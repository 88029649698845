import * as React from "react";
import * as Strings from "../utils/strings";
import { OS } from "../models/os";
import { AppIcon } from "@root/shared";
import { IApp } from "@lib/common-interfaces";
import { CompatibilityStore } from "../stores/compatibility-store";

const styles = require("./device-area.scss");

export interface DeviceAreaProps {
  app: IApp;
  os: string;
}

export class DeviceArea extends React.Component<DeviceAreaProps, {}> {
  public render(): JSX.Element | null {
    const { app, os } = this.props;

    const displayName = app.display_name || "Application";

    if (OS.isIos(os)) {
      return (
        <div className={styles["device-ios"]} aria-hidden="true" tabIndex={-1}>
          <div className={styles["icon-container-ios"]}>
            <AppIcon size={33} app={app} value={displayName} />
            <div className={styles["icon-name-ios"]}>{displayName}</div>
          </div>
        </div>
      );
    } else if (OS.isAndroid(os)) {
      return (
        <div className={styles["device-android"]} aria-hidden="true" tabIndex={-1}>
          <div className={styles["icon-container-android"]}>
            <AppIcon size={18} app={app} value={displayName} />
            <div className={styles["notification-text-android"]}>
              <div className={styles["notification-file-android"]}>{displayName}</div>
              <div className={styles["notification-status-android"]}>{Strings.Install.DeviceNotificationDownloadComplete}</div>
            </div>
          </div>
        </div>
      );
    } else if (OS.isMacOS(os)) {
      return (
        <div className={styles["device-macos"]} aria-hidden="true" tabIndex={-1}>
          <div className={styles["icon-container-macos"]}>
            <AppIcon size={24} app={app} value={displayName} />
            <div className={styles["notification-text-macos"]}>
              <div className={styles["notification-file-macos"]}>{displayName}</div>
              <div className={styles["notification-status-macos"]}>{Strings.Install.DeviceNotificationDownloadComplete}</div>
            </div>
          </div>
        </div>
      );
    } else if (OS.isWindows(os)) {
      if (CompatibilityStore.isWindowsPhoneUserAgent()) {
        return (
          <div className={styles["device-uwp-mobile"]} aria-hidden="true" tabIndex={-1}>
            <div className={styles["icon-container-uwp-mobile"]}>
              <AppIcon size={40} app={app} value={displayName} />
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles["device-uwp-desktop"]} aria-hidden="true" tabIndex={-1}>
            <div className={styles["icon-container-uwp-desktop"]}>
              <AppIcon size={24} app={app} value={displayName} />
              <div className={styles["notification-text-uwp-desktop"]}>
                <div className={styles["notification-file-uwp-desktop"]}>{displayName}</div>
                <div className={styles["notification-status-uwp-desktop"]}>{Strings.Install.DeviceNotificationDownloadComplete}</div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }
}
