import * as React from "react";
import { Title, Text, Size } from "@root/shared";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from "react-accessible-accordion";
const styles = require("./help.scss");

// TODO: Replace with markdown from strings file once the markdown component supports custom styles
export interface HelpItem {
  title: string;
  content: JSX.Element;
}

export interface HelpProps {
  title: string;
  items: HelpItem[];
}

export function Help(props: HelpProps) {
  return (
    <div className={styles["help"]}>
      <div className={styles["title"]}>
        <Title size={Size.Medium}>{props.title}</Title>
      </div>
      <Accordion allowMultipleExpanded allowZeroExpanded className={styles["accordion"]}>
        {props.items.map((helpItem, index) => (
          <AccordionItem key={index} className={styles["accordion-item"]}>
            <AccordionItemHeading>
              <AccordionItemButton className={styles["accordion-button"]}>
                <Text size={Size.Medium} className={styles["accordion-title"]}>
                  {helpItem.title}
                </Text>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles["accordion-content"]}>
              <Text size={Size.Medium} className={styles["accordion-content-text"]}>
                {helpItem.content}
              </Text>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}
