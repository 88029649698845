// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oh3RB92ux{position:relative;width:100%;display:flex;flex-direction:column}._5zNz9Q8Jx{height:auto;width:100%;flex-grow:1;width:inherit;display:flex;flex-direction:column;box-sizing:border-box}._67Qlev4Tx{margin-top:48px}", "",{"version":3,"sources":["webpack://client/install-beacon/components/page/page.scss","webpack://client/install-beacon/css/vars.scss"],"names":[],"mappings":"AAEA,WACE,iBAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CAIF,YACE,WAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,YAAA,CACA,qBAAA,CACA,qBAAA,CAGF,YAEE,eCtBc","sourcesContent":["@import \"~@mobilecss/vars\";\n\n.page {\n  position:relative; // children of parent with min-height can't inherit the height property unless position is set to relative\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n\n.scroll-container {\n  height: auto;\n  width: 100%;\n  flex-grow: 1;\n  width: inherit;\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n}\n\n.scroll-container-with-nav-bar {\ncomposes: scroll-container;\n  margin-top: $navbar-height;\n}\n","$navbar-height: 48px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "oh3RB92ux",
	"scroll-container": "_5zNz9Q8Jx",
	"scrollContainer": "_5zNz9Q8Jx",
	"scroll-container-with-nav-bar": "_67Qlev4Tx _5zNz9Q8Jx",
	"scrollContainerWithNavBar": "_67Qlev4Tx _5zNz9Q8Jx"
};
export default ___CSS_LOADER_EXPORT___;
