import * as React from "react";
import { CSSTransitionGroup } from "react-transition-group";
import { Title, Paragraph, Size, TextColor } from "@root/shared/typography";
import { Icon, IconName } from "@root/shared/icon";
import { IconSize, Space } from "@root/shared/common-interfaces";
import { MediaObject } from "@root/shared/media-object";
import { UnstyledButton } from "@root/shared";
import { findDOMNode } from "react-dom";

const css = require("./install-toast.scss");

export interface InstallToastProps extends React.HTMLAttributes<HTMLElement> {
  visible: boolean;
  title: string;
  description?: React.ReactNode;
  icon: IconName | React.ReactElement<any>;
  action?: React.MouseEventHandler<HTMLButtonElement>;
  styles?: any;
}

export class InstallToast extends React.Component<InstallToastProps> {
  public static defaultProps = { styles: css };
  private buttonRef = React.createRef<UnstyledButton>();

  private renderIcon() {
    const { styles } = this.props;

    const icon =
      typeof this.props.icon === "string" ? (
        <Icon icon={this.props.icon} size={IconSize.Medium} color={TextColor.Link} />
      ) : (
        this.props.icon || null
      );

    if (!icon) {
      return null;
    }

    return <span className={styles.icon}>{icon}</span>;
  }

  public componentDidUpdate(prevProps: InstallToastProps) {
    if (!prevProps.action && this.props.action && this.props.visible) {
      // Focus the button when it becomes actually an enabled button.
      // Mostly because aria-live and role don’t work _inside_ button,
      // but also so you can interact with the button at this point.
      const button = findDOMNode(this.buttonRef.current);
      if (button instanceof HTMLElement) {
        button.focus();
      }
    }
  }

  public render() {
    const { styles, visible, action, className, title, description, icon, ...props } = this.props;
    const inDuration = parseInt(styles.transitionInDuration, 10);
    const outDuration = parseInt(styles.transitionOutDuration, 10);
    return (
      <div {...props} className={[className, styles.toaster].join(" ")}>
        <CSSTransitionGroup
          component="div"
          className={styles.transitionGroup}
          transitionAppear
          transitionAppearTimeout={inDuration}
          transitionEnterTimeout={inDuration}
          transitionLeaveTimeout={outDuration}
          transitionName={{
            appear: styles.enter,
            appearActive: styles.enterActive,
            enter: styles.enter,
            enterActive: styles.enterActive,
            leave: styles.leave,
            leaveActive: styles.leaveActive,
          }}
        >
          {visible ? (
            <UnstyledButton disabled={!action} onClick={action} ref={this.buttonRef} role={action ? "button" : "presentation"}>
              <div className={styles.toast} role="status" aria-live="assertive">
                <MediaObject hSpace={Space.Small} textOnly={!this.props.hasOwnProperty("icon")}>
                  {this.renderIcon()}
                  <Title className={styles.wrapText} size={Size.XSmall} bold>
                    {title}
                  </Title>
                  {typeof description === "string" ? (
                    <Paragraph className={styles.wrapText} size={Size.Small} color={TextColor.Secondary}>
                      {description}
                    </Paragraph>
                  ) : (
                    description || null
                  )}
                </MediaObject>
              </div>
            </UnstyledButton>
          ) : null}
        </CSSTransitionGroup>
      </div>
    );
  }
}
