import * as React from "react";
import { observer } from "mobx-react";
import { TabTrap, Autofocus } from "@root/shared";
import { findDOMNode } from "react-dom";

const classNames = require("classnames");
const styles = require("./overlay.scss");

export interface OverlayProps {
  isVisible: boolean;
  animated?: boolean;
}

@observer
export class Overlay extends React.Component<OverlayProps, {}> {
  private clientY!: number;
  private overlayContainer: any;

  public componentDidMount() {
    this.disableBodyScrolling(this.props.isVisible);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isVisible !== this.props.isVisible) {
      this.disableBodyScrolling(!this.props.isVisible);
    }
  }

  public componentWillUnmount() {
    this.disableBodyScrolling(false);
  }

  public render() {
    const { isVisible, animated } = this.props;
    const className = classNames({
      [styles["container"]]: !animated,
      [styles["animated-container"]]: animated,
      [styles["visible"]]: isVisible,
    });

    return (
      <TabTrap active={isVisible} className={className} ref={(c) => (this.overlayContainer = findDOMNode(c))}>
        <Autofocus getRef={() => this.overlayContainer} focus={isVisible}>
          {this.props.children}
        </Autofocus>
      </TabTrap>
    );
  }

  private onScrollStart = (event: any) => {
    if (event.targetTouches.length === 1) {
      this.clientY = event.targetTouches[0].clientY;
    }
  };

  private onScroll = (event: any) => {
    if (this.overlayContainer.scrollHeight - this.overlayContainer.offsetHeight > 0) {
      const scrollDistance = event.targetTouches[0].clientY - this.clientY;
      this.overlayContainer.scrollTop = this.getScrollTo(scrollDistance);
      this.clientY = event.targetTouches[0].clientY;
    }
    event.preventDefault();
  };

  private disableBodyScrolling = (disable: boolean): void => {
    if (disable) {
      document.body.classList.add(styles["no-scroll"]);
      this.overlayContainer.addEventListener("touchmove", this.onScroll, true);
      this.overlayContainer.addEventListener("touchstart", this.onScrollStart, true);
    } else {
      document.body.classList.remove(styles["no-scroll"]);
      this.overlayContainer.removeEventListener("touchmove", this.onScroll, true);
      this.overlayContainer.removeEventListener("touchstart", this.onScrollStart, true);
    }
  };

  private getScrollTo = (scrollDistance: number): number => {
    const scrollDestination = this.overlayContainer.scrollTop - scrollDistance;
    const maxScrollY = this.overlayContainer.scrollHeight - this.overlayContainer.offsetHeight;

    let scrollTo;
    if (scrollDestination <= 0) {
      scrollTo = 0;
    } else if (scrollDestination >= maxScrollY) {
      scrollTo = maxScrollY;
    } else {
      scrollTo = scrollDestination;
    }

    return scrollTo;
  };
}
