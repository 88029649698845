import * as React from "react";
import { AppIcon } from "@root/shared";
import { App } from "@root/data/shell/models";
const styles = require("./app-icon-annotated.scss");

export interface AppIconAnnotatedProps {
  app: App;
}

export class AppIconAnnotated extends React.Component<AppIconAnnotatedProps, {}> {
  public render() {
    const { app } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.background}>
          <AppIcon size={120} styles={styles["app-icon"]} app={app} />
        </div>
        <div className={styles.foreground} />
      </div>
    );
  }
}
