import * as React from "react";
import * as Strings from "../../utils/strings";
import { userStore } from "../../../stores/user-store";
import { Footer } from "../footer";
import { ProductLogo, Gravatar, PrimaryButton } from "@root/shared";

const styles = require("./not-found.scss");

export class NotFound extends React.Component<{}, {}> {
  public render() {
    return (
      <div className={styles["not-found"]}>
        <div className={styles["logo"]}>
          <ProductLogo />
        </div>
        <div className={styles["content"]}>
          <Gravatar className={styles["gravatar"]} email={userStore.currentUser.email} size={60} />
          <div className={styles["signed-in-as"]}>{Strings.App.SignedInAs}</div>
          <div className={styles["email"]}>{userStore.currentUser.email}</div>
          <div className={styles["error-message"]}>{Strings.App.NotAuthorizedNotFound}</div>
          <PrimaryButton className={styles["sign-out"]} onClick={() => ((window as any).location = "/logout")}>
            {Strings.App.SignInWithDifferentAccount}
          </PrimaryButton>
        </div>
        <div className={styles["footer"]}>
          <Footer isPublic={false} />
        </div>
      </div>
    );
  }
}
