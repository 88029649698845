import { action, observable } from "mobx";
import { RegisterDeviceStore } from "@root/install-beacon/stores/register-device-store";
import { locationStore } from "@root/stores";
import { FirstRunSessionStorage } from "../utils/first-run-session-storage";
import { logger } from "@root/lib/telemetry";

export class RegisterDeviceFirstRunUIStore {
  private registerDeviceStore: RegisterDeviceStore;
  @observable public showSkipConfirmDialog = false;

  constructor(private returnUrl: string) {
    this.registerDeviceStore = new RegisterDeviceStore();
  }

  @action
  public onAddDeviceClicked = () => {
    logger.info("Register device clicked");
    this.registerDeviceStore.fetchProfile(this.returnUrl);
  };

  @action
  public onSkipClicked = () => {
    this.showSkipConfirmDialog = true;
  };

  @action
  public onSkipConfirmed = () => {
    // Record that the user chose to skip, so they don't get repeatedly prompted to register.
    logger.info("First-run skip device registration clicked");
    FirstRunSessionStorage.skippedDeviceRegistration = true;
    locationStore.pushAppList();
  };

  @action
  public onSkipCancelled = () => {
    this.showSkipConfirmDialog = false;
  };
}
