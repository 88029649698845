import * as React from "react";
import { observer, inject } from "mobx-react";
import { Build } from "@root/data/install";
import { Paragraph, Size } from "@root/shared";
import { updateTokenStore } from "../stores/update-token-store";
import { AppUIStore } from "./stores/app-ui-store";
import { AppInfo } from "./app-info";
import { Releases } from "./releases";
import { ReleaseNotFound } from "./release-not-found";
import { App } from "@root/data/shell/models";

const styles = require("./app-info-and-releases.scss");

export interface AppInfoAndReleasesProps {
  app: App;
  topRelease: Build;
  isPublic: boolean;
  isDownloadOverlayVisible: boolean;
  appUIStore?: AppUIStore;
}

const Banner = ({ message }: { message: string }) => (
  <div className={styles.noticeBar} data-test-id="incompatability-banner">
    <Paragraph className={styles.responsiveConstraint} size={Size.Medium} bold invert>
      {message}
    </Paragraph>
  </div>
);

export const AppInfoAndReleases = inject((stores: { appUIStore: AppUIStore }) => ({ appUIStore: stores.appUIStore }))(
  observer(
    class extends React.Component<AppInfoAndReleasesProps, { toggleVisible: "visible" | "hidden" }> {
      constructor(props) {
        super(props);

        this.state = {
          toggleVisible: "visible",
        };
      }
      public UNSAFE_componentWillReceiveProps(nextProps) {
        const { isDownloadOverlayVisible } = this.props;
        if (nextProps.isDownloadOverlayVisible !== isDownloadOverlayVisible) {
          this.toggleVisibility();
        }
      }

      private toggleVisibility() {
        const { isDownloadOverlayVisible } = this.props;
        if (isDownloadOverlayVisible) {
          this.setState({
            toggleVisible: "visible",
          });
        } else {
          setTimeout(() => {
            this.setState({
              toggleVisible: "hidden",
            });
          }, 1510);
        }
      }

      private showStopTestingDialog = () => {
        const { appUIStore } = this.props;
        if (appUIStore) {
          return appUIStore.setTesterDialogVisible(true);
        }
      };

      public render() {
        const { app, appUIStore } = this.props;
        const noticeMessage = appUIStore ? appUIStore.noticeMessage : "";
        const { toggleVisible } = this.state;
        const isReleaseNotFound: boolean = appUIStore ? appUIStore.isReleaseNotFound : false;
        const releaseId: string = appUIStore ? appUIStore.releaseId : "";
        return (
          <div className={styles["app-info-and-releases"]}>
            <div className={styles[toggleVisible]}>
              <AppInfo app={app} />
              {noticeMessage && <Banner message={noticeMessage} />}
              {isReleaseNotFound && <ReleaseNotFound releaseId={releaseId} stopTestingOnClick={this.showStopTestingDialog} />}
            </div>
            <Releases token={updateTokenStore.data} toggleVisibility={toggleVisible} />
          </div>
        );
      }
    }
  )
);
