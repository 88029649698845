// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../common.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6NuhVxh7x{text-align:center;display:flex;flex-direction:column;width:100%}._7vtfMzGdx{align-items:center;display:flex;flex-direction:column;justify-content:center;flex:7;margin-bottom:100px}._6KPeHVY8x{align-items:center;display:flex;flex-direction:column;flex:1}._2neZ9Fw0x{margin-top:50px}", "",{"version":3,"sources":["webpack://client/install-beacon/hockey-app-update-setup/hockey-app-update-setup.scss"],"names":[],"mappings":"AAEA,YAEE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CAGF,YACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,MAAA,CACA,mBAAA,CAGF,YACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,MAAA,CAGF,YACE,eAAA","sourcesContent":["@import \"~@css/vars.scss\";\n\n.container {\n  composes: centered from \"../common.scss\";\n  text-align: center;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.content-container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 7;\n  margin-bottom: 100px;\n}\n\n.logo-container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.logo {\n  margin-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"container": "_6NuhVxh7x " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["centered"] + "",
	"content-container": "_7vtfMzGdx",
	"contentContainer": "_7vtfMzGdx",
	"logo-container": "_6KPeHVY8x",
	"logoContainer": "_6KPeHVY8x",
	"logo": "_2neZ9Fw0x"
};
export default ___CSS_LOADER_EXPORT___;
