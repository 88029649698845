import { Device, devicesStore } from "@root/data/management";
import { computed, action, observable } from "mobx";
import { registerDeviceStore } from "@root/install-beacon/stores/register-device-store";
import { RegistrationUtils } from "@root/install-beacon/utils/registration-utils";
import { locationStore } from "@root/stores";

export class ProfileUIStore {
  @observable public showDeleteConfirmDialog = false;
  @observable public showDeleteFailedToast = false;
  public deviceToDelete!: Device;

  constructor(udid?: string) {
    if (!!udid) {
      RegistrationUtils.finishDeviceRegistration(udid, false, false);
      locationStore.router.replace("/profile");
    }
  }

  @action
  public fetchDevices() {
    this.deviceToDelete = null as any;
    devicesStore.fetchCollection();
  }

  @computed
  public get devices(): Device[] {
    return devicesStore.resources;
  }

  @computed
  public get isLoading(): boolean {
    return devicesStore.isFetchingCollection;
  }

  @action
  public onRemoveDevice = (device: Device) => {
    this.showDeleteConfirmDialog = true;
    this.deviceToDelete = device;
  };

  @action
  public onRemoveDeviceCancelled = () => {
    this.showDeleteConfirmDialog = false;
  };

  @action
  public onRemoveDeviceConfirmed = () => {
    devicesStore.delete(this.deviceToDelete).onFailure(() => {
      this.showDeleteFailedToast = true;
    });
    this.showDeleteConfirmDialog = false;
  };

  @action
  public onDismissDeleteFailedToast = () => {
    this.showDeleteFailedToast = false;
  };

  @action
  public onAddNewDevice = () => {
    registerDeviceStore.fetchProfile(`${window.location.origin}/profile`);
  };
}
