import * as React from "react";
import { Device } from "@root/data/management";
import {
  Stretch,
  MediaObject,
  Space,
  Text,
  Size,
  ClickableIcon,
  Icon,
  IconName,
  Color,
  IconSize,
  TextColor,
  ActionText,
} from "@root/shared";
import { Grid, GridSpacing, Row, Col, RowCol } from "@root/shared/grid";
import getDeviceThumbnail from "@root/install-beacon/utils/device-to-image-helper";
import { getOsFromUserAgent } from "@root/lib/utils/user-agent";
import { OS } from "@root/install-beacon/models/os";
import { compatibilityStore } from "../../stores/compatibility-store";

const styles = require("./devices-list.scss");
const classNames = require("classnames");

export interface DeviceListProps {
  loading?: boolean;
  invert?: boolean;
  onRemoveDevice?: (device: Device) => void;
  onAddNewDevice?: () => void;
  devices: Device[];
}

export class DevicesList extends React.Component<DeviceListProps, {}> {
  public render() {
    const { loading, invert, devices, onRemoveDevice, onAddNewDevice, ...passthrough } = this.props;
    const rowClassName = classNames({ [styles.invertedRow]: invert });

    const rows = loading
      ? [null, null, null].map((_, i) => (
          <Row key={i}>
            <Col width={10}>
              <MediaObject skeleton>
                <Icon icon={IconName.Done} size={IconSize.Large} />
                <Text bold size={Size.Large} />
              </MediaObject>
            </Col>
            <Col width={2} />
          </Row>
        ))
      : devices.map((device) => (
          <Row
            key={device.udid}
            className={rowClassName}
            columnSpacing={invert ? GridSpacing.XSmall : GridSpacing.Medium}
            data-test-class="device-list-row"
          >
            <Col>
              <Stretch>
                <MediaObject hSpace={Space.XSmall}>
                  <div className={styles.deviceIcon}>
                    <img src={getDeviceThumbnail(device)} data-test-class="device-thumbnail" />
                  </div>
                  <Text bold size={Size.Medium} data-test-class="device-displayname">
                    {device.displayName}
                  </Text>
                </MediaObject>
                {onRemoveDevice ? (
                  <ClickableIcon
                    className={styles.deleteButton}
                    icon={<Icon icon={IconName.Delete} color={Color.Gray} />}
                    onClick={onRemoveDevice.bind(this, device)}
                    data-test-class="delete-device-button"
                  />
                ) : null}
              </Stretch>
            </Col>
          </Row>
        ));

    return (
      <Grid
        {...passthrough}
        className={classNames({ [styles.devices]: !invert })}
        padded={invert ? true : null}
        bordered={!invert}
        rowSpacing={GridSpacing.Medium}
      >
        {rows}
        {onAddNewDevice && OS.isIos(getOsFromUserAgent().name!) ? (
          compatibilityStore.isSafariBrowser() ? (
            <RowCol>
              <ActionText onClick={onAddNewDevice} className={styles.registerDevice}>
                <MediaObject hSpace={Space.XSmall}>
                  <Icon size={IconSize.XSmall} icon={IconName.Add} color={TextColor.Link} />
                  <Text size={Size.Medium} color={TextColor.Link}>
                    Add new device
                  </Text>
                </MediaObject>
              </ActionText>
            </RowCol>
          ) : (
            <RowCol>
              <Text size={Size.Medium} color={TextColor.Secondary}>
                Due to an iOS limitation, new devices can only be registered in Safari.
              </Text>
            </RowCol>
          )
        ) : null}
      </Grid>
    );
  }
}
