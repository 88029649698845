import { humanizePackageSize } from "@root/lib/domain/package-size";
import { format } from "date-fns";

export default class BuildHelper {
  public static dateString(timestamp: string): string {
    return timestamp ? format(new Date(timestamp), "MMM dd, yyyy 'at' HH:mm") : "";
  }

  public static filesizeString(size: number): string {
    return size ? humanizePackageSize(size) : "";
  }
}
