const SKIPPED_KEY = "skip-device-registration-after-first-run";

/** Saves a boolean in Session Storage that identifies if the user skiped device registration during the first run flow. */
export class FirstRunSessionStorage {
  public static get skippedDeviceRegistration(): boolean {
    try {
      if (!window.sessionStorage) {
        return false;
      }
      return window.sessionStorage.getItem(SKIPPED_KEY) === `${true}`;
    } catch (e) {
      console.warn(
        "Oops! Looks like sessionStorage isn't available in your browser. Please update your browser settings to turn on sessionStorage."
      );
      return false;
    }
  }
  public static set skippedDeviceRegistration(skipped: boolean) {
    try {
      if (window.sessionStorage) {
        window.sessionStorage.setItem(SKIPPED_KEY, `${skipped}`);
      }
    } catch (e) {
      console.warn(
        "Oops! Looks like sessionStorage isn't available in your browser. Please update your browser settings to turn on sessionStorage."
      );
    }
  }
}
