import { observable, action } from "mobx";
import { RegistrationUtils } from "../utils/registration-utils";

export class RegisterDeviceUIStore {
  @observable public registeredOverlayVisible!: boolean;
  constructor(private skipRegistration?: boolean, private udid?: string, isPublic?: boolean) {
    if (!isPublic) {
      this.handleRegistrationProcess();
    }
  }

  private handleRegistrationProcess(): void {
    // TODO: Move this to some registration base UI store
    RegistrationUtils.redirectToInitialRegistrationIfNeeded(this.skipRegistration, this.udid);
    if (!!this.udid) {
      this.setRegisteredOverlayVisible(true);
      setTimeout(() => {
        this.setRegisteredOverlayVisible(false);
      }, 2000);
    }
  }

  @action
  public setRegisteredOverlayVisible(visible: boolean): void {
    this.registeredOverlayVisible = visible;
  }
}
