import * as React from "react";
import { observer, inject } from "mobx-react";
import { createPortal } from "react-dom";
import { Text, Size, EmptyState, portalRootNode, DropdownButton, ButtonSize, Select, Trigger, ItemGroup, Option } from "@root/shared";
import { Release, ReleaseProps } from "./release";
import * as Strings from "../utils/strings";
import { Overlay } from "../components/overlay/overlay";
import { Install } from "./install";
import { StopTestingMenu } from "./stop-testing-menu";
import { AppUIStore, SortReleasesBy } from "./stores/app-ui-store";
import { Build, LATEST } from "@root/data/install";
import StringHelper from "../utils/string-helper";
import { OS } from "@lib/common-interfaces";

const styles = require("./releases.scss");
const noReleasesImg = require("../assets/lab.svg");

export interface ReleasesProps {
  // In-app updates token, only for private distribution groups
  token?: string;
  appUIStore?: AppUIStore;
  toggleVisibility?: string;
}

export const ReleaseList = ({
  releases,
  selectedRelease,
  releaseAvailable,
  ...passthrough
}: { releases: Build[]; selectedRelease: Build } & Partial<ReleaseProps>) => (
  <div>
    {releases.map((release) => {
      return (
        <Release
          key={release.id}
          data-test-class="release"
          expanded={selectedRelease && selectedRelease.id === release.id}
          data={release}
          releaseAvailable={releaseAvailable}
          {...passthrough}
        />
      );
    })}
  </div>
);

const renderOverlay = (isVisble: boolean, selectedRelease: Build, onCloseDownloadOverlay: any) => {
  return createPortal(
    <Overlay animated={true} isVisible={isVisble}>
      <Install build={selectedRelease} onCloseClicked={onCloseDownloadOverlay} />
    </Overlay>,
    portalRootNode
  );
};

export const NoReleases = () => (
  <EmptyState
    className={styles.noReleases}
    imgSrc={noReleasesImg}
    title={Strings.Releases.NoReleasesTitle}
    subtitle={Strings.Releases.NoReleasesSubtitle}
    hideButton={true}
  />
);

export const Releases = inject((stores: { appUIStore: AppUIStore }) => ({ appUIStore: stores.appUIStore }))(
  observer(
    class extends React.Component<ReleasesProps> {
      public static displayName = "Releases";
      private sortText?: string;
      private versionSortValue?: string;

      private renderRefineToolbar(): JSX.Element {
        const { appUIStore } = this.props;
        const { setSort, sortBy } = appUIStore!;
        return (
          <Select
            minListWidth={170}
            onChange={(newSort: string) => {
              const n: number = +newSort;
              setSort(n);
            }}
            defaultValue={sortBy.toString()}
          >
            <Trigger>
              <DropdownButton subtle size={ButtonSize.Small}>
                {`Sort By: ${this.sortText}`}
              </DropdownButton>
            </Trigger>
            <ItemGroup>
              <Option value={SortReleasesBy.VersionNumberDescending.toString()} text={this.versionSortValue!} />
              <Option value={SortReleasesBy.DateDecending.toString()} text={"Most Recent"} />
            </ItemGroup>
          </Select>
        );
      }

      private showStopTestingDialog = () => {
        const { appUIStore } = this.props;
        return appUIStore!.setTesterDialogVisible(true);
      };

      public render() {
        const { appUIStore, toggleVisibility } = this.props;
        const {
          isPublic,
          isLoading,
          selectedRelease,
          noticeMessage,
          hasReleases,
          hasMultipleReleases,
          sortedReleasesByDateDescending,
          sortedReleases,
          onExpandReleaseClicked,
          onInstallClicked,
          releaseAvailable,
          isDownloadOverlayVisible,
          onCloseDownloadOverlay,
          releaseId,
          isReleaseNotFound,
          topRelease,
          initialState,
          sortBy,
        } = appUIStore!;

        const specifiedRelease: boolean = releaseId !== LATEST;
        const currentOs = topRelease ? topRelease.appOs : "None";
        this.versionSortValue = currentOs === OS.ANDROID ? "Build" : "Version";

        let displayedReleases: Build[];
        switch (sortBy) {
          case SortReleasesBy.DateDecending:
            displayedReleases = sortedReleasesByDateDescending;
            this.sortText = "Most Recent";
            break;
          case SortReleasesBy.VersionNumberDescending:
            displayedReleases = sortedReleases;
            this.sortText = this.versionSortValue;
            break;
          default:
            displayedReleases = sortedReleases;
            this.sortText = this.versionSortValue;
            break;
        }
        let specificRelease: Build;
        let topTitle: string;
        if (!specifiedRelease) {
          topTitle = Strings.Releases.Latest;
          specificRelease = displayedReleases[0];
          displayedReleases = displayedReleases.slice(1);
        } else {
          topTitle = StringHelper.format(Strings.Release.Title, releaseId);
          specificRelease = topRelease;
        }

        return (
          <div className={styles.releases}>
            {renderOverlay(isDownloadOverlayVisible, selectedRelease!, onCloseDownloadOverlay)}
            <div className={styles[toggleVisibility!]}>
              <div className={styles.responsiveConstraint}>
                {hasReleases && !isReleaseNotFound && (
                  <div className={styles.topRelease}>
                    <div className={styles.titleBar}>
                      <Text size={Size.Medium} bold>
                        {topTitle}
                      </Text>
                      <div className={styles.titleControls}>
                        <StopTestingMenu onClick={this.showStopTestingDialog} />
                      </div>
                    </div>
                    <Release
                      key={specificRelease.id}
                      expanded
                      data={specificRelease}
                      releaseAvailable={releaseAvailable}
                      installEnabled={!noticeMessage}
                      isPublic={isPublic}
                      onExpand={onExpandReleaseClicked}
                      onInstallClicked={onInstallClicked}
                    />
                  </div>
                )}
                {hasMultipleReleases && (
                  <div className={styles.titleBar}>
                    <Text size={Size.Medium} bold>
                      {Strings.Releases.Title}
                    </Text>
                    <div className={styles.titleControls}>{this.renderRefineToolbar()}</div>
                  </div>
                )}
              </div>
              {!isLoading && !hasReleases && <NoReleases />}
              {hasMultipleReleases && (
                <ReleaseList
                  releases={displayedReleases}
                  installEnabled={!noticeMessage}
                  isPublic={isPublic}
                  selectedRelease={(!initialState as any) && selectedRelease!}
                  onExpand={onExpandReleaseClicked}
                  onInstallClicked={onInstallClicked}
                  releaseAvailable={releaseAvailable}
                />
              )}
            </div>
          </div>
        );
      }
    }
  )
);
