import * as React from "react";
import { MediaObject, Paragraph, Size, Space, TextColor } from "@root/shared";
const branchIcon = require("../assets/branch.svg");
const commitHashIcon = require("../assets/sha.svg");
const styles = require("./build-info.scss");

export interface BuildInfoProps {
  branch: string;
  commitHash: string;
  commitMessage?: string;
}

export class BuildInfo extends React.PureComponent<BuildInfoProps, {}> {
  public render() {
    const { branch, commitHash, commitMessage } = this.props;
    return (
      <div className={styles.container}>
        {this.renderBranch(branch)}
        {this.renderCommitHash(commitHash)}
        {this.renderCommitMessage(commitMessage)}
      </div>
    );
  }

  public renderBranch(branch: string) {
    if (branch) {
      return (
        <MediaObject className={styles.row} hSpace={Space.XXSmall} inline>
          <img className={styles.icon} src={branchIcon} />
          <Paragraph bold size={Size.Medium}>
            {branch}
          </Paragraph>
        </MediaObject>
      );
    }
  }

  public renderCommitHash(commitHash: string) {
    if (commitHash) {
      return (
        <MediaObject className={styles.row} hSpace={Space.XXSmall} inline>
          <img className={styles.icon} src={commitHashIcon} />
          <Paragraph size={Size.Medium}>{commitHash}</Paragraph>
        </MediaObject>
      );
    }
  }

  public renderCommitMessage(commitMessage?: string) {
    if (commitMessage) {
      return (
        <div className={styles.message}>
          <Paragraph color={TextColor.Secondary} size={Size.Medium}>
            {commitMessage}
          </Paragraph>
        </div>
      );
    }
  }
}
