import * as React from "react";
import { observer, Provider } from "mobx-react";
import { withRouter, WithRouterProps } from "react-router";

import { userStore } from "../stores/user-store";
import { appStore } from "../stores/app-store";
import { locationStore } from "../stores/location-store";
import { globalUIStore } from "../stores/global-ui-store";

import { KeyboardEventManager, RefocusManager } from "@root/shared";
import * as PageTimer from "@root/shared/page-timer";
import { ScrollStopper } from "../shell/scroll-stopper";

const styles = require("./install.scss");

@observer
class InstallComponent extends React.Component<WithRouterProps, {}> {
  public UNSAFE_componentWillMount() {
    locationStore.setRouter(this.props.router);
  }

  public render() {
    const isOverlayOrModalOpen = globalUIStore.isOverlayOpen || globalUIStore.isModalOpen;
    const appLayoutProps = {
      inert: isOverlayOrModalOpen ? "true" : null,
      "aria-hidden": isOverlayOrModalOpen ? true : undefined,
    };

    return (
      <Provider userStore={userStore} locationStore={locationStore} appStore={appStore}>
        <KeyboardEventManager>
          <RefocusManager>
            <PageTimer.Collector>
              <ScrollStopper>
                <div className={styles["app-layout"]} {...appLayoutProps}>
                  {this.props.children}
                </div>
              </ScrollStopper>
            </PageTimer.Collector>
          </RefocusManager>
        </KeyboardEventManager>
      </Provider>
    );
  }
}

const installComponentWithRouter = withRouter(InstallComponent);
export { installComponentWithRouter as Install };
