import { Build, buildStore } from "@root/data/install";
import { userStore, appStore, locationStore } from "@root/stores";
import { apiGateway } from "@root/lib/http";
import { action, observable, computed } from "mobx";
import * as uuid from "uuid";
import { installAnalyticsStore } from "@root/install-beacon/stores/install-analytics-store";
import { API } from "@root/data/install/constants";
import { logger } from "@root/lib/telemetry";
import { OS } from "../../models/os";
import { noop } from "lodash";
import { UpdateSetupHelper } from "@root/install-beacon/utils/update-setup-helper";
import { getOsNameFromUserAgent } from "@root/lib/utils/user-agent";

const maxRetryCount = 2;

interface InstallUIStoreOptions {
  isPublic: boolean;
  token: string;
  onInstallLinkAvailableFromProps: () => void;
  ownerName: string;
  appName: string;
  releaseId: string;
  udid: string;
}

type SerializedResignResponse = {
  status_url: string;
};
type DeserializedResignResponse = {
  statusUrl: string;
};

type SerializedResignStatusResponse = {
  status: "complete" | string;
  error_code?: string;
  error_message?: string;
};
export type DeserializedResignStatusResponse = {
  status: "inProgress" | "failedAndCanRetry" | "failedAndCannotRetry" | "complete";
};

export class InstallUIStore {
  private retryCount: number = 0;
  private cancelled: boolean = false;
  public cancel() {
    this.cancelled = true;

    // Track time it took to get here since tapping install button
    logger.info("Provisioning: Resign cancelled in tester UI", this.telemetryProperties as any);
  }
  private noopOrRun<T extends (...args: any[]) => any>(cb: T): (...args: any[]) => ReturnType<T> | void {
    if (this.cancelled) {
      return noop;
    }
    return cb;
  }

  @observable public installToastVisible: boolean = false;
  @action public setInstallToastVisible = (installToastVisible: boolean) => {
    this.installToastVisible = installToastVisible;
  };

  public get currentReleaseId() {
    return this.releaseId;
  }

  private isPublic!: boolean;
  private token!: string;
  private onInstallLinkAvailableFromProps!: () => void;
  private ownerName!: string;
  private appName!: string;
  private releaseId!: string;
  private udid!: string;

  @computed get release() {
    return buildStore.get(buildStore.compoundKey(this.ownerName, this.appName, this.releaseId));
  }

  constructor(options: InstallUIStoreOptions) {
    Object.assign(this, options);
  }

  @computed private get telemetryProperties() {
    const [ownerName, appName] = buildStore.splitKey(this.release!.appIdentifier!);
    const app = appStore.findApp(ownerName, appName);
    return {
      appId: app ? app.id : null,
      releaseId: this.release!.id,
    };
  }

  @computed public get canRetry() {
    return this.resignStatus === "failedAndCanRetry";
  }

  private storeCookies = this.noopOrRun(() => {
    const app = appStore.app;
    const appIconUrl = (app && app.icon_url) || this.release!.iconLink;
    const appDisplayName = (app && app.display_name) || this.release!.appDisplayName;
    UpdateSetupHelper.storeCookies(this.release!, appDisplayName!, appIconUrl!, this.isPublic, this.token);
  });

  private onResignFailed = this.noopOrRun(() => {
    this.retryCount = this.retryCount + 1;
    if (this.retryCount >= maxRetryCount) {
      this.setResignStatus("failedAndCannotRetry");
    } else {
      this.setResignStatus("failedAndCanRetry");
    }
  });

  private onResignPending = this.noopOrRun(({ statusUrl }: DeserializedResignResponse) => {
    this.setResignStatus("inProgress");
    apiGateway
      .get<SerializedResignStatusResponse>(statusUrl)
      .then(
        this.noopOrRun((response: SerializedResignStatusResponse) => {
          if (!!response.error_code) {
            // The resign errored off
            this.onResignFailed();
          } else if (response.status === "complete") {
            buildStore
              .fetchOne(buildStore.compoundKey(this.ownerName, this.appName, this.release!.id), {
                ownerName: this.ownerName,
                appName: this.appName,
                udid: this.udid,
              })
              .onSuccess((release: Build | any) => {
                this.setResignStatus("complete");

                // Track the time it took for the Install button to be available after kicking off a resign
                // Track time it took to get here since tapping install button
                logger.info("Provisioning: Resigned app available to install", this.telemetryProperties as any);
                this.setInstallToastVisible(false);
              });
          } else {
            // Consider this the "in progress" case
            setTimeout(() => {
              this.onResignPending({ statusUrl });
            }, 1000);
          }
        }),
        this.noopOrRun(() => {
          this.onResignFailed();
        })
      )
      .catch(
        this.noopOrRun(() => {
          this.onResignFailed();
        })
      );
  });

  private startInstall = () => {
    this.onInstallLinkAvailableFromProps();

    if (this.release!.installLink) {
      this.storeCookies();

      // Track time it took to get here since tapping install button
      logger.info("Provisioning: Installed app", this.telemetryProperties as any);

      setTimeout(() => {
        let link = this.release!.installLink;
        if (OS.isIos(this.release!.appOs!) && !OS.isIos(getOsNameFromUserAgent())) {
          link = this.release!.downloadLink;
        }
        // If doing the redirect immediately, it will keep the download page from fully loading.
        window.location.href = link!;
      }, 100);
    }

    const userId = userStore.currentUser && userStore.currentUser.id ? userStore.currentUser.id : uuid.v4();

    if (!!this.release!.distributionGroups) {
      const distributionGroupIds = this.release!.distributionGroups.map((x) => x.id);
      installAnalyticsStore.sendInstallAnalytics(
        this.release!.appName!,
        locationStore.ownerName!,
        this.release!.id!,
        distributionGroupIds,
        userId
      );
    }
  };

  private resign = this.noopOrRun(() => {
    apiGateway
      .post<SerializedResignResponse>(API.PROVISION, {
        params: {
          owner_name: this.ownerName,
          app_name: this.appName,
          release_id: this.release!.id,
        },
      })
      .then(
        this.noopOrRun(({ status_url: statusUrl }: SerializedResignResponse) => {
          // Deserialize
          const response: DeserializedResignResponse = { statusUrl };
          // Check status
          this.onResignPending(response);
        }),
        this.noopOrRun(() => {
          this.onResignFailed();
        })
      )
      .catch(
        this.noopOrRun(() => {
          this.onResignFailed();
        })
      );
  });

  public onInstallClicked = action(() => {
    if (
      this.isPublic ||
      !OS.isIos(this.release!.appOs!) ||
      this.release!.isUdidProvisioned ||
      !this.udid ||
      this.release!.isExternalBuild
    ) {
      // Already provisioned, or is public app and doesn't matter
      this.setResignStatus(undefined as any);
      this.startInstall();
    } else if (!this.release!.isUdidProvisioned && this.release!.canResign) {
      // Indicate that we've started resign
      this.setResignStatus("inProgress");

      // Show toast with a loading indicator
      this.setInstallToastVisible(true);
      this.retryCount = 0;

      // Send telemetry that a resign has started
      logger.info("Provisioning: Resign started", this.telemetryProperties as any);

      // Request a resign
      this.resign();
    }
  });
  public onRetryClicked = this.noopOrRun(() => {
    // Request a resign
    this.resign();
  });

  @observable public resignStatus!: DeserializedResignStatusResponse["status"];

  @action
  private setResignStatus(resignStatus: DeserializedResignStatusResponse["status"]) {
    this.resignStatus = resignStatus;
  }

  @computed public get installToastTitle(): string | undefined {
    if (this.resignStatus === "inProgress") {
      const version = this.release && this.release.version ? `version ${this.release.version}` : "a version";
      return `Preparing ${version} for your device.`;
    } else if (["failedAndCanRetry", "failedAndCannotRetry"].includes(this.resignStatus)) {
      return "Oops! There was a problem building the app.";
    }
  }
}
