import * as React from "react";
import { ButtonContainer, UnstyledButton, Text, Size, TextColor, Overlay, OverlayTransition } from "@root/shared";
import { Block, BlockBorderRadius, MaterialShadow } from "@root/shared/block";

const css = require("./install-dialog.scss");

const touchMoveHandler = function (e) {
  e.preventDefault();
};

const preventScrolling = () => {
  document.body.classList.add(css.noScroll);
  document.body.addEventListener("touchmove", touchMoveHandler, { passive: false });
};
const allowScrolling = () => {
  document.body.removeEventListener("touchmove", touchMoveHandler, false);
  document.body.classList.remove(css.noScroll);
};

export interface InstallDialogProps {
  visible: boolean;
  description: string;
  cancelButton?: string;
  confirmButton?: string;
  onCancel(): void;
  onConfirm?(event: React.MouseEvent<HTMLElement>): void;
  [key: string]: any;
}

export class InstallDialog extends React.Component<InstallDialogProps, {}> {
  public static defaultProps = { styles: css };

  public componentWillUnmount() {
    allowScrolling();
  }

  public render() {
    const {
      onCancel,
      onConfirm,
      danger,
      description,
      cancelButton,
      confirmButton,
      className,
      styles,
      children,
      ...props
    } = this.props;

    return (
      <Overlay
        transitionOut={OverlayTransition.Fade}
        {...props}
        role="dialog"
        backdrop
        backdropClassName={styles.backdrop}
        onRequestClose={onCancel}
        className={[className, styles.dialog].join(" ")}
        onEnter={preventScrolling}
        onExited={allowScrolling}
      >
        <Block shadow={MaterialShadow.OverlayIntense} borderRadius={BlockBorderRadius.Large}>
          <Text size={Size.Medium} className={styles.title}>
            {description}
          </Text>
          <ButtonContainer className={styles.buttons} right>
            {cancelButton ? (
              <UnstyledButton data-test-id="cancel-button" onClick={onCancel}>
                <Text color={TextColor.Link} size={Size.Medium}>
                  {cancelButton}
                </Text>
              </UnstyledButton>
            ) : null}
            {confirmButton ? (
              <UnstyledButton data-test-id="confirm-button" onClick={onConfirm}>
                <Text color={TextColor.Link} size={Size.Medium} bold>
                  {confirmButton}
                </Text>
              </UnstyledButton>
            ) : null}
          </ButtonContainer>
        </Block>
      </Overlay>
    );
  }
}
