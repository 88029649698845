import { supportsTouch } from "@root/lib/utils/supports-touch";
import { getBrowserFromUserAgent, getOsFromUserAgent } from "@root/lib/utils/user-agent";
import { DEPRECATED_DO_NOT_USE_SEE_DOC_COMMENT_ExternalDataStore } from "@root/shared";
import { UAParser } from "ua-parser-js";
import { OS } from "../models/os";
import StringHelper from "../utils/string-helper";
import * as Strings from "../utils/strings";
import VersionComparer from "../utils/version-comparer";
import { logger, LogProperties } from "@root/lib/telemetry";

export class Compatibility {
  public compatible!: boolean;
  public osNameCompatible?: boolean;
  public osVersionCompatible?: boolean;
  public browserCompatible?: boolean;
  public privateBrowsing?: boolean;
  public incompatibilityMessage!: string;

  // For telemetry
  public userAgent?: string;
  public userAgentOsName?: string;
  public userAgentBrowserName?: string;
  public userAgentOsVersion?: string;
  public userAgentBrowserVersion?: string;
}

export class CompatibilityStore extends DEPRECATED_DO_NOT_USE_SEE_DOC_COMMENT_ExternalDataStore<Compatibility> {
  public fetchDataPromise!: Promise<Compatibility>;

  // See https://github.com/faisalman/ua-parser-js for all possible user-agent OS constants
  public static UserAgent = {
    OSNames: {
      iOS: "iOS",
      macOS: "Mac OS",
      Android: "Android",
      Windows: "Windows",
      WindowsPhone: "Windows Phone",
    },
    OSDisplayNames: {
      "Mac OS": "macOS",
    },
    BrowserNames: {
      MobileSafari: "Mobile Safari",
      Chrome: "Chrome",
      AndroidBrowser: "Android Browser",
      SamsungInternet: "Samsung Internet",
      Edge: "Edge",
      Firefox: "Firefox",
    },
  };

  constructor() {
    super();
  }

  public checkCompatibility(os?: string, minOsVersion?: string, appDisplayName?: string): Promise<Compatibility> {
    const parser = new UAParser();

    const userAgent: string = parser.getUA();

    if (OS.isCustom(os!)) {
      const compatibility: Compatibility = {
        compatible: true,
        osNameCompatible: true,
        osVersionCompatible: true,
        browserCompatible: true,
        incompatibilityMessage: "",
        userAgent: userAgent,
      };

      return this.load(Promise.resolve(compatibility));
    }

    const userAgentOs = getOsFromUserAgent();
    const userAgentBrowser = this.identifyAndroidBrowser(getBrowserFromUserAgent(), userAgent);

    if (userAgentOs !== undefined && userAgentBrowser !== undefined) {
      const osNameCompatible: boolean | undefined = this.isOsNameCompatible(os!, userAgentOs.name!);
      const userAgentOsVersion: string = userAgentOs.version as any;

      const osVersionCompatible: boolean | undefined = OS.isWindows(os!)
        ? true
        : this.isOsVersionCompatible(osNameCompatible!, userAgentOsVersion, minOsVersion!);
      const browserCompatible = true;

      // Disable checking for private browsing mode for now
      // const privateBrowsingCheck: Promise<boolean> = OS.isWindows(os) || OS.isMacOS(os) ? Promise.resolve(false) : this.isPrivateBrowsing(userAgentBrowser.name);
      const privateBrowsingCheck: Promise<boolean> = Promise.resolve(false);

      this.fetchDataPromise = privateBrowsingCheck.then((privateBrowsing: boolean) => {
        const compatible: boolean = this.isCompatible(
          minOsVersion!,
          osNameCompatible!,
          osVersionCompatible!,
          browserCompatible,
          privateBrowsing
        );
        const incompatibilityMessage: string = compatible
          ? ""
          : this.getIncompatibilityMessage(
              os!,
              minOsVersion!,
              appDisplayName!,
              userAgentOs.name!,
              osNameCompatible!,
              osVersionCompatible!,
              browserCompatible,
              privateBrowsing
            );

        const compatibility: Compatibility = {
          compatible: compatible,
          osNameCompatible: osNameCompatible,
          osVersionCompatible: osVersionCompatible,
          browserCompatible: browserCompatible,
          privateBrowsing: privateBrowsing,
          incompatibilityMessage: incompatibilityMessage,
          userAgent: userAgent,
          userAgentOsName: userAgentOs.name,
          userAgentBrowserName: userAgentBrowser.name,
          userAgentOsVersion: userAgentOs.version,
          userAgentBrowserVersion: userAgentBrowser.version,
        };

        return compatibility;
      });
    } else {
      // Either OS or browser couldn't be detected in user agent string
      this.fetchDataPromise = new Promise<Compatibility>((resolve) => {
        const compatibility: Compatibility = {
          compatible: false,
          incompatibilityMessage: Strings.Install.Incompatible.Other,
          userAgent: userAgent,
        };
        return compatibility;
      });
    }

    return this.load(this.fetchDataPromise);
  }

  public static isWindowsPhoneUserAgent(): boolean {
    const os = getOsFromUserAgent().name;
    if (os === CompatibilityStore.UserAgent.OSNames.WindowsPhone) {
      return true;
    }
    return false;
  }

  public checkCompatibilityAndTrack(
    telemetryName: string,
    telemetryProps: LogProperties,
    os?: string,
    minOsVersion?: string,
    appDisplayName?: string
  ): Promise<Compatibility> {
    return this.checkCompatibility(os, minOsVersion, appDisplayName).then((compatibility: Compatibility) => {
      if (telemetryName) {
        if (telemetryProps) {
          Object.assign(telemetryProps, compatibility);
        }
        logger.info(telemetryName, telemetryProps);
      }
      return compatibility;
    });
  }

  public compatibleBrowserForOs(os?: string): string {
    // Attempt to get the OS from the user-agent if none is provided in param
    os = os || getOsFromUserAgent().name;
    const ua = CompatibilityStore.UserAgent;
    switch (os) {
      case ua.OSNames.iOS:
        return ua.BrowserNames.MobileSafari;
      case ua.OSNames.Android:
        return ua.BrowserNames.Chrome;
      default:
        return "";
    }
  }

  public isSafariBrowser(): boolean {
    const userAgentBrowser = getBrowserFromUserAgent();
    const browserNames = CompatibilityStore.UserAgent.BrowserNames;
    return userAgentBrowser && browserNames.MobileSafari === userAgentBrowser.name;
  }

  public isIOSSafariBrowserInDesktopMode(): boolean {
    const parser = new UAParser();
    const parsedOs = parser.getOS();
    const osName = parsedOs.name;

    // IPadOS 13 opens safari in desktop mode by default. IPad Safari in desktop mode behaves similar to MacOS's safari, so their user agents are same.
    // Detecting browser touch support to determine whether the device is an ipad or not.
    if (osName && osName.toLowerCase().startsWith("mac") && supportsTouch()) {
      return true;
    }
    return false;
  }

  private isCompatible(
    minOsVersion: string,
    isOsNameCompatible: boolean,
    isOsVersionCompatible: boolean,
    isBrowserCompatible: boolean,
    isPrivateBrowsing: boolean
  ): boolean {
    return isOsNameCompatible && isOsVersionCompatible && isBrowserCompatible && !isPrivateBrowsing;
  }

  private isOsNameCompatible(os: string, userAgentOsName: string): boolean | undefined {
    if (!os) {
      return undefined;
    }

    const osNames = CompatibilityStore.UserAgent.OSNames;
    switch (userAgentOsName) {
      case osNames.iOS:
        return OS.isIos(os);
      case osNames.macOS:
        return OS.isMacOS(os);
      case osNames.Android:
        return OS.isAndroid(os);
      case osNames.Windows:
      case osNames.WindowsPhone:
        return OS.isWindows(os);
      default:
        return false;
    }
  }

  private isOsVersionCompatible(isOsNameCompatible: boolean, userAgentOsVersion: string, minOsVersion: string): boolean | undefined {
    if (isOsNameCompatible) {
      if (minOsVersion) {
        return VersionComparer.compare(userAgentOsVersion, minOsVersion) >= 0;
      } else {
        return true;
      }
    } else {
      return undefined;
    }
  }

  /**
   * If the browser is "Android Browser", check if it's actually something more specific,
   * like "Samsung Internet" http://developer.samsung.com/technical-doc/view.do?v=T000000203
   */
  private identifyAndroidBrowser(userAgentBrowser: IUAParser.IBrowser, userAgent: string): IUAParser.IBrowser {
    const browserNames = CompatibilityStore.UserAgent.BrowserNames;
    if (!userAgentBrowser || userAgentBrowser.name !== browserNames.AndroidBrowser) {
      return userAgentBrowser;
    } else {
      const foundSamsungInternet: boolean = /SamsungBrowser/.test(userAgent);
      return foundSamsungInternet ? Object.assign(userAgentBrowser, { name: browserNames.SamsungInternet }) : userAgentBrowser;
    }
  }

  // Temporarily make this public to suppress ts error
  public isPrivateBrowsing(browser: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const browsers = CompatibilityStore.UserAgent.BrowserNames;
      switch (browser) {
        case browsers.MobileSafari:
          resolve(this.isPrivateBrowsingInSafari());
        case browsers.Chrome:
          resolve(this.isPrivateBrowsingInChrome());
        case browsers.Edge:
          resolve(this.isPrivateBrowsingInEdge());
        default:
          resolve(false);
      }
    });
  }

  // http://codereview.stackexchange.com/questions/127750/detect-if-ie10-and-edge-is-using-inprivate-mode
  private isPrivateBrowsingInEdge(): boolean {
    return !window.indexedDB && !!((window as any).PointerEvent || (window as any).MSPointerEvent);
  }

  // https://github.com/atuttle/node-is-private-browser/blob/ea27c1e1a965a23fecc224bd34a7882a1ba99914/index.js
  private isPrivateBrowsingInSafari(): boolean {
    if (typeof localStorage === "undefined") {
      return false;
    }

    try {
      // don't use safeLocalStorage because we're trying to determine if localStorage is available.
      localStorage.setItem("private-browser-detection", "test");
      const localStorageTestFailed: boolean = localStorage.getItem("private-browser-detection") !== "test";
      localStorage.removeItem("private-browser-detection");

      // The localStorage trick doesn't work after Safari 11.0, so fallback to checking for DNT (do not track) header
      // https://stackoverflow.com/questions/2860879/detecting-if-a-browser-is-using-private-browsing-mode/45054262#45054262
      const dntEnabled: boolean = (navigator as any).doNotTrack === "1" || (window as any).doNotTrack === "1";

      return localStorageTestFailed || dntEnabled;
    } catch (e) {
      return true;
    }
  }

  // http://stackoverflow.com/a/37091058/1330341
  private isPrivateBrowsingInChrome(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const on = function () {
        resolve(true);
      };
      const off = function () {
        resolve(false);
      };
      const windowHack = window as any; // Suppress TypeScript error
      windowHack.webkitRequestFileSystem ? windowHack.webkitRequestFileSystem(0, 0, off, on) : off();
    });
  }

  private getIncompatibilityMessage(
    os: string,
    minOsVersion: string,
    appDisplayName: string,
    userAgentOs: string,
    osNameCompatible: boolean,
    osVersionCompatible: boolean,
    browserCompatible: boolean,
    privateBrowsing: boolean
  ): string {
    const incompatibleStrings = Strings.Install.Incompatible;
    const compatibleBrowser: string = this.compatibleBrowserForOs(userAgentOs);

    if (os && !osNameCompatible) {
      const osDisplayName: string = CompatibilityStore.UserAgent.OSDisplayNames[userAgentOs];
      return StringHelper.format(incompatibleStrings.OpenPageInDevice, osDisplayName || userAgentOs);
    } else if (minOsVersion && !osVersionCompatible) {
      return StringHelper.format(incompatibleStrings.OpenPageInMinOsVersion, userAgentOs, minOsVersion);
    } else if (!browserCompatible) {
      if (appDisplayName) {
        return StringHelper.format(incompatibleStrings.OpenPageInBrowser, appDisplayName, compatibleBrowser);
      } else {
        return StringHelper.format(incompatibleStrings.OpenPageInBrowserNoApp, compatibleBrowser);
      }
    } else if (privateBrowsing) {
      if (appDisplayName) {
        if (compatibleBrowser === CompatibilityStore.UserAgent.BrowserNames.MobileSafari) {
          return StringHelper.format(incompatibleStrings.OpenPageInNonPrivateBrowsingSafari, appDisplayName);
        } else {
          return StringHelper.format(incompatibleStrings.OpenPageInNonPrivateBrowsing, appDisplayName, compatibleBrowser);
        }
      } else {
        return StringHelper.format(incompatibleStrings.OpenPageInNonPrivateBrowsingNoApp, compatibleBrowser);
      }
    } else {
      return incompatibleStrings.Other;
    }
  }
}

export const compatibilityStore = new CompatibilityStore();
