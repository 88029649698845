// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/close-icon.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3m7LijLIx{transition-duration:.2s;height:32px;width:32px;background-color:rgba(0,0,0,0);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center center;border-radius:50%;border:1px solid rgba(255,255,255,.3);cursor:pointer}._3m7LijLIx:hover{border-color:rgba(255,255,255,.6)}", "",{"version":3,"sources":["webpack://client/install-beacon/components/close-button/close-button.scss"],"names":[],"mappings":"AAEA,YAEE,uBAAA,CAEA,WAAA,CACA,UAAA,CAEA,8BAAA,CACA,wDAAA,CACA,2BAAA,CACA,iCAAA,CAEA,iBAAA,CACA,qCAAA,CAEA,cAAA,CAEA,kBACE,iCAAA","sourcesContent":["@import \"~@css/vars.scss\";\n\n.button {\n  -webkit-transition-duration: 0.2s; /* Safari */\n  transition-duration: 0.2s;\n\n  height: 32px;\n  width: 32px;\n\n  background-color: transparent;\n  background-image: url(../../assets/close-icon.svg);\n  background-repeat: no-repeat;\n  background-position: center center; \n\n  border-radius: 50%;\n  border: 1px solid rgba(255,255,255,0.3);\n\n  cursor: pointer;\n\n  &:hover {\n    border-color: rgba(255,255,255,0.6) ;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"button": "_3m7LijLIx"
};
export default ___CSS_LOADER_EXPORT___;
