// TODO: Move all of this to a localized string file

export const MobileCenter = "App Center";

export const App = {
  VersionAndSize: "Version {0}, {1}",
  BackToApps: "Back to my apps",
  NotAuthorizedNotFound: "Your account doesn't have access or this app doesn't exist.",
  SignedInAs: "Signed in as",
  SignInWithDifferentAccount: "Sign in with a different account",
  ErrorDeviceNotProvisioned: "The developer will send you an email once there's a new version suitable for your device.",
  ErrorProfileType: "This application cannot be installed because it was not provisioned with an Ad-Hoc or Enterprise profile.",
  TvOsNoticeMessage: "This is a tvOS app.",
};

export const Releases = {
  Title: "Releases",
  Install: "Install",
  Download: "Download",
  NoReleasesTitle: "This app is not yet released.",
  NoReleasesSubtitle: "You’ll receive an email once this app is released.",
  Latest: "Latest release",
};

export const Release = {
  Title: "Release {0}",
  NotFound: "Release {0} not found",
};

export const Generic = {
  Help: "Help!",
  PoweredBy: "Powered by",
  SignOut: "Sign out",
  Dismiss: "Dismiss",
};

export const Install = {
  AfterDownloadInstructionDesktop: "Go to the Downloads folder and double-click on the downloaded file.",
  AfterDownloadInstructionMobileInstall: "Tap on the notification and INSTALL when the download is finished.",
  AfterTesterAppDownloadInstructionMobilePressHome: "After installing, go to the home screen to check progress.",
  AfterDownloadInstructionMobilePressHome: "Go to home screen to check progress.",
  StatusDownloading: "Downloading…",
  Cancel: "Cancel",
  DeviceNotificationDownloadComplete: "download complete",
  CopyLink: "Tap and hold to copy the link to this page",
  Incompatible: {
    OpenPageInDevice: "This app is not compatible with {0}.",
    OpenPageInMinOsVersion: "This app can only be installed on {0} version {1} or above.",
    OpenPageInBrowser: 'To install "{0}," open this page using {1}.',
    OpenPageInBrowserNoApp: "To install open this page using {0}.",
    OpenPageInNonPrivateBrowsing: 'To install "{0}," disable Private mode in {1}.',
    OpenPageInNonPrivateBrowsingSafari: 'To install "{0}," disable Private mode and/or Do Not Track mode in Safari.',
    OpenPageInNonPrivateBrowsingNoApp: "To install disable Private mode in {0}.",
    Other: "An unknown error occurred.",
    SafariBrowser: 'If you are using iOS, please enable "Request Mobile Website" for this site.',
  },
  DefaultUserId: "00000000-0000-0000-0000-000000000001",
};

export const UpdateSetup = {
  UpdateEnablingMessage: "In-app updates enabled!\nReturning to app in {0}...",
  ReturnToApp: "Return to App Now",
  UpdateErrorMessagePrivate: "In-app updates can't be enabled while using Private mode in {0}",
  UpdateErrorInstructionsPrivate: "Disable Private mode from the {0} app and try again.",
  UpdateErrorMessageCookies:
    "Failed to enable in-app updates. Reinstall the app from App Center in non-private browsing to enable them.",
  UpdateErrorMessageParams: "Unfortunately, this feature couldn't be enabled. Please contact our support team.",
  UpdateErrorWithContextId: "{0} (Support ID: {1})",
  UpdateSetupFailed: "This release was either side-loaded or downloaded using a browser in private mode.",
};

export const UpdateInviteEmail = {
  ChooseAccount: "Choose an account",
  ContinueAs: "Continue as {0}",
  SignInAs: "Sign in as",
};

export const Register = {
  Instructions: "Register your device for testing apps.",
  ButtonTitleRegister: "Register",
  ButtonTitleSkip: "I already registered it",
};

export const CommonStrings = {
  VersionWithShortVersion: "{0} ({1})",
};

export const InstallHelp = {
  Title: "Help",
};

export const DeviceRegisteredPage = {
  Title: "Your device was registered",
  Text: "Redirecting...",
};
