import { safeLocalStorage } from "@root/lib/utils/safe-local-storage";
import { action, observable } from "mobx";
import { compatibilityStore } from "./compatibility-store";

class IncompatibleSafariWarningStore {
  @observable
  public shouldShowMessage: boolean;

  constructor() {
    this.shouldShowMessage =
      compatibilityStore.isIOSSafariBrowserInDesktopMode() && safeLocalStorage.getItem("show-incompatible-safari-warning") !== "false";
  }

  @action
  public hideWarningMessage() {
    this.shouldShowMessage = false;
    safeLocalStorage.setItem("show-incompatible-safari-warning", "false");
  }
}

export const incompatibleSafariWarningStore = new IncompatibleSafariWarningStore();
