import * as React from "react";
import { Skeletal } from "@root/shared";
import { TesterApp } from "@root/data/install";
import { locationStore } from "@root/stores";
import { AppDetailsComponent } from "../components/app-details-component/app-details-component";
import { observer } from "mobx-react";
import { ListItemLink } from "../components/list-item-link/list-item-link";
import { range } from "lodash";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = require("./app-list.scss");

export interface AppListProps extends Skeletal {
  apps: TesterApp[];
  grayOut?: boolean;
}

export const AppList = withTranslation(["install"])(
  observer(
    class AppList extends React.Component<AppListProps & WithTranslation, {}> {
      public render() {
        if (this.props.skeleton) {
          return (
            <ul className={styles["list"]}>
              {range(0, 3).map((value) => {
                return (
                  <ListItemLink key={value}>
                    <AppDetailsComponent skeleton />
                  </ListItemLink>
                );
              })}
            </ul>
          );
        }
        const { t, apps } = this.props;
        const locationHref = window.location?.href;
        const url = locationHref ? new URL(locationHref) : undefined;
        const noCheck = url && url.searchParams.has("no_check");

        if (apps.length === 0) {
          return (
            <div className={styles["no-apps-found-container"]}>
              <div>{t("install:app.appsList.noAppsFound")}</div>
            </div>
          );
        }

        const list: JSX.Element[] = apps.map((app: TesterApp, index: number) => {
          return (
            <ListItemLink
              key={index}
              divider={true}
              link={locationStore.getUrlWithApp("", app) + (noCheck ? "?no_check" : "")}
              aria-label={app.display_name + ", " + app.os}
              {...this.props}
            >
              <AppDetailsComponent app={app} />
            </ListItemLink>
          );
        });

        return (
          <ul data-test-id="apps-list" className={styles["list"]}>
            {list}
          </ul>
        );
      }
    }
  )
);
