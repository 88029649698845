import * as React from "react";
import { AppIcon, Gravatar, NextButton, Color } from "@root/shared";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import { withRouter } from "react-router";

import { DistributionGroupInvitationStore } from "../stores/distribution-group-invitation-store";
import { Footer } from "../components/footer";
import { userStore } from "@root/stores";
import * as Strings from "../utils/strings";
import StringHelper from "../utils/string-helper";

const styles = require("./update-invite-email.scss");

export interface UpdateEmailProps {
  appName: string;
  ownerName: string;
  appDisplayName: string;
  originalUserEmail: string;
}

@withRouter
@observer
export class UpdateInviteEmail extends React.Component<UpdateEmailProps & RouteComponentProps<any, any>, {}> {
  private _distributionGroupInvitationStore!: DistributionGroupInvitationStore;
  private _initProps!: UpdateEmailProps;
  private _token!: string;

  public UNSAFE_componentWillMount() {
    this._initProps = (window as any).initProps;
    this._token = this.props.routeParams["token"];
    this._distributionGroupInvitationStore = new DistributionGroupInvitationStore(this._token);

    if (userStore.currentUser.email === this._initProps.originalUserEmail) {
      this._distributionGroupInvitationStore.update(this._onUpdateEmailSuccess);
    }
  }

  public render() {
    const props = this._initProps;
    const currentUserEmail = userStore.currentUser.email;
    const originalUserEmail = props.originalUserEmail;
    if (currentUserEmail === originalUserEmail) {
      return null;
    }

    const originalUrl = `/invitations/tester/${this._token}`;
    const logOutUrl = `/logout?original_url=${originalUrl}`;

    return (
      <div className={styles["main"]}>
        <div className={styles["top"]}>
          <AppIcon size={100} value={props.appDisplayName} />
          <div className={styles["app-display-name"]}>{props.appDisplayName}</div>
        </div>
        <div className={styles["bottom"]}>
          <div className={styles["choose-account"]}>{Strings.UpdateInviteEmail.ChooseAccount}</div>
          {this._accountPicker(
            currentUserEmail,
            () => this._distributionGroupInvitationStore.update(this._onUpdateEmailSuccess),
            userStore.currentUser.name
          )}
          {this._accountPicker(originalUserEmail, () => (window.location.href = logOutUrl))}
          <div className={styles["footer"]}>
            <Footer isPublic={false} />
          </div>
        </div>
      </div>
    );
  }

  private _onUpdateEmailSuccess = (): void => {
    window.location.assign(`/users/${this._initProps.ownerName}/apps/${this._initProps.appName}`);
  };

  private _accountPicker(email: string, onClick: () => void, name?: string): JSX.Element {
    const buttonColor = name ? Color.Blue : null;
    const continueAs = name ? (
      <div>{StringHelper.format(Strings.UpdateInviteEmail.ContinueAs, userStore.currentUser.name)}</div>
    ) : (
      <div className={styles["sign-in-as"]}>{Strings.UpdateInviteEmail.SignInAs}</div>
    );
    const emailStyle = name ? null : "email-dark";

    return (
      <NextButton color={buttonColor!} className={styles["user"]} onClick={() => onClick()}>
        <div className={styles["account-picker"]}>
          <Gravatar size={40} className={styles["gravatar"]} email={email} />
          <div className={styles["user-info"]}>
            {continueAs}
            <div className={styles[emailStyle!]}>{email}</div>
          </div>
        </div>
      </NextButton>
    );
  }
}
