import * as React from "react";
import { observer } from "mobx-react";
import { Generic, MobileCenter } from "../../utils/strings";
import { Size, Text, TextColor } from "@root/shared";

const styles = require("./footer.scss");

export interface FooterProps {
  isPublic: boolean;
}

@observer
export class Footer extends React.Component<FooterProps, {}> {
  // TODO:  Remove hardcoded strings for Help
  //  I needed to do this because the linting rule is doing an Object compare instead of a deep compare.
  // Since both the Help and SignOut values are coming from the same constant string object, it is failing the check
  // I also have not been able to disable the tslint rule for this file.  I will use the string variables
  // again once I fix the linter.
  public render() {
    return (
      <div className={styles["footer"]}>
        <div className={styles["powered-by-container"]}>
          <Text size={Size.Small} color={TextColor.Secondary} className={styles["powered-by"]}>
            {Generic.PoweredBy}
          </Text>
          <Text size={Size.Small} color={TextColor.Secondary} bold>
            {MobileCenter}
          </Text>
        </div>
        <div className={styles["help"]}>
          {this.renderSignOut()}
          <a href="https://go.microsoft.com/fwlink/?linkid=834790" target="_blank">
            Help!
          </a>
        </div>
      </div>
    );
  }

  private renderSignOut() {
    if (this.props.isPublic) {
      return null;
    }

    return (
      <div>
        <a href="/logout">{Generic.SignOut}</a>
        <span aria-hidden tabIndex={-1}>
          |
        </span>
      </div>
    );
  }
}
