import * as React from "react";
import { Menu, Trigger, ClickableIcon, Action, IconName } from "@root/shared";
import { useTranslation } from "react-i18next";

export const StopTestingMenu: React.FunctionComponent<{ onClick(): void }> = ({ onClick }) => {
  const { t } = useTranslation(["install"]);
  return (
    <Menu>
      <Trigger>
        <ClickableIcon icon={IconName.More} />
      </Trigger>
      <Action text={t("install:app.tester.stopTestingMenu")} danger onClick={onClick} />
    </Menu>
  );
};
