import * as React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router";
import { Gravatar, Progress, Color, ProgressDirection } from "@root/shared";
import { userStore } from "@root/stores";
import { Routes } from "@root/install-beacon/utils/constants";
import { AppCenterLogo } from "@root/shared/appcenter-logo/app-center-logo";

const styles = require("./navigation-bar.scss");

export interface NavigationBarProps {
  homeLink?: string;
  showProfileLink?: boolean;
  loading?: boolean;
  controlsArea?: React.ReactNode;
}

@observer
export class NavigationBar extends React.Component<NavigationBarProps, {}> {
  public render() {
    const { controlsArea, showProfileLink, loading } = this.props;

    return (
      <div className={styles.navigationWrapper}>
        <div className={styles.navigation}>
          {this.renderAppCenter()}
          <div className={styles.controls}>
            {controlsArea}
            {showProfileLink ? this.renderProfileButton() : null}
          </div>
        </div>
        <Progress color={Color.Blue} loading={loading!} direction={ProgressDirection.Above} />
      </div>
    );
  }

  public renderAppCenter(): JSX.Element {
    const { homeLink } = this.props;
    if (!userStore.userLoggedIn) {
      return (
        // Notice its "href" and not "to", as this needs to trigger a site refresh to make the redirects work
        <AppCenterLogo href="/" />
      );
    } else {
      return <AppCenterLogo to={homeLink} />;
    }
  }

  public renderProfileButton(): JSX.Element | undefined {
    if (!userStore.currentUser || !this.props.showProfileLink) {
      return undefined;
    }
    const { email } = userStore.currentUser;

    return userStore.userLoggedIn ? this.renderAvatarButton(email) : this.renderLoginButton();
  }

  public renderAvatarButton(email: string): JSX.Element {
    return (
      <Link className={styles["profile"]} to={Routes.Profile}>
        <Gravatar email={email} size={24} />
      </Link>
    );
  }

  public renderLoginButton(): JSX.Element {
    return (
      <a className={styles["signin"]} href={Routes.SignIn}>
        Sign In
      </a>
    );
  }
}
