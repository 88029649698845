// Referred https://gist.github.com/TheDistantSea/8021359
export default class VersionComparer {
  public static compare(left: string, right: string): number {
    if (left == null || right == null) {
      return NaN;
    }

    const leftParts: string[] = left.split(".");
    const rightParts: string[] = right.split(".");

    if (!leftParts.every(VersionComparer.isValidVersionPart) || !rightParts.every(VersionComparer.isValidVersionPart)) {
      return NaN;
    }

    const leftPartNumbers: number[] = leftParts.map(Number);
    const rightPartNumbers: number[] = rightParts.map(Number);

    while (leftPartNumbers.length < rightPartNumbers.length) {
      leftPartNumbers.push(0);
    }
    while (rightPartNumbers.length < leftPartNumbers.length) {
      rightPartNumbers.push(0);
    }

    for (let i = 0; i < leftPartNumbers.length; i++) {
      if (leftPartNumbers[i] === rightPartNumbers[i]) {
        continue;
      } else if (leftPartNumbers[i] > rightPartNumbers[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    return 0;
  }

  private static isValidVersionPart(part: string) {
    return /^\d+$/.test(part);
  }
}
