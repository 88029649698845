import * as React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import { RegistrationUtils } from "../utils/registration-utils";
import { logger } from "@root/lib/telemetry";

/**
 * This page is unauthenticated and will simply save off the device registration information in
 * a cookie and redirect to the originating URL (or app).
 */
@observer
export class RegisterDeviceComplete extends React.Component<RouteComponentProps<any, any>, {}> {
  public UNSAFE_componentWillMount() {
    // Get the query params.
    let queryParams;
    if (this.props.location && this.props.location.query) {
      queryParams = this.props.location.query;
    }

    // Get the UDID.
    const udid = queryParams && queryParams.udid;
    if (!udid) {
      logger.info("No Device ID provided to completion page");
    }

    // Get the url to redirect back to.
    let originalUrl;
    if (queryParams && queryParams.original_url) {
      // Only use the url if it is for the Tester app.
      if (queryParams.original_url.indexOf("ms-actesterapp://") === 0) {
        originalUrl = queryParams.original_url;
      } else {
        logger.info("Non-Tester App URL provided to completion page", { url: queryParams.original_url });
      }
    } else {
      logger.info("No Original URL provided to completion page");
    }

    // If we have the UDID and the originalUrl, complete the registration.
    if (udid && originalUrl) {
      RegistrationUtils.finishDeviceRegistration(udid.toString(), false, true);
    } else {
      // We don't have what we need to complete the registration, so go back to the main page of the
      // install site.
      // NOTE: This should only happen in scenarios where someone is loading this page outside of
      //       the device registration flow.
      originalUrl = window.location.origin;
    }

    // Redirect to the provided URL.
    RegisterDeviceComplete.navigate(originalUrl);
  }

  public render() {
    // No content as this page redirects right away when loaded.
    return null;
  }

  /**
   * Navigates to the provided url.
   *
   * NOTE: This is only here for testing purposes so we can make sure that we are navigating to the right place.
   */
  private static navigate(url: string) {
    window.location.href = url;
  }
}
