import * as ReactRouter from "react-router";

import * as cookie from "tiny-cookie";
import { App } from "./app/app";
import { Install } from "./app/install";
import { Apps } from "./apps/apps";
import { UpdateSetup } from "./update-setup/update-setup";
import { PrivateUpdateSetup } from "./update-setup/private-update-setup";
import { HockeyAppUpdateSetup } from "./hockey-app-update-setup/hockey-app-update-setup";
import { OrgApps } from "@root/install-beacon/apps/org-apps";
import { NotFound } from "./components/not-found";
import { UpdateInviteEmail } from "./update-invite-email/update-invite-email";
import { RegisterDevice } from "./register-device/register-device";
import { RegisterDeviceComplete } from "./register-device/register-device-complete";
import { RegisterDeviceStart } from "./register-device/register-device-start";
import { Profile } from "./profile/profile";
import { Welcome, RegisterDeviceFirstRun, FirstRunFinished, CompleteRegistration } from "./first-run";
import { locationStore } from "@root/stores";
import { getOsFromUserAgent } from "@root/lib/utils/user-agent";
import { OS } from "@root/install-beacon/models/os";
import { compatibilityStore } from "./stores/compatibility-store";

function IOSOnlyCheck(_, replace: ReactRouter.RedirectFunction, cb?: Function) {
  const os = getOsFromUserAgent();
  if (
    !OS.isIos(os.name!) ||
    (OS.isIos(os.name!) && !compatibilityStore.isSafariBrowser()) ||
    compatibilityStore.isIOSSafariBrowserInDesktopMode()
  ) {
    replace("/apps");
  }
  cb!();
}

function startFirstRunAfterSignup(_, replace: ReactRouter.RedirectFunction, cb?: Function) {
  if (cookie.get("signup-origin") === "install") {
    replace("/first-run");
  }
  cb!();
}

/**
 * All Child Routes under a particular path. This creates a nested routing table for that path.
 *
 * Use this to create nested routes for your beacon.
 *
 * For example:
 * Given a path = `/beacon`,
 * Considering the childRoutes below; give us routes - `/beacon/sub` & `/beacon/alt`
 *
 * For more examples, check https://github.com/reactjs/react-router
 */

/**
 * The route object for this `beacon`.
 */
const rootRoute: ReactRouter.PlainRoute = {
  childRoutes: [
    {
      path: "apps",
      component: Apps,
      onEnter: startFirstRunAfterSignup,
    },
    {
      path: "apps/:secret/update-setup",
      component: UpdateSetup,
    },
    {
      path: "apps/:secret/private-update-setup",
      component: PrivateUpdateSetup,
    },
    {
      path: "apps/:secret/ha-update-setup",
      component: HockeyAppUpdateSetup,
    },
    {
      path: locationStore.getParameterizedUrlWithApp(),
      indexRoute: {
        component: App,
      },
      onEnter: startFirstRunAfterSignup,
      childRoutes: [
        {
          // This one is semi-deprecated, the list of all releases used to be a separate page
          path: "releases",
          indexRoute: {
            component: App,
          },
          childRoutes: [
            {
              path: ":id",
              component: App,
            },
          ],
        },
        {
          path: "distribution_groups/:group_name",
          indexRoute: {
            component: App,
          },
          childRoutes: [
            {
              path: "releases",
              indexRoute: {
                component: App,
              },
              childRoutes: [
                {
                  path: ":id",
                  component: App,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "first-run",
      indexRoute: {
        component: Welcome,
      },
      childRoutes: [
        {
          path: "welcome",
          component: Welcome,
        },
        {
          path: "register-device",
          component: RegisterDeviceFirstRun,
          onEnter: IOSOnlyCheck,
        },
        {
          path: "complete-registration",
          component: CompleteRegistration,
          onEnter: IOSOnlyCheck,
        },
        {
          path: "finished",
          component: FirstRunFinished,
          onEnter: IOSOnlyCheck,
        },
      ],
    },
    {
      path: "invitations/tester/:token",
      component: UpdateInviteEmail,
    },
    {
      path: "register-device",
      component: RegisterDevice,
      onEnter: IOSOnlyCheck,
    },
    {
      path: "register-device-start",
      component: RegisterDeviceStart,
    },
    {
      path: "register-device-complete",
      component: RegisterDeviceComplete,
    },
    {
      path: "install-faq",
      component: Install,
    },
    {
      path: "profile",
      component: Profile,
    },
    {
      path: "orgs/:orgName",
      component: OrgApps,
    },
    {
      path: "*",
      component: NotFound,
    },
  ],
};

/**
 * Export the beacon.
 * Note: This **has** to be exported so that shell knows about the beacon &
 * loads it when the `/beacon` link is clicked.
 */
export default {
  beacon: rootRoute,
};
