import * as React from "react";
import { observer } from "mobx-react";
import {
  Title,
  Paragraph,
  Text,
  Size,
  TextColor,
  PrimaryButton,
  MarkdownEditor,
  Icon,
  IconName,
  IconSize,
  Color,
  UnstyledButton,
  Spinner,
  SecondaryButton,
} from "@root/shared";
import { Build } from "@root/data/install";
import BuildHelper from "../utils/build-helper";
import { ReleaseUIStore } from "./stores/release-ui-store";
import { ReleaseAvailableStatus } from "@root/install-beacon/app/stores/app-ui-store";
import { BuildInfo } from "@root/install-beacon/app/build-info";

const styles = require("./release.scss");

export interface ReleaseProps {
  data: Build;
  isPublic?: boolean;
  installEnabled?: boolean;
  releaseAvailable: ReleaseAvailableStatus;
  expanded: boolean;
  onExpand?: (releaseId: string) => void;
  onInstallClicked?: (release: Build) => void;
}

export interface ReleaseState {
  isLoadingFullData: boolean;
  fullData: Build;
}
@observer
export class Release extends React.Component<ReleaseProps, ReleaseState> {
  private releaseUIStore: ReleaseUIStore;
  public static defaultProps: ReleaseProps = {
    data: undefined as any,
    isPublic: false,
    installEnabled: true,
    expanded: false,
    onExpand: undefined,
    releaseAvailable: undefined as any,
  };

  constructor(props: ReleaseProps) {
    super(props);
    this.releaseUIStore = new ReleaseUIStore(
      props.data.id!,
      props.data.appIdentifier!,
      props.onInstallClicked,
      props.isPublic!,
      props.onExpand
    );
  }

  public render() {
    const { expanded, releaseAvailable } = this.props;

    const { release, isLoadingRelease, onExpandClicked, onInstallClicked, installText } = this.releaseUIStore;

    const action = (() => {
      if (expanded) {
        if (isLoadingRelease) {
          return <Spinner size={IconSize.Medium} />;
        } else {
          if (releaseAvailable === ReleaseAvailableStatus.CanGet) {
            return (
              <SecondaryButton className={styles.installButton} onClick={onInstallClicked} data-test-class="install-button">
                Get
              </SecondaryButton>
            );
          } else if (releaseAvailable === ReleaseAvailableStatus.IsPreparing) {
            return (
              <Text size={Size.Medium} color={TextColor.Secondary}>
                Preparing...
              </Text>
            );
          } else if (releaseAvailable === ReleaseAvailableStatus.IsDownloadable) {
            return (
              <PrimaryButton className={styles.installButton} onClick={onInstallClicked} data-test-class="install-button">
                {installText}
              </PrimaryButton>
            );
          } else {
            return;
          }
        }
      } else {
        return <Icon icon={IconName.ButtonExpandMore} size={IconSize.Medium} color={Color.LightGray} />;
      }
    })();

    const content: JSX.Element = (
      <div className={styles["responsive-constraint"]} data-test-class="release">
        <div className={styles["release"]}>
          <div className={styles.releaseContainer}>
            <div className={styles.releaseInfo}>
              <Title className={styles.title} size={Size.XSmall}>
                Version {release.version}
              </Title>
              <Paragraph size={Size.Medium} color={TextColor.Secondary}>
                {BuildHelper.dateString(release.timestamp!)}
              </Paragraph>
              <Paragraph size={Size.Medium} color={TextColor.Secondary}>
                {BuildHelper.filesizeString(release.size!)}
              </Paragraph>
            </div>
            <div className={styles.action}>{action}</div>
          </div>
          {expanded && release.releaseNotes ? (
            // Markdown component (from react-remarkable library) didn’t support className prop: we can revisit this now that we’ve migrated to react-markdown
            <div className={styles["release-notes"]}>
              <MarkdownEditor.Preview value={release.releaseNotes} />
            </div>
          ) : null}
          {expanded && release.build ? (
            <BuildInfo
              branch={release.build.branch}
              commitHash={release.build.commitHash}
              commitMessage={release.build.commitMessage}
            />
          ) : null}
        </div>
      </div>
    );

    return expanded ? (
      content
    ) : (
      <UnstyledButton data-test-class="expand-release" className={styles["unstyled-button"]} onClick={onExpandClicked}>
        {content}
      </UnstyledButton>
    );
  }
}
