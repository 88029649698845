import * as React from "react";
import { observer } from "mobx-react";
import { OrganizationIcon, Paragraph, Size, Space, TextColor, Gravatar, IconSize, IconName, Icon } from "@root/shared";
import { App } from "@root/data/shell/models";
import { isDogfoodTesterApp } from "@root/stores/utils/app-utils";

export interface OrgIconProps {
  app: App;
}

@observer
export class OwnerIconName extends React.Component<OrgIconProps, {}> {
  public render() {
    const { app } = this.props;
    if (isDogfoodTesterApp(app)) {
      return (
        <>
          <Icon icon={IconName.Microsoft} size={IconSize.Medium} />
          <Paragraph size={Size.Medium} color={TextColor.Secondary} spaceAbove={Space.XXSmall}>
            Microsoft Internal
          </Paragraph>
        </>
      );
    }
    if (!app.owner.display_name) {
      return null;
    }
    return (
      <>
        {app.owner.type === "org" ? (
          <OrganizationIcon size={32} organization={app.owner} />
        ) : (
          <Gravatar size={32} email={app.owner.email} />
        )}
        <Paragraph size={Size.Medium} color={TextColor.Secondary} spaceAbove={Space.XXSmall}>
          {app.owner.display_name}
        </Paragraph>
      </>
    );
  }
}
