import * as React from "react";
import { observer } from "mobx-react";
import { AppIcon, Heading, Paragraph, Size, IdenticonColors, getColor, Skeletal } from "@root/shared";
import { OS } from "@lib/common-interfaces";
import { App } from "@root/data/shell/models/app";
import { isDogfoodTesterApp } from "@root/stores/utils/app-utils";

const styles = require("./app-info.scss");

export interface AppInfoProps extends Skeletal {
  app: App;
}

@observer
export class AppInfo extends React.Component<AppInfoProps, {}> {
  public render() {
    if (this.props.skeleton) {
      return (
        <div>
          <div className={styles["background-app-icon-skeleton"]} />
          <div className={styles["app-icon-and-text"]}>
            <div className={styles["app-icon-skeleton"]} />
            <div className={styles["app-text"]}>
              <div className={styles["heading-skeleton"]} />
              <div className={styles["paragraph-skeleton"]} />
              <div className={styles["paragraph-skeleton"]} />
            </div>
          </div>
        </div>
      );
    }
    const { app } = this.props;

    if (!app) {
      return null;
    }

    return (
      <div>
        <div className={styles["background-app-icon"]} style={this.backgroundAppIconStyle} />
        <div className={styles["app-icon-and-text"]}>
          <AppIcon size={64} app={app} />
          <div className={styles["app-text"]}>
            <Heading className={styles["app-name"]} size={Size.Medium} invert>
              {app.display_name}
            </Heading>
            <Paragraph size={Size.Medium} className={styles["app-text-info"]} invert>
              <span className={styles["app-text-os"]}>for {app.os}</span> by {this.ownerName(app)}
            </Paragraph>
          </div>
        </div>
      </div>
    );
  }

  private ownerName(app: App): String {
    if (isDogfoodTesterApp(app)) {
      return "Microsoft Internal";
    }
    return app.ownerFriendlyName;
  }

  private get backgroundAppIconStyle() {
    const { app } = this.props;
    const style = {};

    // App icons from URL are temporarily disabled for Windows apps until they can be
    // properly extracted from the build, so that it doesn't show the ugly X image
    if (app && app.icon_url && app.os !== OS.WINDOWS) {
      style["backgroundImage"] = `linear-gradient(
                                     rgba(0, 0, 0, 0.4),
                                     rgba(0, 0, 0, 0.4)
                                    ),
                                   url(${app.icon_url})`;
    } else if (app) {
      style["background"] = `linear-gradient(
                               to right,
                               rgba(0, 0, 0, 0.5),
                               rgba(38, 38, 38, 0.5),
                               rgba(0, 0, 0, 0.5)
                              ),
                             ${getColor(app.id, IdenticonColors.colors)}`;
      style["backgroundBlendMode"] = "luminosity";
    }

    return style;
  }
}
