import * as React from "react";
import * as classNames from "classnames";
import * as PropTypes from "prop-types";
import { omit } from "lodash";
import { NavigationBar } from "@root/install-beacon/components/navigation-bar";
const { Children } = React;

const styles = require("./page.scss");

export interface PageProps extends React.HTMLAttributes<HTMLElement> {
  navBar?: React.ReactElement<any>;
}

const isRecognizedType = (t: ChildType) => t === NavigationBar;

type ChildType = React.ComponentClass<any> | React.StatelessComponent<any> | string;

export class Page extends React.Component<PageProps, {}> {
  public static propTypes: React.ValidationMap<PageProps> = {
    navBar: PropTypes.element as any,
  };

  private children!: Map<ChildType, React.ReactElement<any>>;
  private container = React.createRef<HTMLDivElement>();

  constructor(props: PageProps) {
    super(props);
    this.saveChildTypes(props.children);
  }

  private get navBar() {
    return this.props.hasOwnProperty("topBar") ? this.props.navBar : this.children.get(NavigationBar);
  }

  private get pageContents() {
    return this.children.get("contents");
  }

  private saveChildTypes(children: React.ReactNode) {
    this.children = new Map(
      // @ts-ignore. [Should fix it in the future] Strict error.
      Children.toArray(children).map((c: React.ReactElement<any>): [ChildType, React.ReactElement<any>] => [
        isRecognizedType(c.type) ? c.type : "contents",
        c,
      ])
    );
  }

  public UNSAFE_componentWillReceiveProps(nextProps: PageProps) {
    if (nextProps.children !== this.props.children) {
      this.saveChildTypes(nextProps.children);
    }
  }

  public render() {
    const { className } = this.props;
    const passthrough = omit(this.props, "styles", "className", "children", Object.keys((this.constructor as typeof Page).propTypes));

    return (
      <div className={classNames(styles.page, className)} {...passthrough} ref={this.container}>
        {this.navBar}
        <div className={this.navBar ? styles.scrollContainerWithNavBar : styles.scrollContainer}>{this.pageContents}</div>
      </div>
    );
  }
}
