import * as React from "react";
import { observer } from "mobx-react";
import { Title, Text, Size, TextColor } from "@root/shared";
import { DeviceRegisteredPage as Strings } from "../../utils/strings";

const styles = require("./device-registered-page.scss");

export interface DeviceRegisteredPageProps {
  title?: string;
}

@observer
export class DeviceRegisteredPage extends React.Component<DeviceRegisteredPageProps, {}> {
  public render() {
    const { title } = this.props;
    return (
      <div className={styles["container"]}>
        <div className={styles["content"]}>
          <div className={styles["spinner"]} />
          <Title className={styles["title"]} size={Size.Medium}>
            {title || Strings.Title}
          </Title>
          <Text size={Size.Medium} color={TextColor.Secondary}>
            {Strings.Text}
          </Text>
        </div>
      </div>
    );
  }
}
