export default class StringHelper {
  // https://gist.github.com/fauxtrot/8349c9bd2708a8fa8455
  public static format(format: string, ...args: any[]): string {
    let i: number;
    if (args instanceof Array) {
      for (i = 0; i < args.length; i++) {
        format = format.replace(new RegExp("\\{" + i + "\\}", "gm"), args[i]);
      }
      return format;
    }
    for (i = 0; i < arguments.length - 1; i++) {
      format = format.replace(new RegExp("\\{" + i + "\\}", "gm"), arguments[i + 1]);
    }
    return format;
  }

  /**
   * Obfuscate a string by replacing characters with asterisk.
   *
   * @static
   * @param {number} [percentage=50] the percentage of the string that will not be obfuscated.
   * @param {number} [maxLength] the maximum length of a string. the cut string will be part of the obfuscate string. negative number, zero or underfined means unlimited.
   * For example: percentage=50, maxLength=100, string=[150 times a], [75 times 'a'][25 asterisk] (last 50 are cut).
   */
  public static obfuscate(str: string, percentage = 50, maxLength?: number): string {
    if (!str) {
      return str;
    }

    let result = str;

    if (maxLength && maxLength > 0 && str.length > maxLength) {
      result = result.substring(0, maxLength);
    }

    if (percentage <= 0) {
      return result;
    }

    if (percentage >= 100) {
      return "*".repeat(result.length);
    }

    let obfuscateUntil = Math.floor(str.length * (percentage / 100));
    obfuscateUntil = Math.min(obfuscateUntil, result.length);

    return result.substring(0, Math.floor(obfuscateUntil)) + "*".repeat(result.length - obfuscateUntil);
  }
}
