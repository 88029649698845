import * as React from "react";
import { Paragraph, Size, Space } from "@root/shared";

export const Faqs = {
  FaqUntrustedEnterpriseDeveloperTitle: `I get the message “Untrusted Enterprise Developer”.`,
  FaqUntrustedEnterpriseDeveloperContent: (
    <Paragraph size={Size.Medium}>
      In order for the app to be available for use, you will need to trust the developer's certificate. From your phone’s home screen,
      tap <b>Settings &gt; General &gt; Profiles or Profiles &amp; Device Management</b>. Under the <b>Enterprise App</b> heading, you
      see will see a profile for the developer. Tap the name of the developer profile and then confirm you trust them. You should now
      be able to launch the app.
    </Paragraph>
  ),
  FaqErrorParsingPackageTitle: `While installing the app, I get a "There Was a Problem Parsing the Package" error.`,
  FaqErrorParsingPackageContent: (
    <div>
      <Paragraph size={Size.Medium}>This error could be caused by one of the following reasons:</Paragraph>
      <ul>
        <li>
          <Paragraph size={Size.Medium}>File may be downloaded incompletely</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>Application might be not suitable for your hardware or OS version</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>Security settings</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>Corrupted APK file</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>Name of the package is changed after signing</Paragraph>
        </li>
      </ul>
      <Paragraph size={Size.Medium}>
        To troubleshoot, try reinstalling the app. If that doesn't work, contact the app developer to help resolve the issue.
      </Paragraph>
    </div>
  ),
  FaqAndroidInstallBlockedTitle: `While installing the app on Android, I get a security warning.`,
  FaqAndroidInstallBlockedContent: (
    <div>
      <Paragraph size={Size.Medium}>
        On Android 8 or higher, you get a dialog such as "Chrome, For your security, your phone is not allowed to install unknown apps
        from this source". Click the <b>Settings</b> button of that dialog and toggle <b>Allow from this source</b>. Alternatively you
        can navigate to <b>Phone Settings {">"} Apps & notifications</b>. Find the browser application in the list, tap <b>Advanced</b>{" "}
        then <b>Install unknown apps</b> then toggle <b>Allow from this source</b>.
      </Paragraph>
      <Paragraph size={Size.Medium} spaceAbove={Space.XSmall}>
        On Android 7 or lower, navigate to the device <b>Settings</b> then into the <b>Security</b> category. Under the{" "}
        <b>Device Administration</b> category, toggle <b>Unknown Sources</b>. Then try to install the application again.
      </Paragraph>
      <Paragraph size={Size.Medium} spaceAbove={Space.XSmall}>
        Please note that some device manufacturers might customize the settings UI and thus menu entries can have different names or be
        at a different location.
      </Paragraph>
    </div>
  ),
  FaqWindowsInstallBlockedTitle: `After confirming "Install" I return to the downloads page, and the app is not installed.`,
  FaqWindowsInstallBlockedContent: (
    <Paragraph size={Size.Medium}>
      In order for the app to be available for use, you will need to enable sideloading apps in your settings. From your home screen,
      tap <b>Settings &gt; Update &amp; security &gt; For developers</b>. On the <b>For developers</b> screen, select{" "}
      <b>Sideload apps</b> option. You should now be able to go back to the downloads screen and continue with the installation.
    </Paragraph>
  ),
  FaqUnableToDownloadTitle: `While installing the app, I get an “Unable to Download App” error, and the app is not installed.`,
  FaqUnableToDownloadContent: (
    <div>
      <Paragraph size={Size.Medium}>
        This error could be caused by many reasons, and you should contact the app developer to help resolve the issue.
      </Paragraph>
      <Paragraph size={Size.Medium}>
        If you are the app developer, this error could be caused by one of the following reasons:
      </Paragraph>
      <ul>
        <li>
          <Paragraph size={Size.Medium}>UDID is not included in the provisioning profile</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>The build was incorrectly signed or has broken entitlements</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>Device or iOS version is incompatible with the build</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>Device storage is full</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>Actual download error</Paragraph>
        </li>
        <li>
          <Paragraph size={Size.Medium}>App is over 50 MB and downloaded over a cellular connection</Paragraph>
        </li>
      </ul>
    </div>
  ),
  FaqUnableToOpenTitle: `After installing the app, It appears to download successfully but I am unable to open it.`,
  FaqUnableToOpenContent: (
    <Paragraph size={Size.Medium}>
      If you are a tester, this could be because you haven't registered your device. From{" "}
      <a href="https://install.appcenter.ms" target="_blank">
        the install page
      </a>{" "}
      in safari, tap <b>Profile</b> in the top right, register your device and uninstall the existing version of the app. If you are
      downloading a public release or registering your device did not help with your installation issues, contact your app developer.
    </Paragraph>
  ),
  FaqNoInstallAlertTitle: `When I press the “Install” button, the installation alert never shows up and the app never installs on my device.`,
  FaqNoInstallAlertContent: (
    <Paragraph size={Size.Medium}>
      If you have previously installed the app from the App Store, and it carries the same version of the app that is being installed,
      there will be no alert to confirm the installation. As a workaround, please try uninstalling the existing version of the app, and
      then tap the Install button again.
    </Paragraph>
  ),
};
