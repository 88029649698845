import * as React from "react";
import { Link } from "react-router";
import { Skeletal } from "@root/shared";
import { observer } from "mobx-react";

const styles = require("./list-item-link.scss");

export interface ListItemLinkProps extends Skeletal {
  link?: string;
  divider?: boolean;
  grayOut?: boolean;
}

@observer
export class ListItemLink extends React.Component<ListItemLinkProps, {}> {
  public render() {
    if (this.props.skeleton) {
      return (
        <li>
          <Link to="#" className={styles["list-item-link"]}>
            <div className={styles["list-item"]}>{this.props.children}</div>
          </Link>
        </li>
      );
    }
    return (
      <li data-test-class="app-list-item">
        <Link to={this.props.link!} className={styles["list-item-link"]} role="link" aria-label={this.props["aria-label"]}>
          <div className={this.props.grayOut ? styles["list-item-grayed"] : styles["list-item"]}>{this.props.children}</div>
        </Link>
      </li>
    );
  }
}
