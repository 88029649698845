import * as React from "react";
import * as Strings from "../utils/strings";
import { Faqs } from "../utils/faq-helper";
import { Help, HelpItem } from "./help";
import { OS } from "../models/os";
import { Title, Paragraph, Size, TextColor, PrimaryButton } from "@root/shared";
import { IApp } from "@lib/common-interfaces";
import { appStore } from "@root/stores";
import { getOsFromUserAgent } from "@root/lib/utils/user-agent";
import { Build } from "@root/data/install";
import { CompatibilityStore } from "../stores/compatibility-store";
import { CloseButton } from "../components/close-button/close-button";
import { DeviceArea } from "./device-area";
import { isEmpty } from "lodash";
import * as url from "url";

const styles = require("./install.scss");

export interface InstallProps {
  build: Build;
  onCloseClicked?: () => void;
}

export interface InstallState {
  appOs: string;
  appDisplayName?: string;
  appIconUrl?: string;
}

interface InstallQueryParams {
  display_name?: string;
  icon_url?: string;
  install_url?: string;
}

export class Install extends React.Component<InstallProps, InstallState> {
  private helpItems: HelpItem[];

  constructor(props: any) {
    super(props);

    if (!this.props.build) {
      const userAgentOs = getOsFromUserAgent();

      if (userAgentOs && userAgentOs.name) {
        this.state = {
          appOs: userAgentOs.name,
          appDisplayName: this.queryParams ? this.queryParams.display_name : "",
          appIconUrl: this.queryParams ? this.queryParams.icon_url : "",
        };
      }
    }

    this.helpItems = this.getHelpItems();
  }

  public componentDidMount() {
    window.addEventListener("blur", this.onBlur);
  }

  public render() {
    if (!this.partialApp.display_name) {
      return null;
    }

    const showInstallButton: boolean = OS.isIos(this.getOs()) && !!this.queryParams && !!this.queryParams.install_url;
    const installUrl: string = this.queryParams && this.queryParams.install_url ? this.queryParams.install_url : "";

    return (
      <div className={styles["top-container"]} data-test-id="install-overlay">
        <div className={styles["image-container"]}>
          {this.props.onCloseClicked ? (
            <CloseButton
              className={styles["close-button"]}
              onClick={this.closeButtonClicked}
              label={Strings.Install.Cancel}
              data-test-id="close-button"
            />
          ) : null}
          <DeviceArea app={this.partialApp} os={this.getOs()} />
        </div>
        <div className={styles["status-container"]}>
          {showInstallButton ? (
            <PrimaryButton className={styles["install-button"]} onClick={() => this.download(installUrl)}>
              {Strings.Releases.Install}
            </PrimaryButton>
          ) : (
            <Title className={styles["status-title"]} size={Size.Medium}>
              {Strings.Install.StatusDownloading}
            </Title>
          )}
          <Paragraph size={Size.Medium} color={TextColor.Secondary} className={styles["status-subtitle"]}>
            {this.afterFinishMessage(showInstallButton)}
          </Paragraph>
        </div>
        {!isEmpty(this.helpItems) ? (
          <div>
            <Help title={Strings.InstallHelp.Title} items={this.helpItems} />
          </div>
        ) : null}
      </div>
    );
  }

  private download(installUrl: string): void {
    // Check URL starts with itms-services and is only from HockeyApp or App Center
    const parsedInstallUrl = url.parse(installUrl, true);
    const correctProtocol = parsedInstallUrl.protocol === "itms-services:";
    const correctAction = !!parsedInstallUrl.query && parsedInstallUrl.query.action === "download-manifest";
    let correctUrl = false;
    if (!!parsedInstallUrl.query && parsedInstallUrl.query.url) {
      const manifestUrl = parsedInstallUrl.query.url as string;
      const parsedManifestUrl = url.parse(manifestUrl);
      const parsedManifestUrlHost: string = parsedManifestUrl.host ? parsedManifestUrl.host : "";
      const validManifestUrls: string[] = [
        "training.hockeyapp.net",
        "warmup.hockeyapp.net",
        "rink.hockeyapp.net",
        "portal-server-core-integration.dev.avalanch.es",
        "portal-server-web-int-pme.dev-pme.avalanch.es",
        "appcenter.ms",
      ];
      correctUrl = validManifestUrls.includes(parsedManifestUrlHost);
    }

    if (correctProtocol && correctAction && correctUrl) {
      window.location.href = installUrl;
    }
  }

  private closeButtonClicked = (): void => {
    const { onCloseClicked } = this.props;
    if (onCloseClicked) {
      onCloseClicked();
    }
  };

  private afterFinishMessage = (forTesterApp: boolean): string => {
    const os: string = this.getOs();
    if (OS.isIos(os)) {
      if (forTesterApp) {
        return Strings.Install.AfterTesterAppDownloadInstructionMobilePressHome;
      } else {
        return Strings.Install.AfterDownloadInstructionMobilePressHome;
      }
    } else if (OS.isAndroid(os)) {
      return Strings.Install.AfterDownloadInstructionMobileInstall;
    } else if (OS.isMacOS(os)) {
      return Strings.Install.AfterDownloadInstructionDesktop;
    } else if (OS.isWindows(os)) {
      if (CompatibilityStore.isWindowsPhoneUserAgent()) {
        return Strings.Install.AfterDownloadInstructionMobileInstall;
      }
      return Strings.Install.AfterDownloadInstructionDesktop;
    } else {
      return "";
    }
  };

  private onBlur = (e: FocusEvent) => {
    window.removeEventListener("blur", this.onBlur);
  };

  private getOs = (): string => {
    const app = appStore.app;
    const build = this.props.build;
    if (!app && !build) {
      return this.state.appOs;
    }
    return app?.os || build.appOs;
  };

  private get partialApp(): IApp {
    if (this.props.build) {
      return {
        display_name: this.props.build.appDisplayName,
        os: this.props.build.appOs,
        icon_url: this.props.build.iconLink,
      } as IApp;
    } else if (this.state && this.state.appOs) {
      return {
        display_name: this.state.appDisplayName,
        os: this.state.appOs,
        icon_url: this.state.appIconUrl,
      } as IApp;
    } else {
      return {
        display_name: "",
      } as IApp;
    }
  }

  private getHelpItems = (): HelpItem[] => {
    const os = this.getOs();
    if (OS.isIos(os)) {
      return [
        { title: Faqs.FaqUntrustedEnterpriseDeveloperTitle, content: Faqs.FaqUntrustedEnterpriseDeveloperContent },
        { title: Faqs.FaqUnableToDownloadTitle, content: Faqs.FaqUnableToDownloadContent },
        { title: Faqs.FaqUnableToOpenTitle, content: Faqs.FaqUnableToOpenContent },
        { title: Faqs.FaqNoInstallAlertTitle, content: Faqs.FaqNoInstallAlertContent },
      ];
    } else if (OS.isAndroid(os)) {
      return [
        { title: Faqs.FaqAndroidInstallBlockedTitle, content: Faqs.FaqAndroidInstallBlockedContent },
        { title: Faqs.FaqErrorParsingPackageTitle, content: Faqs.FaqErrorParsingPackageContent },
      ];
    } else if (OS.isWindows(os)) {
      return [{ title: Faqs.FaqWindowsInstallBlockedTitle, content: Faqs.FaqWindowsInstallBlockedContent }];
    } else {
      return [];
    }
  };

  private get queryParams(): InstallQueryParams {
    const props: any = this.props;
    return (props.location && props.location.query) || undefined;
  }
}
