import * as React from "react";
import { observer } from "mobx-react";
import { App } from "@root/data/shell/models";
import { AppIconAnnotated } from "@root/install-beacon/app/app-icon-annotated";
import { ActionText, EmptyState, Paragraph, Size, Title, Space, TitleProps, ParagraphProps, LinkButton } from "@root/shared";
import { OwnerIconName } from "@root/install-beacon/app/owner-icon-name";
const styles = require("./app-no-releases.scss");

export interface AppNoReleasesProps {
  app: App;
  hasReleases: boolean;
  hasMultipleReleases: boolean;
  isPublic?: boolean;
  onSelectPreviousVersions?: any;
  stopTestingOnClick?(): void;
}

@observer
export class AppNoReleases extends React.Component<AppNoReleasesProps, {}> {
  constructor(props) {
    super(props);
  }

  private renderSubtitle = (subtitleProps: ParagraphProps) => {
    const { hasMultipleReleases, hasReleases, onSelectPreviousVersions, app, stopTestingOnClick } = this.props;
    return (
      <div>
        {app.os ? (
          <Paragraph {...subtitleProps} size={Size.Medium} spaceBelow={Space.Medium} data-test-id="app-no-releases-os">
            for {app.os}
          </Paragraph>
        ) : null}
        <AppIconAnnotated app={app} />
        <Title size={Size.Small} data-test-id="app-no-releases-subtitle" spaceAbove={Space.Large} spaceBelow={Space.XSmall}>
          {hasReleases ? (
            <span data-test-id="app-no-releases-no-provisioned-releases">The developers are working on a version for your device</span>
          ) : (
            <span data-test-id="app-no-releases-no-releases">No releases for this app</span>
          )}
        </Title>
        <Paragraph {...subtitleProps}>You’ll receive an email once this app is released.</Paragraph>
        {hasMultipleReleases ? (
          <div className={styles.releasesContainer}>
            <ActionText
              data-test-id="app-no-releases-multiple-releases"
              className={styles.releases}
              onClick={onSelectPreviousVersions}
            >
              Show previous versions
            </ActionText>
          </div>
        ) : null}
        <OwnerIconName app={app} />
        {stopTestingOnClick ? (
          <Paragraph {...subtitleProps} spaceAbove={Space.Medium}>
            <LinkButton onClick={stopTestingOnClick} danger subtle>
              Stop testing app
            </LinkButton>
          </Paragraph>
        ) : null}
      </div>
    );
  };

  public renderTitle = (props: TitleProps) => {
    const {
      app: { display_name: displayName },
    } = this.props;
    // For now, bold={false} won't work since Title doesn't support non-bold styles. Need to have
    // further discussion with the design team on whether we want to support lighter titles.
    const { spaceBelow, ...remaining } = props;
    return (
      <Title {...remaining} bold={false} size={Size.Large}>
        {displayName}
      </Title>
    );
  };

  public render() {
    return (
      <div className={styles.container} data-test-id="app-no-releases">
        <EmptyState title={this.renderTitle} subtitle={this.renderSubtitle} hideButton={true} hideImage={true} />
      </div>
    );
  }
}
