import { testerAppStore } from "@root/data/install";
import { Page } from "@root/install-beacon/components/page";
import { FirstRunSessionStorage } from "@root/install-beacon/utils/first-run-session-storage";
import { Location } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { LayoutWithLeftNav } from "../../shell/layout-with-left-nav/layout-with-left-nav";
import { DeviceRegisteredPage } from "../components/device-registered-page/device-registered-page";
import { Footer } from "../components/footer";
import { IncompatibleSafariBrowserMessage } from "../components/incompatible-safari-browser-message";
import { Overlay } from "../components/overlay/overlay";
import { RegisterDeviceUIStore } from "../stores/register-device-ui-store";
import { AppsContainer } from "./apps-container";

const styles = require("./apps.scss");

export interface LocationWithQuery extends Location {
  query: {
    udid?: string;
    skip_registration?: any;
  } | null;
}

export interface AppsProps extends RouteComponentProps<{}, {}> {
  location: LocationWithQuery;
}

export const Apps = withTranslation(["install"])(
  observer(
    class Apps extends React.Component<AppsProps & WithTranslation, {}> {
      private uiStore!: RegisterDeviceUIStore;

      public UNSAFE_componentWillMount() {
        const {
          location: { query },
        } = this.props;
        const skipRegistration = FirstRunSessionStorage.skippedDeviceRegistration || "skip_registration" in (query || {});
        this.uiStore = new RegisterDeviceUIStore(skipRegistration, (query || {}).udid);
        testerAppStore.fetchCollection();
      }

      public render() {
        const {
          uiStore: { registeredOverlayVisible },
        } = this;
        const { t } = this.props;
        const pageContent: JSX.Element = (
          <div className={styles.apps}>
            <Overlay animated={false} isVisible={registeredOverlayVisible}>
              <DeviceRegisteredPage />
            </Overlay>
            <IncompatibleSafariBrowserMessage />
            <AppsContainer
              title={t("install:app.appsList.myApps")}
              isFetching={testerAppStore.isFetchingCollection}
              appsForCurrentOs={testerAppStore.appsForCurrentOs}
              appsForOtherOs={testerAppStore.appsForOtherOs}
            ></AppsContainer>
            <div className={styles.footerContainer}>
              <Footer isPublic={false} />
            </div>
          </div>
        );
        return (
          <Page data-test-id="apps-page">
            <LayoutWithLeftNav topBarProps={{ showProfileLink: true }}>{pageContent}</LayoutWithLeftNav>
          </Page>
        );
      }
    }
  )
);
