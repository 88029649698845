import * as React from "react";
import { userStore } from "@root/stores";
import { logger } from "@root/lib/telemetry";
import { RouteComponentProps } from "react-router";
import { useUpdateToken } from "../hooks/use-update-token";
import { ProductLogo, PrimaryButton } from "@root/shared";
import { IncompatibleWarning } from "../app/incompatible-warning";

const styles = require("./hockey-app-update-setup.scss");

enum ErrorState {
  UpdateTokenNotFound = "Error: Update Token not found",
  QueryParamsMissing = "Error: Query parameters are missing or incorrect",
  None = "",
}

enum WhatQueryParam {
  Email = "email",
  Device = "udid",
}

interface HockeyAppUpdateSetupProps extends RouteComponentProps<{ secret: string }, any, any> {
  children?: React.ReactNode;
}

// @ts-ignore. [Should fix it in the future] Strict error.
const HockeyAppUpdateSetup: React.FunctionComponent = (props: HockeyAppUpdateSetupProps) => {
  const [error, setError] = React.useState<ErrorState | undefined>(undefined);
  const { updateToken } = useUpdateToken();

  const appSecret: string | undefined = props.params?.secret;
  const whatQueryParam: WhatQueryParam | undefined = props.location?.query?.what;

  const getTelemetryProps = (extraProps?) => {
    const telemetryProps = {
      userId: userStore.currentUser.clientId,
      ...extraProps,
    };
    return telemetryProps;
  };

  // redirect all the way back to the application which the user was sent form
  const redirectToApp = () => {
    const appSecretNoDashes = appSecret!.replace(/-/g, "");
    let redirectUrl = `ha${appSecretNoDashes}://`;
    if (whatQueryParam === WhatQueryParam.Email) {
      redirectUrl = `${redirectUrl}authorize?iuid=${updateToken}&email=${userStore.currentUser.email}`;
    } else {
      redirectUrl = `${redirectUrl}authorize?udid=${updateToken}`;
    }
    logger.info(
      "Hockey App Update Setup: Clicked Return To App Button",
      getTelemetryProps({ redirectUrl, whatQueryParam, appSecretNoDashes })
    );
    window.open(redirectUrl);
  };

  React.useEffect(() => {
    const checkErrors = () => {
      if (updateToken === "") {
        logger.info("Hockey App Update Setup: Update Token Not Found", getTelemetryProps({ updateToken, whatQueryParam }));
        return ErrorState.UpdateTokenNotFound;
      } else if (whatQueryParam !== WhatQueryParam.Email && whatQueryParam !== WhatQueryParam.Device) {
        logger.info("Hockey App Update Setup: Query Parameter Missing", getTelemetryProps({ updateToken, whatQueryParam }));
        return ErrorState.QueryParamsMissing;
      } else {
        return ErrorState.None;
      }
    };

    const redirectToSignIn = () => {
      const originUrl = `install:/${window.location.pathname}${window.location.search}`;
      logger.info("Hockey App Update Setup: redirecting to sign in page", getTelemetryProps({ originUrl }));
      window.location.assign(`${window.location.origin}/sign-in?original_url=${encodeURIComponent(originUrl)}`);
    };

    if (!userStore.userLoggedIn) {
      redirectToSignIn();
    } else {
      setError(checkErrors());
    }
  }, [whatQueryParam, updateToken]);

  if (error === ErrorState.None) {
    return (
      <div className={styles["container"]}>
        <div className={styles["logo-container"]}>
          <ProductLogo className={styles["logo"]} />
        </div>
        <div className={styles["content-container"]}>
          <PrimaryButton onClick={redirectToApp}>Return to application</PrimaryButton>
        </div>
      </div>
    );
  } else if (error === ErrorState.QueryParamsMissing || error === ErrorState.UpdateTokenNotFound) {
    return <IncompatibleWarning compatibility={{ compatible: false, incompatibilityMessage: error }} />;
  } else {
    // this is needed so we don't flash the error page before redirecting to login if user is not logged in
    return null;
  }
};

export { HockeyAppUpdateSetup };
