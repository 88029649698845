import { buildStore, Build, BuildStore } from "@root/data/install";
import { getOsNameFromUserAgent, getOsFromUserAgent } from "@root/lib/utils/user-agent";
import { appStore, locationStore } from "@root/stores";
import { action, computed } from "mobx";
import { safeLocalStorage } from "@root/lib/utils/safe-local-storage";
import * as Strings from "../../utils/strings";
import { LocalStorageKeys } from "@root/install-beacon/utils/constants";
import { OS } from "../../models/os";
import { noop } from "lodash";
import { OS as AppOS } from "@lib/common-interfaces/app";
import { logger } from "@root/lib/telemetry";

export class ReleaseUIStore {
  private buildStore: BuildStore;

  @computed
  public get release(): Build {
    return this.buildStore.get(this.buildStore.compoundKey(this.appIdentifier, this.releaseId))!;
  }

  @computed
  public get installText() {
    const app = appStore.app;
    const os = (app && app.os) || (this.release && this.release.appOs);

    return OS.isIos(os) && OS.isIos(getOsNameFromUserAgent()) ? Strings.Releases.Install : Strings.Releases.Download;
  }

  @computed
  public get disabledInstallButton() {
    const app = appStore.app;
    const userAgentOs = getOsFromUserAgent();

    if (app && app.os && userAgentOs) {
      switch (app.os.toLowerCase()) {
        case AppOS.IOS.toLowerCase():
          return userAgentOs.name !== "iOS";
        case AppOS.MACOS.toLowerCase():
          return !userAgentOs.name!.startsWith("Mac");
        case AppOS.ANDROID.toLowerCase():
          return userAgentOs.name !== "Android";
        case AppOS.WINDOWS.toLowerCase():
          return userAgentOs.name !== "Windows";
        default:
          return false;
      }
    } else {
      return false;
    }
  }

  @computed
  public get isLoadingRelease() {
    const app = appStore.app;
    return !!app ? buildStore.isFetching(buildStore.compoundKey(app.owner.name, app.name, this.releaseId)) : false;
  }

  constructor(
    private releaseId: string,
    private appIdentifier: string,
    private _onInstallClicked: (release: Build) => void = noop,
    private isPublic: boolean,
    private onExpanded: (releaseId: string) => void = noop
  ) {
    this.buildStore = buildStore;
  }

  @action
  public onExpandClicked = () => {
    this.onExpanded(this.releaseId);

    // Get the UDID from local storage
    const registeredUdid = safeLocalStorage.getItem(LocalStorageKeys.Registered);

    const app = appStore.app;
    const ownerName = !this.isPublic ? app.owner.name : locationStore.ownerName;
    const appName = !this.isPublic ? app.name : locationStore.appName;

    const params = {
      ownerName: ownerName,
      appName: appName,
      udid: registeredUdid,
      is_install_page: true,
    };

    if (this.isPublic) {
      // Path: /[orgs|users]/{owner_name}/apps/{app_name}/distribution_groups/{group_name}
      const groupNameFromPath = locationStore.pathname ? locationStore.pathname.split("/").filter((p) => !!p)[5] : undefined;
      params["groupName"] = groupNameFromPath;
    }
    buildStore.fetchOne(buildStore.compoundKey(ownerName, appName, this.releaseId), params as any);
  };

  @action
  public onInstallClicked = () => {
    const release = this.release;
    const app = appStore.app;
    logger.info("Install clicked", {
      microsoft_internal: app ? app.microsoft_internal : false,
    });
    this._onInstallClicked(release);
  };
}
