import { Urls } from "../utils/constants";
import { apiGateway } from "@root/lib/http";
import { noop } from "lodash";

export class InstallAnalyticsStore {
  public sendInstallAnalytics(
    appName: string,
    ownerName: string,
    releaseId: string,
    distributionGroupIds: string[],
    userId: string
  ): Promise<any> {
    const releases: any[] = [];
    distributionGroupIds.forEach((distributionGroupId) => {
      const item = {
        release_id: parseInt(releaseId, 10),
        distribution_group_id: distributionGroupId,
        user_id: userId,
      };
      releases.push(item);
    });

    const options = {
      body: {
        releases: releases,
      },
      params: {
        app_name: appName,
        owner_name: ownerName,
      },
      noBifrostToken: true,
    };

    return apiGateway.post<any>(Urls.PostInstallAnalytics, options).catch(noop);
  }
}

export const installAnalyticsStore = new InstallAnalyticsStore();
