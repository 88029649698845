import { InAppUpdateRedirectUrl, LocalStorageKeys } from "./constants";
import { UpdateSetup } from "./strings";
import StringHelper from "../utils/string-helper";
import { OS } from "../models/os";
import { isEmpty } from "lodash";
import { DeserializedBuild } from "@root/data/install/models/build";
import { safeLocalStorage } from "@root/lib/utils/safe-local-storage";

export interface UpdateQuery {
  /**
   * The signing hash (Android)/Build UUID of the currently installed version
   */
  release_hash: string;
  /**
   * ID (custom URL scheme) for redirecting back to app (for iOS)
   * For Android this value is optional since it's loaded from the backend.
   */
  redirect_id: string;
  /**
   * The request identifier (UUID)
   */
  request_id: string;
  /**
   * iOS or Android (case-sensitive)
   */
  platform: string;
  /**
   * When this flag is present and equal to "true", the update setup page will
   * redirect back to the app with a failure message if in-app update setup fails.
   */
  enable_failure_redirect: string;
  /**
   * Custom scheme that will be used as part of the redirect url (Android only). By default, the scheme is 'mobile.center'.
   */
  redirect_scheme?: string;
}

export class UpdateSetupHelper {
  public static isMissingRequiredQueryParam(query: UpdateQuery): boolean {
    const isMissingParam: boolean =
      !query || !query.release_hash || !query.request_id || !query.platform || (!OS.isAndroid(query.platform) && !query.redirect_id);
    return isMissingParam;
  }

  public static obfuscatedQueryParams(query: UpdateQuery): any {
    if (!query) {
      return {};
    }

    return {
      release_hash: StringHelper.obfuscate(query.release_hash),
      request_id: query.request_id,
      platform: query.platform,
      redirect_id: StringHelper.obfuscate(query.redirect_id),
    };
  }

  public static createPrivateRedirectUrl(
    platform: string,
    redirectId: string | undefined,
    updateToken: string,
    distributionGroupId: string,
    requestId: string,
    udid: string | null,
    redirectScheme?: string
  ): string {
    let url: string | undefined;
    if (platform && redirectId && updateToken && distributionGroupId && requestId) {
      if (OS.isIos(platform) && this.isValidUrlScheme(redirectId)) {
        const urlFormat = InAppUpdateRedirectUrl.PrivateUrlFormatIos;
        url = StringHelper.format(urlFormat, redirectId, updateToken, distributionGroupId, requestId, udid);
      } else if (OS.isAndroid(platform)) {
        const scheme = this.getRedirectScheme(redirectScheme);
        const urlFormat = InAppUpdateRedirectUrl.PrivateUrlFormatAndroid;
        url = StringHelper.format(urlFormat, scheme, redirectId, updateToken, distributionGroupId, requestId, udid);
      }
    }

    return url!;
  }

  public static createPublicRedirectUrl(
    platform: string,
    redirectId: string | undefined,
    distributionGroupId: string,
    requestId: string,
    redirectScheme?: string
  ): string {
    let url: string | undefined;
    if (platform && redirectId && distributionGroupId && requestId) {
      if (OS.isIos(platform) && this.isValidUrlScheme(redirectId)) {
        const urlFormat = InAppUpdateRedirectUrl.PublicUrlFormatIos;
        url = StringHelper.format(urlFormat, redirectId, distributionGroupId, requestId);
      } else if (OS.isAndroid(platform)) {
        const scheme = this.getRedirectScheme(redirectScheme);
        const urlFormat = InAppUpdateRedirectUrl.PublicUrlFormatAndroid;
        url = StringHelper.format(urlFormat, scheme, redirectId, distributionGroupId, requestId);
      }
    }

    return url!;
  }

  public static createFailureRedirectUrl(platform: string, redirectId: string, requestId: string, redirectScheme?: string): string {
    let url: string | undefined;
    if (platform && redirectId && requestId) {
      if (OS.isIos(platform) && this.isValidUrlScheme(redirectId)) {
        const urlFormat = InAppUpdateRedirectUrl.FailedUrlFormatIos;
        url = StringHelper.format(urlFormat, redirectId, UpdateSetup.UpdateSetupFailed, requestId);
      } else if (OS.isAndroid(platform)) {
        const scheme = this.getRedirectScheme(redirectScheme);
        const urlFormat = InAppUpdateRedirectUrl.FailedUrlFormatAndroid;
        url = StringHelper.format(urlFormat, scheme, redirectId, UpdateSetup.UpdateSetupFailed, requestId);
      }
    }

    return url!;
  }

  public static isValidUrlScheme(scheme: string): boolean {
    // http://stackoverflow.com/questions/3641722/valid-characters-for-uri-schemes
    return /^[a-zA-Z][a-zA-Z0-9.+-]*$/.test(scheme);
  }

  public static storeCookies(
    build: DeserializedBuild,
    appDisplayName: string,
    appIconUrl: string,
    isPublic: boolean,
    updateToken: string
  ) {
    const packageHashes = build.packageHashes;
    if (packageHashes) {
      // Set expiration of cookies for a year from now.
      // Safari requires a cookie expiration to create a persistent cookie across the multiple instances of the app.
      // Android has one package_hash, iOS has one for each architecture.
      // We have to store all values for each hash, since we do not know which hash the user is going to provide on the update setup page.
      packageHashes.map((hash) => {
        // When enabling in-app updates we are going to check if the update token is set (private group) or not (public group).
        if (isPublic) {
          safeLocalStorage.removeItem(StringHelper.format(LocalStorageKeys.Format, hash, LocalStorageKeys.UpdateToken));
        } else {
          safeLocalStorage.setItem(StringHelper.format(LocalStorageKeys.Format, hash, LocalStorageKeys.UpdateToken), updateToken);
        }
        // The distribution group id
        // (Jess) TODO: After re-release feature is enabled, this might no longer be able to just use the first
        // distribution group ID from the data. Also, the DG ID is only needed by the SDK for the public group API
        if (build.distributionGroups && build.distributionGroups.length > 0) {
          safeLocalStorage.setItem(
            StringHelper.format(LocalStorageKeys.Format, hash, LocalStorageKeys.DistributionGroupId),
            build.distributionGroups[0].id
          );
        }
        // The app display name which is immediately shown when the user is directed to the update setup page
        safeLocalStorage.setItem(StringHelper.format(LocalStorageKeys.Format, hash, LocalStorageKeys.DisplayName), appDisplayName);
        // The app icon which is immediately shown when the user is directed to the update setup page
        safeLocalStorage.setItem(StringHelper.format(LocalStorageKeys.Format, hash, LocalStorageKeys.IconUrl), appIconUrl);
      });
    }
  }

  private static getRedirectScheme(redirectScheme?: string): string {
    if (isEmpty(redirectScheme)) {
      return InAppUpdateRedirectUrl.DefaultScheme;
    }
    return redirectScheme!;
  }
}
