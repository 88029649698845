import { DevicesList } from "@root/install-beacon/components/devices-list";
import { Page } from "@root/install-beacon/components/page";
import { Card, Color, Gravatar, Icon, IconName, IconSize, Size, Space, Text, TextColor, Title } from "@root/shared";
import { userStore } from "@root/stores";
import { Location } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import { LayoutWithLeftNav } from "../../shell/layout-with-left-nav/layout-with-left-nav";
import { IncompatibleSafariBrowserMessage } from "../components/incompatible-safari-browser-message";
import { InstallDialog } from "../components/install-dialog";
import { InstallToast } from "../components/install-toast";
import { ProfileUIStore } from "./profile-ui-store";

const styles = require("./profile.scss");

export interface LocationWithQuery extends Location {
  query: {
    udid?: string;
  } | null;
}

export interface ProfileProps extends RouteComponentProps<{}, {}> {
  location: LocationWithQuery;
}

@observer
export class Profile extends React.Component<ProfileProps, {}> {
  private profileUIStore: ProfileUIStore;

  constructor(props: ProfileProps) {
    super(props);

    super(props);

    const {
      location: { query },
    } = this.props;

    this.profileUIStore = new ProfileUIStore((query || {}).udid);
    this.profileUIStore.fetchDevices();
  }

  public render() {
    const user = userStore.currentUser;
    const {
      devices,
      isLoading,
      onRemoveDevice,
      deviceToDelete,
      showDeleteConfirmDialog,
      onRemoveDeviceCancelled,
      onRemoveDeviceConfirmed,
      showDeleteFailedToast,
      onDismissDeleteFailedToast,
      onAddNewDevice,
    } = this.profileUIStore;
    const pageContent: JSX.Element = (
      <div className={styles.profileContainer}>
        <IncompatibleSafariBrowserMessage />
        <div className={styles.userBadge}>
          <Gravatar email={user.email} size={80} />
          <Title data-test-id="avatar-info-display-name" ellipsize size={Size.Medium} bold>
            {user.display_name}
          </Title>
          <Text data-test-id="avatar-info-email" ellipsize size={Size.Medium} color={TextColor.Secondary}>
            {user.email}
          </Text>
          <a data-test-id="user-badge-logout" href="/logout">
            <Text size={Size.Medium} spaceAbove={Space.XSmall} uppercase color={TextColor.Link}>
              Log Out
            </Text>
          </a>
        </div>

        <Card title="My Devices" data-test-id="profile-device-list">
          <Text className={styles.myDevicesTitle} bold size={Size.Medium} spaceBelow={Space.XSmall}>
            My devices
          </Text>
          {devices.length === 0 && !isLoading ? (
            <Text size={Size.Medium} color={TextColor.Secondary} className={styles.deviceText}>
              No devices have been added to your account yet.
            </Text>
          ) : null}
          {devices.length > 0 && !isLoading ? (
            <Text size={Size.Medium} color={TextColor.Secondary}>
              These are the iOS devices you've added for testing.
            </Text>
          ) : null}
          <DevicesList
            devices={devices}
            loading={isLoading}
            onRemoveDevice={onRemoveDevice}
            onAddNewDevice={onAddNewDevice}
            data-test-id="device-list"
          />
        </Card>

        <InstallDialog
          visible={showDeleteConfirmDialog}
          description={
            deviceToDelete ? `Are you sure you want to remove the device '${deviceToDelete.displayName}' from your account?` : ""
          }
          confirmButton="Remove device"
          onConfirm={onRemoveDeviceConfirmed}
          cancelButton="Cancel"
          onCancel={onRemoveDeviceCancelled}
          data-test-id="device-delete-confirm-dialog"
        />

        <InstallToast
          visible={showDeleteFailedToast}
          title={deviceToDelete ? `Something went wrong while trying to delete device '${deviceToDelete.displayName}'.` : ""}
          icon={<Icon icon={IconName.StatusFailed} size={IconSize.Medium} color={Color.Red} />}
          action={onDismissDeleteFailedToast}
          data-test-id="delete-failed-toast"
        />
      </div>
    );

    return (
      <Page data-test-id="profile-page">
        <LayoutWithLeftNav>{pageContent}</LayoutWithLeftNav>
      </Page>
    );
  }
}
