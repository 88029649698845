import { Urls } from "../utils/constants";
import { IHttpOptions } from "@lib/common-interfaces";
import { apiGateway } from "@root/lib/http";
import { compatibilityStore, Compatibility } from "../stores/compatibility-store";
import { OS as AppOS } from "@lib/common-interfaces/app";
import { logger } from "@root/lib/telemetry";

interface ProfileResponse {
  data_url: string;
}

export class RegisterDeviceStore {
  /**
   * Fetch the profile and trigger the install.
   */
  public fetchProfile(orginalUrl: string, registrationId?: string): Promise<string | void> {
    const params: any = {};
    const options: IHttpOptions = {
      params: params,
    };

    // Generate the query string params based on the inputs provided.
    if (orginalUrl) {
      params.original_url = encodeURI(orginalUrl);
    }

    if (registrationId) {
      params.registration_id = registrationId;

      // No authenticaiton is needed when dioing registration with a registration ID, authentication was done previously to get the registration ID.
      options.noBifrostToken = true;
    }

    // Get the profile.
    return apiGateway
      .get<ProfileResponse>(Urls.RegisterDevice, options)
      .then((result) => {
        // Install the profile.
        this.navigate(result.data_url);

        return result.data_url;
      })
      .catch((error) => {
        console.error(error);
        return;
      });
  }

  /**
   * Triggers install for the profile associated with the registration id.
   */
  public installProfileForRegistrationId(registrationId) {
    compatibilityStore
      .checkCompatibilityAndTrack("InstallProfile: Checked device compatibility", undefined as any, AppOS.IOS)
      .then((compatability: Compatibility) => {
        // If this is a compatible browser, load up the profile info and trigger the install.
        if (compatability.compatible) {
          this.fetchProfile(undefined as any, registrationId).then((data_url: string | any) => {
            if (!data_url) {
              logger.info(`InstallProfile: Could not load data url for registration_id: ${registrationId}`);

              // Data URL could not be retrieved, likely the registration ID is not valid.
              // Nothing we can do so navigate to the main page.
              this.navigate(window.location.origin);
            }
          });
        }
      });
  }

  /**
   * Navigates to the provided url.
   *
   * NOTE: This is only here for testing purposes so we can make sure that we are navigating to the right place.
   */
  private navigate(url: string) {
    window.location.href = url;
  }
}

export const registerDeviceStore = new RegisterDeviceStore();
