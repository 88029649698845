import { computed } from "mobx";
import { RegistrationUtils } from "@root/install-beacon/utils/registration-utils";
import { locationStore } from "@root/stores";
import { logger } from "@root/lib/telemetry";

export class CompleteRegistrationUIStore {
  @computed get showRegisteredOverlay() {
    return !!this.udid;
  }
  constructor(private udid: string) {
    if (!udid) {
      // This is kind of an error condition. Track some telemetry to know when this happens.
      logger.info("UDID not returned from management profile");
      locationStore.pushAppList();
    } else {
      RegistrationUtils.finishDeviceRegistration(udid, null as any, null as any);
      setTimeout(() => {
        locationStore.router.push("/first-run/finished");
      }, 2000);
    }
  }
}
