import { observable, computed, action, reaction, IReactionDisposer } from "mobx";
import {
  buildStore,
  LATEST,
  BuildStore,
  Build,
  ProfileType,
  TesterAppQueryOrOptions,
  testerAppStore,
  BuildQueryOrOptions,
} from "@root/data/install";
import { compatibilityStore } from "../../stores/compatibility-store";
import { distributionStores } from "../../../distribute/stores/distribution-stores";
import { DistributionGroupListStore } from "../../../distribute/stores/distribution-group-list-store";
import { LocalStorageKeys } from "@root/install-beacon/utils/constants";
import { safeLocalStorage } from "@root/lib/utils/safe-local-storage";
import { OS } from "../../models/os";
import * as Strings from "../../utils/strings";
import { RegisterDeviceUIStore } from "@root/install-beacon/stores/register-device-ui-store";
import { FetchMode } from "@root/data/lib";
import { InstallUIStore, DeserializedResignStatusResponse } from "@root/install-beacon/app/stores/install-ui-store";
import { updateTokenStore } from "@root/install-beacon/stores/update-token-store";
import { appStore, userStore, locationStore } from "@root/stores";
import { App } from "@root/data/shell/models/app";
import { logger } from "@root/lib/telemetry";
interface FilterCriteria {
  ownerName: string;
  appName: string;
  groupName?: string;
}

export enum ReleaseAvailableStatus {
  CanGet,
  IsPreparing,
  IsDownloadable,
  NotAvailable,
}

export enum SortReleasesBy {
  DateAscending,
  DateDecending,
  VersionNumberAscending,
  VersionNumberDescending,
}

export class AppUIStore {
  private cannotGetOrInstallReactionDisposer: IReactionDisposer;
  private registerDeviceUIStore: RegisterDeviceUIStore;
  private buildStore: BuildStore;
  private distributionGroupListStore!: DistributionGroupListStore;
  public identifierPrefix: string;
  @observable public skipEmptyState: boolean = false;
  @observable public isDownloadOverlayVisible: boolean = false;
  @observable private showTesterDialogBox: boolean = false;
  @observable private selectedReleaseId?: string;
  @observable public sortBy = SortReleasesBy.VersionNumberDescending;

  @computed get currentApp(): App {
    if (appStore.app) {
      return appStore.app;
    } else if (this.topRelease) {
      return new App({
        display_name: this.topRelease.appDisplayName,
        os: this.topRelease.appOs,
        icon_url: this.topRelease.iconLink,
        owner: this.topRelease.owner || ({ name: locationStore.ownerName } as any),
      });
    } else {
      return new App({
        display_name: locationStore.appName,
        owner: { name: locationStore.ownerName } as any,
      });
    }
  }

  @action
  public onSelectPreviousVersions = () => {
    this.setSkipEmptyState(true);
  };

  @computed
  public get isTesterDialogVisible(): boolean {
    return this.showTesterDialogBox;
  }

  @action
  public setTesterDialogVisible(visible: boolean) {
    this.showTesterDialogBox = visible;
  }

  @computed
  public get hasMultipleReleases() {
    return this.releases.length > 1;
  }

  @computed
  public get hasReleases() {
    return this.releases.length > 0;
  }

  @computed
  public get selectedRelease() {
    return this.buildStore.get(this.buildStore.compoundKey(this.identifierPrefix, this.selectedReleaseId));
  }

  @computed
  public get releases() {
    const filterCriteria = this.filterCriteria;
    if (!filterCriteria) {
      return [];
    }
    return this.buildStore.resources.filter((release) => {
      return release.appIdentifier === `${filterCriteria.ownerName}/${filterCriteria.appName}`;
    });
  }

  @computed
  public get sortedReleases() {
    if (!this.providedReleaseId) {
      return this.releases;
    }

    const releases = this.releases.filter((release) => {
      return release.id !== this.providedReleaseId;
    });
    const providedRelease = this.buildStore.get(this.buildStore.compoundKey(this.identifierPrefix, this.providedReleaseId));
    if (providedRelease) {
      releases.unshift(providedRelease);
    }

    return releases;
  }

  @computed
  public get sortedReleasesAscending() {
    const reverseSortedReleases = ([] as any[]).concat(this.sortedReleases).reverse();

    return reverseSortedReleases;
  }

  @computed
  public get sortedReleasesByDateAscending() {
    const sortedByDateReleases = ([] as any[]).concat(this.sortedReleases).sort((a, b) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });

    return sortedByDateReleases;
  }

  @computed
  public get sortedReleasesByDateDescending() {
    const reverseSortedByDate = ([] as any[]).concat(this.sortedReleasesByDateAscending).reverse();

    return reverseSortedByDate;
  }

  public setSort = action((order: SortReleasesBy) => {
    /**
     * If the sort is being set to DateDecending (Most Recent), we need to make sure we
     * have fetched the full details of the most recent release so it can be expanded by default
     * as the Latest Release.
     * This is necessary because the initial fetch collection does not return the full details, including
     * properties such as release notes.
     */
    if (order === SortReleasesBy.DateDecending && this.mostRecentRelease && !this.providedReleaseId) {
      const app = appStore.app;
      const groupName = this.groupName || this.groupNameFromPath;
      this.buildStore.fetchOne(buildStore.compoundKey(this.ownerName, this.appName, this.mostRecentRelease.id), {
        ownerName: !this.isPublic && app ? app.owner.name : locationStore.ownerName,
        appName: !this.isPublic && app ? app.name : locationStore.appName,
        udid: this.udid,
        checkLatest: this.releaseId !== LATEST,
        groupName: !this.isPublic ? (null as any) : groupName,
        is_install_page: true,
      });
    }
    this.sortBy = order;
  });

  @computed
  public get releaseAvailable(): ReleaseAvailableStatus {
    return this.getReleaseAvailability(this.selectedRelease);
  }

  @computed
  public get topReleaseAvailable(): ReleaseAvailableStatus {
    return this.getReleaseAvailability(this.topRelease);
  }

  public getReleaseAvailability(release): ReleaseAvailableStatus {
    if (!release) {
      return ReleaseAvailableStatus.NotAvailable;
    }
    if (
      this.isPublic ||
      !OS.isIos(release.appOs) ||
      !this.isProvisioningCheckNeeded(release) ||
      release.isUdidProvisioned ||
      !this.udid
    ) {
      return ReleaseAvailableStatus.IsDownloadable;
    } else if (
      this.installUIStore &&
      this.selectedReleaseId === this.installUIStore.currentReleaseId &&
      this.installUIStore.resignStatus === "inProgress"
    ) {
      return ReleaseAvailableStatus.IsPreparing;
    } else if (!release.isUdidProvisioned && release.canResign) {
      return ReleaseAvailableStatus.CanGet;
    } else {
      return ReleaseAvailableStatus.NotAvailable;
    }
  }

  @computed
  public get noticeMessage() {
    if (this.currentApp.isTvOSApp) {
      return Strings.App.TvOsNoticeMessage;
    }
    if (this.hasReleases && !this.noCheck) {
      return this.compatibilityCheckMessage || this.buildProvisionCheckMessage;
    }
  }

  @computed
  private get buildProvisionCheckMessage() {
    const build = this.selectedRelease || this.topRelease;
    if (build.provisioningProfileType === ProfileType.Other) {
      return Strings.App.ErrorProfileType;
    }
  }

  @computed
  private get compatibilityCheckMessage() {
    if (compatibilityStore.isLoaded && !compatibilityStore.data!.compatible) {
      return compatibilityStore.data!.incompatibilityMessage;
    }
  }

  constructor(
    public isPublic: boolean,
    private providedReleaseId: string,
    public udid: string,
    public noCheck: boolean,
    public source: "email" | string,
    skipRegistration: boolean,
    private ownerName?: string,
    private appName?: string,
    private groupName?: string
  ) {
    this.registerDeviceUIStore = new RegisterDeviceUIStore(skipRegistration, udid, isPublic);
    this.buildStore = buildStore;
    this.fetchDistributionGroups();
    this.fetchBuilds();
    if (!this.isPublic) {
      this.fetchTesterAppPermissions();
      updateTokenStore.loadUpdateToken();
    }

    this.identifierPrefix = this.buildStore.compoundKey(this.ownerName, this.appName);

    // We want to track whenever the user has no option to resign or install an app release
    this.cannotGetOrInstallReactionDisposer = reaction(
      () => this.releaseAvailable,
      (available) => {
        if (this.selectedRelease && available === ReleaseAvailableStatus.NotAvailable) {
          logger.info("Provisioning: No get or install option available", this.telemetryProperties as any);
        }
      }
    );
  }

  @computed private get telemetryProperties() {
    const app = appStore.findApp(this.ownerName!, this.appName!);
    return {
      appId: app ? app.id : null,
      releaseId: this.selectedRelease ? this.selectedRelease.id : null,
    };
  }

  public get releaseId(): string {
    return this.providedReleaseId || LATEST;
  }

  private get filterCriteria(): FilterCriteria {
    const app = appStore.app;
    if (!app && !this.isPublic) {
      return undefined as any;
    }
    return {
      ownerName: !this.isPublic ? app.owner.name : locationStore.ownerName!,
      appName: !this.isPublic ? app.name! : locationStore.appName!,
    };
  }

  @computed get testerHasRemovePermissions(): boolean {
    const testerAppPermissions = this.testerAppPermissions();
    return userStore.userLoggedIn && testerAppPermissions && testerAppPermissions.includes("can_remove_from_app");
  }

  private testerAppPermissions(): string[] {
    if (testerAppStore.isEmpty) {
      return [];
    }

    const testerApp = testerAppStore.get(this.currentApp.id);
    return testerApp ? testerApp.permissions! : [];
  }

  @computed get isCurrentUserFromSharedGroup(): boolean {
    if (!this.distributionGroupListStore) {
      return false;
    }

    return (
      !!this.distributionGroupListStore.data &&
      this.distributionGroupListStore.data.some(
        (distributionGroup) =>
          distributionGroup.is_shared &&
          userStore.userLoggedIn &&
          distributionGroup.users.some((user) => user.id === userStore.currentUser.id)
      )
    );
  }

  @computed get isMicrosoftDogfooding(): boolean {
    const app = appStore.app;
    if (!app) {
      return false;
    }

    return app.isMicrosoftInternal && userStore.userLoggedIn && !!userStore.currentUser.is_microsoft_internal;
  }

  @computed get topRelease(): Build {
    if (this.buildStore.isEmpty) {
      return undefined as any;
    }
    return this.buildStore.get(this.buildStore.compoundKey(this.identifierPrefix, this.releaseId)) || this.sortedReleases.length > 0
      ? this.sortedReleases[0]
      : (null as any);
  }

  @computed get mostRecentRelease(): Build {
    if (this.buildStore.isEmpty || this.sortedReleasesByDateDescending.length === 0) {
      return undefined as any;
    }
    return this.sortedReleasesByDateDescending[0];
  }

  @computed get isLoading(): boolean {
    return this.buildStore.isFetchingCollection || this.buildStore.isFetching(this.releaseId);
  }

  @observable private isFetchingInitial = true;
  @computed get fetchingInitial() {
    // If a fetch error occurred, that means that the "isFetchingInitial" observable
    // probably didn't get unset. So let the presence of a fetch error override the observable.
    return (
      this.isFetchingInitial &&
      !this.buildStore.collectionFetchError &&
      (this.topRelease ? !this.buildStore.fetchError(this.identifierPrefix, this.topRelease.id!) : true)
    );
  }

  @computed get registrationRequired(): boolean {
    return this.registerDeviceUIStore.registeredOverlayVisible;
  }

  public onExpandReleaseClicked = action((releaseId: string) => {
    this.selectedReleaseId = releaseId;
    this.initialStateOver();
  });

  public onCloseDownloadOverlay = action(() => {
    this.isDownloadOverlayVisible = false;
  });

  private fetchTesterAppPermissions() {
    const app = appStore.app;
    if (!app) {
      // This means the user is trying to load releases for an app they do not have access to. Just
      // do nothing here and let the render handle this case.
      return;
    }

    const query: TesterAppQueryOrOptions = { ownerName: this.ownerName, appName: this.appName };
    testerAppStore.fetchOne(testerAppStore.compoundKey(this.ownerName, this.appName), query);
  }

  private fetchDistributionGroups = () => {
    const app = appStore.app;
    if (!app) {
      return;
    }

    this.distributionGroupListStore = distributionStores.getDistributionGroupListStore(app);
    this.distributionGroupListStore.fetchDistributionGroupsList();
  };

  private fetchBuilds = () => {
    this.fetchAllBuilds();
  };

  private fetchAllBuilds() {
    // Need to not show the UI until the initial fetch is completed, and I can't come up with a better
    // way to differentiate between the first fetch and any other that is the full collection || the top
    // release (don't want the UI to disappear if the top item is later expanded again). So we have to use
    // an observable to track the specific two initial loads that happen upon load.
    this.setFetchingInitial(true);
    const app = appStore.app;
    if (!app && !this.isPublic) {
      // This means the user is trying to load releases for an app they do not have access to. Just
      // do nothing here and let the render handle this case.
      return;
    }

    if (!!this.udid) {
      safeLocalStorage.setItem(LocalStorageKeys.Registered, this.udid);
    } else {
      this.udid = safeLocalStorage.getItem(LocalStorageKeys.Registered)!;
    }
    const params: BuildQueryOrOptions = {
      ownerName: !this.isPublic ? app.owner.name : locationStore.ownerName,
      appName: !this.isPublic ? app.name : locationStore.appName,
      top: 10000,
    };
    if (this.isPublic) {
      params["groupName"] = this.groupName || this.groupNameFromPath;
      params["public"] = true;
    }
    const fetchCollection = this.buildStore.fetchCollection(params, { fetchMode: FetchMode.PreserveAppend });

    /**
     * Note: This request chaining is not ideal (they could be run in parallel),
     * but it's the easiest solution to the timing issue where the partial release
     * info was overwriting the full release info for the main release being loaded.
     * In the future when the releases for tester API is updated to return
     * full release info for each release, running these requests in parallel won't
     * be a problem anymore.
     */
    fetchCollection.onSuccess(() => {
      const topRelease = this.topRelease;
      const groupName = this.groupName || this.groupNameFromPath;
      if (topRelease) {
        this.buildStore
          .fetchOne(buildStore.compoundKey(this.ownerName, this.appName, this.topRelease.id), {
            ownerName: !this.isPublic ? app.owner.name : locationStore.ownerName,
            appName: !this.isPublic ? app.name : locationStore.appName,
            udid: this.udid,
            checkLatest: this.releaseId !== LATEST,
            groupName: !this.isPublic ? (null as any) : groupName,
            is_install_page: true,
          })
          .onSuccess((fetchedDetails) => {
            if (fetchedDetails) {
              this.checkCompatibilityForRelease(fetchedDetails);
              // By default, pre-select the top release
              this.selectedReleaseId = fetchedDetails.id;
            }
            this.setFetchingInitial(false);
          })
          .onFailure(() => {
            this.setFetchingInitial(false);
          });
      } else {
        this.setFetchingInitial(false);
      }
    });
  }

  private isProvisioningCheckNeeded(release: Build): boolean {
    if (!release || this.isPublic) {
      return false;
    }

    const os = release.appOs!;
    const udid: string = safeLocalStorage.getItem(LocalStorageKeys.Registered)!;
    return OS.isIos(os) && release.provisioningProfileType === ProfileType.AdHoc && !release.isProvisioningProfileSyncing && !!udid;
  }

  @action
  private setFetchingInitial(fetching: boolean) {
    this.isFetchingInitial = fetching;
  }

  @action
  private setSkipEmptyState(skipEmptyState: boolean): void {
    this.skipEmptyState = skipEmptyState;
  }

  private checkCompatibilityForRelease = action((release: Build) => {
    // Do compatibility check and send telemetry
    const telemetryProps = { os: release.appOs, minOsVersion: release.minOsVersion };
    compatibilityStore.checkCompatibilityAndTrack(
      "Checked device compatibility",
      telemetryProps,
      release.appOs,
      release.minOsVersion,
      release.appDisplayName
    );

    // Track whether the device is provisioned
    if (release.provisioningProfileType === ProfileType.Other) {
      logger.info("Install: Viewing build provisioned with App Store Distribution Profile");
    } else if (this.isProvisioningCheckNeeded(release) && !release.isUdidProvisioned) {
      logger.info("UDID not found in provisioning profile");
    }
  });

  @observable private installUIStore?: InstallUIStore;
  public onInstallClicked = action((release: Build) => {
    this.selectedReleaseId = release.id;
    if (!this.installUIStore) {
      this.installUIStore = new InstallUIStore({
        isPublic: this.isPublic,
        token: updateTokenStore.data!,
        onInstallLinkAvailableFromProps: this.onInstallLinkAvailable,
        ownerName: this.ownerName!,
        appName: this.appName!,
        releaseId: this.selectedRelease!.id!,
        udid: this.udid,
      });
    } else if (this.installUIStore && this.installUIStore.currentReleaseId !== this.selectedReleaseId) {
      // Only replace the install UI store if the user taps Install on a release that is different
      // from the one the existing install UI store was set up for.
      if (this.installUIStore) {
        this.installUIStore.cancel();
      }
      this.installUIStore = new InstallUIStore({
        isPublic: this.isPublic,
        token: updateTokenStore.data!,
        onInstallLinkAvailableFromProps: this.onInstallLinkAvailable,
        ownerName: this.ownerName!,
        appName: this.appName!,
        releaseId: this.selectedRelease!.id!,
        udid: this.udid,
      });
    }

    this.installUIStore.onInstallClicked();
  });
  public onRetryClicked = () => {
    if (this.installUIStore) {
      this.installUIStore.onRetryClicked();
    }
  };
  private onInstallLinkAvailable = () => {
    this.isDownloadOverlayVisible = true;
  };

  public dispose(): void {
    if (this.cannotGetOrInstallReactionDisposer) {
      this.cannotGetOrInstallReactionDisposer();
    }
  }

  @computed get canRetry() {
    return this.installUIStore && this.installUIStore.canRetry;
  }

  @computed get installToastTitle(): string | undefined {
    if (!this.installUIStore) {
      return;
    }
    return this.installUIStore.installToastTitle;
  }
  @computed get resignStatus(): DeserializedResignStatusResponse["status"] | undefined {
    if (!this.installUIStore) {
      return;
    }
    return this.installUIStore.resignStatus;
  }
  @computed get installToastVisible(): boolean {
    return !!this.installUIStore && this.installUIStore.installToastVisible;
  }
  @computed get isReleaseNotFound(): boolean {
    return this.topRelease && this.releaseId !== LATEST && this.topRelease.id !== this.releaseId;
  }
  @observable initialState: boolean = true;
  private initialStateOver(): void {
    this.initialState = false;
  }

  private get groupNameFromPath() {
    // Path: /[orgs|users]/{owner_name}/apps/{app_name}/distribution_groups/{group_name}
    return locationStore.pathname ? locationStore.pathname.split("/").filter((p) => !!p)[5] : undefined;
  }
}
