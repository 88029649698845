import * as React from "react";
import { OrganizationIcon, MediaObject, Space, Text, Size } from "@root/shared";
import { Organization } from "@root/data/shell/models";

export interface OrgTitleProps {
  organization: Organization;
}

export class OrgTitle extends React.Component<OrgTitleProps, {}> {
  public render() {
    return (
      <MediaObject hSpace={Space.XSmall}>
        <OrganizationIcon organization={this.props.organization} size={20}></OrganizationIcon>
        <Text size={Size.Small}>{this.props.organization.display_name}</Text>
      </MediaObject>
    );
  }
}
