// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6u3usr06x{width:16px;height:16px}._50PyLmFCx{margin:5px 0}._5zFXVByUx{margin-left:16px;padding-left:5px}._3FpIu6JIx{margin:16px 0}", "",{"version":3,"sources":["webpack://client/install-beacon/app/build-info.scss"],"names":[],"mappings":"AAEA,YACE,UAAA,CACA,WAAA,CAGF,YACE,YAAA,CAGF,YAEE,gBAAA,CACA,gBAAA,CAGF,YACE,aAAA","sourcesContent":["@import \"~@css/vars.scss\";\n\n.icon {\n  width: 16px;\n  height: 16px;\n}\n\n.row {\n  margin: 5px 0;\n}\n\n.message {\n  composes: row;\n  margin-left: 16px;\n  padding-left: 5px;\n}\n\n.container {\n  margin: 16px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"icon": "_6u3usr06x",
	"row": "_50PyLmFCx",
	"message": "_5zFXVByUx _50PyLmFCx",
	"container": "_3FpIu6JIx"
};
export default ___CSS_LOADER_EXPORT___;
