import * as React from "react";
import { observer } from "mobx-react";
import * as Strings from "../utils/strings";
import { LocalStorageKeys } from "../utils/constants";
import { UpdateQuery, UpdateSetupHelper } from "../utils/update-setup-helper";
import StringHelper from "../utils/string-helper";
import { Compatibility } from "../stores/compatibility-store";
import { IncompatibleWarning } from "../app/incompatible-warning";
import { ProductLogo, AppIcon, PrimaryButton } from "@root/shared";
import { updateBuildStore } from "../stores/update-build-store";
import { OS } from "../models/os";
import { DeserializedBuild as Build } from "@root/data/install";
import { safeLocalStorage } from "../../lib/utils/safe-local-storage";
import { userStore } from "@root/stores";
// For whatever reason, there were two different telemetry libs in use in here
import { logger } from "@root/lib/telemetry";
import { distributionGroupReleaseHashStore, DistributionGroup } from "@root/data/distribute";

const styles = require("./update-setup.scss");

enum ErrorState {
  None,
  CookiesNotFound,
  QueryParamsMissing,
}

interface UpdateSetupState {
  secondsUntilRedirect: number;
}

@observer
export class UpdateSetup extends React.Component<any, UpdateSetupState> {
  private error: ErrorState = ErrorState.None;
  private query: UpdateQuery;
  private updateToken: string | null = null;
  private distributionGroupId: string | null = null;
  private udid: string | null = null;
  private appSecret: string;
  private countdownInterval: NodeJS.Timer | null = null;
  private countdownTime: number = 3;

  constructor(props) {
    super(props);
    this.appSecret = this.getAppSecretFromPath();
    this.state = {
      secondsUntilRedirect: this.countdownTime,
    };
    this.query = this.getQueryParams();
    this.error = this.readCookieValues();
    if (this.error === ErrorState.CookiesNotFound) {
      if (userStore.userLoggedIn) {
        this.error = ErrorState.None;
      }
    } else {
      const enableFailureRedirect: boolean =
        !!this.query.enable_failure_redirect && this.query.enable_failure_redirect.toLowerCase() === "true";
      if (this.error !== ErrorState.None && enableFailureRedirect) {
        this.redirectFailureToApp();
      }
    }
  }

  public componentDidMount() {
    // Start in-app update based on group mode (private group release vs public group release)
    if (this.error === ErrorState.CookiesNotFound) {
      this.tryPublicDistributionGroupFallback();
    } else {
      this.fetchBuildForUpdate();
    }
  }

  public render() {
    if (this.error === ErrorState.CookiesNotFound) {
      // Going through the public distribution group fallback
      return null;
    }
    if (updateBuildStore.isLoaded && this.error === ErrorState.None && !this.countdownInterval) {
      this.delayedRedirectToApp();
    }

    return this.renderEnablingUpdatesPage();
  }

  private redirectToSignIn() {
    logger.info("In-app update setup: redirecting to sign in page", this.telemetryProps);
    const originRedirectUrl = "install:/" + window.location.pathname + window.location.search;
    window.location.assign(`${window.location.origin}/sign-in?original_url=${encodeURIComponent(originRedirectUrl)}`);
    this.error = ErrorState.None;
  }

  private fetchBuildForUpdate() {
    if (this.appSecret && this.query.release_hash) {
      if (this.isAuthenticated) {
        updateBuildStore.fetchPrivateUpdateBuild(this.appSecret, this.query.release_hash, this.updateToken!, this.query.request_id);
      } else if (this.distributionGroupId) {
        updateBuildStore.fetchPublicUpdateBuild(this.appSecret, this.distributionGroupId, this.query.request_id);
      }
    }
  }

  private errorMessageForErrorState = (errorState: ErrorState): string => {
    let error;
    switch (errorState) {
      case ErrorState.CookiesNotFound:
        error = Strings.UpdateSetup.UpdateErrorMessageCookies;
        break;
      case ErrorState.QueryParamsMissing:
        error = Strings.UpdateSetup.UpdateErrorMessageParams;
        break;
      default:
        error = "";
    }

    if (error && this.query.request_id) {
      error = StringHelper.format(Strings.UpdateSetup.UpdateErrorWithContextId, error, this.query.request_id);
    }

    return error;
  };

  private renderEnablingUpdatesPage = (): JSX.Element | null => {
    if (updateBuildStore.isFailed && this.error === ErrorState.None) {
      this.error = ErrorState.QueryParamsMissing;
      this.redirectFailureToApp();
    }

    const errorMessage = this.errorMessageForErrorState(this.error);
    if (errorMessage) {
      const compatibility: Compatibility = {
        compatible: false,
        incompatibilityMessage: errorMessage,
      };
      return <IncompatibleWarning compatibility={compatibility} />;
    }

    return updateBuildStore.isLoaded ? (
      <div className={styles["container"]}>
        <div className={styles["logo-container"]}>
          <ProductLogo className={styles["logo"]} />
        </div>
        <div className={styles["content-container"]}>
          {this.renderAppInfo(updateBuildStore.data)}
          <div className={styles["message"]}>
            {StringHelper.format(Strings.UpdateSetup.UpdateEnablingMessage, this.state.secondsUntilRedirect)}
          </div>
          <PrimaryButton onClick={() => this.redirectToApp()}>{Strings.UpdateSetup.ReturnToApp}</PrimaryButton>
        </div>
      </div>
    ) : null;
  };

  private renderAppInfo = (build?: Build): JSX.Element => {
    const appDisplayName = build ? build.appDisplayName : this.getAppDisplayNameFromCookie(this.query.release_hash);
    const appIconUrl = this.getAppIconUrlFromCookie(this.query.release_hash);
    const app = {
      os: OS.isIos(this.query.platform) ? OS.IOS : OS.ANDROID,
      icon_url: appIconUrl || undefined,
      display_name: appDisplayName || "",
    };

    return (
      <div>
        <AppIcon size={100} value={appDisplayName || "?"} app={app} />
        <div className={styles["app-name"]}>{appDisplayName || " "}</div>
      </div>
    );
  };

  private get redirectUrl(): string {
    const build = updateBuildStore.data;
    const redirectId = build && OS.isAndroid(this.query.platform) ? build.bundleIdentifier : this.query.redirect_id;
    if (!this.distributionGroupId) {
      this.readCookieValues();
    }

    if (!this.distributionGroupId) {
      return UpdateSetupHelper.createFailureRedirectUrl(
        this.query.platform,
        this.query.redirect_id,
        this.query.request_id,
        this.query.redirect_scheme
      );
    }

    if (!this.isAuthenticated) {
      return UpdateSetupHelper.createPublicRedirectUrl(
        this.query.platform,
        redirectId,
        this.distributionGroupId,
        this.query.request_id,
        this.query.redirect_scheme
      );
    } else {
      return UpdateSetupHelper.createPrivateRedirectUrl(
        this.query.platform,
        redirectId,
        this.updateToken!,
        this.distributionGroupId,
        this.query.request_id,
        this.udid,
        this.query.redirect_scheme
      );
    }
  }

  private redirectToApp = (forced: boolean = true): void => {
    if (forced) {
      logger.info("In-app update setup: Clicked Return to App Now", this.telemetryProps);
      if (this.countdownInterval === null) {
        logger.info("In-app update setup: Clicked Return to App Now after auto redirect failed", this.telemetryProps);
      }
    }

    logger.info("In-app update setup: unforced redirect to app", this.telemetryProps);
    window.location.href = this.redirectUrl;
  };

  private delayedRedirectToApp = (): void => {
    setTimeout(() => {
      this.redirectToApp(false);
    }, this.state.secondsUntilRedirect * 1000);
    this.countdownInterval = setInterval(() => {
      if (this.state.secondsUntilRedirect > 1) {
        this.setState({
          secondsUntilRedirect: this.state.secondsUntilRedirect - 1,
        });
      } else {
        if (this.countdownInterval) {
          clearInterval(this.countdownInterval);
        }
        this.countdownInterval = null;
      }
    }, 1000);
  };

  private redirectFailureToApp = (): void => {
    const redirectUrl: string = UpdateSetupHelper.createFailureRedirectUrl(
      this.query.platform,
      this.query.redirect_id,
      this.query.request_id,
      this.query.redirect_scheme
    );

    if (redirectUrl) {
      window.location.assign(redirectUrl);
      logger.info("Redirecting to app with in-app updates setup failure.", this.telemetryProps);
    } else {
      logger.info("Invalid update setup failure redirect url", this.telemetryProps);
    }
  };

  private readCookieValues = (): ErrorState => {
    if (UpdateSetupHelper.isMissingRequiredQueryParam(this.query)) {
      logger.info("distribution/update-setup/MissingRequiredQueryParam", this.telemetryProps);
      return ErrorState.QueryParamsMissing;
    }

    this.updateToken = safeLocalStorage.getItem(
      StringHelper.format(LocalStorageKeys.Format, this.query.release_hash, LocalStorageKeys.UpdateToken)
    );
    this.distributionGroupId = safeLocalStorage.getItem(
      StringHelper.format(LocalStorageKeys.Format, this.query.release_hash, LocalStorageKeys.DistributionGroupId)
    );
    this.udid = safeLocalStorage.getItem(LocalStorageKeys.Registered);

    if (!this.distributionGroupId) {
      logger.info(
        "distribution/update-setup/cookiesAreMissing",
        Object.assign(this.telemetryProps, {
          updateTokenLength: this.updateToken ? this.updateToken.length : 0,
          distributionGroupId: this.distributionGroupId || "missing",
          udid: this.udid ? StringHelper.obfuscate(this.udid, 80) : "missing",
        })
      );
      return ErrorState.CookiesNotFound;
    }

    return ErrorState.None;
  };

  private getQueryParams = (): UpdateQuery => {
    return (this.props && this.props.location && this.props.location.query) || undefined;
  };

  private getAppSecretFromPath = (): string => {
    return (this.props && this.props.params && this.props.params.secret) || undefined;
  };

  private getAppDisplayNameFromCookie(hash: string): string | null {
    return safeLocalStorage.getItem(StringHelper.format(LocalStorageKeys.Format, hash, LocalStorageKeys.DisplayName));
  }

  private getAppIconUrlFromCookie(hash: string): string | null {
    return safeLocalStorage.getItem(StringHelper.format(LocalStorageKeys.Format, hash, LocalStorageKeys.IconUrl));
  }

  private get isAuthenticated(): boolean {
    return !!this.updateToken || (userStore.userLoggedIn && !this.distributionGroupId);
  }

  private tryPublicDistributionGroupFallback(): void {
    if (this.appSecret && this.query.release_hash) {
      distributionGroupReleaseHashStore
        .fetchCollection({ appSecret: this.appSecret, releaseHash: this.query.release_hash })
        .onSuccess((publicGroups: DistributionGroup[] | undefined) => {
          if (!publicGroups || publicGroups.length === 0) {
            return this.redirectToSignIn();
          }

          // choose the first group arbitrarily because there is no criteria to determine which public group to choose in this case
          const publicId = publicGroups[0].id;
          this.distributionGroupId = publicId;
          this.error = ErrorState.None;
          safeLocalStorage.setItem(
            StringHelper.format(LocalStorageKeys.Format, this.query.release_hash, LocalStorageKeys.DistributionGroupId),
            publicId
          );
          this.fetchBuildForUpdate();
          this.setState({ secondsUntilRedirect: this.countdownTime });
        })
        .onFailure((error: Error | undefined) => {
          this.redirectToSignIn();
        });
    }
  }

  private get telemetryProps() {
    return UpdateSetupHelper.obfuscatedQueryParams(this.query);
  }
}
