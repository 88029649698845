// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3SLiPsOqx{background-color:#f8f8f8;height:100%;display:flex;flex-direction:column;flex-grow:1}._16CPVf5Nx{padding:0px 20px 0px 20px}", "",{"version":3,"sources":["webpack://client/install-beacon/apps/apps.scss","webpack://client/install-beacon/apps/org-apps.scss"],"names":[],"mappings":"AAGA,YACE,wBAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,WAAA,CCJF,YACE,yBAAA","sourcesContent":["@import \"~@css/vars.scss\";\n@import \"~@mobilecss/vars\";\n\n.apps {\n  background-color: #F8F8F8;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n","@import \"~@css/vars.scss\";\n@import \"~@mobilecss/vars\";\n@import \"./apps.scss\";\n\n.org-apps-empty {\n  padding: 0px 20px 0px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"apps": "_3SLiPsOqx",
	"org-apps-empty": "_16CPVf5Nx",
	"orgAppsEmpty": "_16CPVf5Nx"
};
export default ___CSS_LOADER_EXPORT___;
