import * as React from "react";
import { Text, Size, LiveRegion } from "@root/shared";
import { AppList } from "./app-list";
import { TesterApp } from "../../data/install/models/tester-app";
import { WithTranslation, withTranslation } from "react-i18next";

const styles = require("./apps-container.scss");

export interface IAppsContainerProps {
  title: string;
  isFetching: boolean;
  appsForCurrentOs: TesterApp[];
  appsForOtherOs: TesterApp[];
}

export const AppsContainer = withTranslation(["install"])(
  class AppsContainer extends React.Component<IAppsContainerProps & WithTranslation, {}> {
    public render() {
      const { title, isFetching, appsForCurrentOs, appsForOtherOs, t } = this.props;

      return (
        <div className={styles.appsContainer}>
          <div className={styles.platforms}>
            <div className={styles.platformHeader}>
              <Text size={Size.Large} bold>
                {title}
              </Text>
            </div>
            <LiveRegion>
              <AppList apps={appsForCurrentOs} skeleton={isFetching} />
            </LiveRegion>
          </div>
          {(appsForOtherOs && appsForOtherOs.length) > 0 ? (
            <div className={styles.platforms}>
              <div className={styles.platformHeader}>
                <Text size={Size.Large} bold>
                  {t("install:app.appsList.otherPlatforms")}
                </Text>
              </div>
              <LiveRegion>
                <AppList apps={appsForOtherOs} skeleton={isFetching} />
              </LiveRegion>
            </div>
          ) : null}
        </div>
      );
    }
  }
);
