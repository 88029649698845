import * as React from "react";
import { safeLocalStorage } from "@root/lib/utils/safe-local-storage";
import { LocalStorageKeys } from "../utils/constants";
import { updateTokenStore } from "../stores/update-token-store";

function useUpdateToken() {
  const defaultState = safeLocalStorage.getItem(LocalStorageKeys.UpdateToken) || undefined;
  const [updateToken, setUpdateToken] = React.useState<string>(defaultState!);

  const fetchUpdateToken = async () => {
    const updateToken = await updateTokenStore.fetchUpdateToken();
    if (updateToken) {
      setUpdateToken(updateToken);
    }
  };
  React.useEffect(() => {
    if (!updateToken) {
      fetchUpdateToken();
    }
  }, [updateToken]);

  return {
    updateToken,
    fetchUpdateToken,
  };
}

export { useUpdateToken };
