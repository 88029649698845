// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../common.scss";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../../shared/styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1BRrfHzjx{}._2Xz4uhy3x{}", "",{"version":3,"sources":["webpack://client/install-beacon/first-run/finished.scss"],"names":[],"mappings":"AAAA,YACE,CAGF,YACE","sourcesContent":[".container {\n  composes: container from \"../common.scss\";\n}\n\n.got-it {\n  composes: standalone-anchor from \"~@css/utils.scss\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1BRrfHzjx " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["container"] + "",
	"got-it": "_2Xz4uhy3x " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["standalone-anchor"] + "",
	"gotIt": "_2Xz4uhy3x " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["standalone-anchor"] + ""
};
export default ___CSS_LOADER_EXPORT___;
