import * as React from "react";
import { RouteComponentProps } from "react-router";
import { DeviceRegisteredPage } from "@root/install-beacon/components/device-registered-page/device-registered-page";
import { Overlay } from "@root/install-beacon/components/overlay/overlay";
import { CompleteRegistrationUIStore } from "./complete-registration-ui-store";
import { Location } from "history";

export interface LocationWithQuery extends Location {
  query: {
    udid?: string;
  } | null;
}

export interface CompleteRegistrationProps extends RouteComponentProps<{}, {}> {
  location: LocationWithQuery;
}

export class CompleteRegistration extends React.Component<CompleteRegistrationProps, {}> {
  private uiStore: CompleteRegistrationUIStore;

  constructor(props) {
    super(props);

    const {
      location: { query },
    } = this.props;
    this.uiStore = new CompleteRegistrationUIStore((query || {}).udid!);
  }

  public render() {
    const { showRegisteredOverlay } = this.uiStore;
    return (
      <Overlay animated={false} isVisible={showRegisteredOverlay}>
        <DeviceRegisteredPage title="Your device was added" />
      </Overlay>
    );
  }
}
