import { DEPRECATED_DO_NOT_USE_SEE_DOC_COMMENT_ExternalDataStore } from "@root/shared";
import { ApiGatewayApi } from "../../api/clients/api-gateway/api";

export class UpdateTokenStore extends DEPRECATED_DO_NOT_USE_SEE_DOC_COMMENT_ExternalDataStore<string> {
  public fetchUpdateToken(): Promise<string> {
    const fetchDataPromise = ApiGatewayApi.v01InAppUpdateTokenGet({});
    return fetchDataPromise.then((result: any) => {
      return result.token;
    });
  }

  public loadUpdateToken(): Promise<void> {
    return this.loadVoid(this.fetchUpdateToken());
  }
}

export const updateTokenStore = new UpdateTokenStore();
