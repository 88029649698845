import { observable, action } from "mobx";
import { portalServer } from "@root/lib/http";

export class DistributionGroupInvitationStore {
  @observable
  public pending: boolean = false;

  @observable
  public errorMessage!: string;

  constructor(private token: string) {}

  @action
  public update(onSuccess: () => void) {
    this.pending = true;

    return portalServer
      .patch(`/distribution_group_users/tokens/${this.token}`, { noBifrostToken: true })
      .then(() => {
        this.errorMessage = null as any;
        onSuccess();
      })
      .catch((error) => {
        console.error(error);
        this.errorMessage = "Unable to update the email";
      })
      .finally(() => {
        this.pending = false;
      });
  }
}
