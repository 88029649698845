import * as Sentry from "@sentry/react";
import "../global-config";

/* tslint:disable */
import * as React from "react";
/* tslint:enable */
import * as ReactDOM from "react-dom";
import { Router, browserHistory, RouterState, RedirectFunction } from "react-router";
import { Install } from "./install";
import Beacon from "@root/install-beacon";
import { PARAM_KEYS } from "@lib/constants/api";
import { DEFAULT_TITLE } from "@lib/constants/ui";
import { userStore } from "../stores/user-store";
import { appStore } from "../stores/app-store";
import { telemetry } from "../lib/telemetry";
import { locationStore } from "../stores/location-store";
import i18n from "../lib/i18n/i18n";
import { I18nextProvider } from "react-i18next";
import { Helmet } from "react-helmet";
import { PlainRoute } from "@lib/common-interfaces";

// Set user context for telemetry
telemetry.setUser(userStore.currentUser);

/**
 * Setup the telemetry context.
 * Need to save the AppStore context because http and appStore has a circular dependency
 */
telemetry.setAppStoreContext(appStore);

/**
 * Operations to be performed on route updates
 */
const onRouteUpdate = (nextState: RouterState) => {
  locationStore.setRouterState(nextState);
  appStore.setAppOnRouteChange(
    nextState.params[PARAM_KEYS.OWNER_TYPE],
    nextState.params[PARAM_KEYS.OWNER_NAME],
    nextState.params[PARAM_KEYS.APP_NAME]
  );
};

const rootRoute: PlainRoute = {
  path: "/",
  indexRoute: { onEnter: (nextState, replace: any) => replace("/apps") },
  component: Install,
  childRoutes: Beacon.beacon.childRoutes,

  // Called once when the route is entered. Necessary to handle the `first` time any route is loaded/deep linked.
  onEnter: (nextState: RouterState, replace: RedirectFunction, cb: Function | undefined) => {
    console.log("onEnter", nextState);
    onRouteUpdate(nextState);
    if (cb) {
      cb();
    }
  },

  // Called when a route changes each time.
  onChange: (prevState: RouterState, nextState: RouterState, replace: RedirectFunction, cb: Function | undefined) => {
    console.log("onChange", nextState);
    onRouteUpdate(nextState);
    if (cb) {
      cb();
    }
  },
};

ReactDOM.render(
  <Sentry.ErrorBoundary>
    <Helmet defaultTitle={DEFAULT_TITLE} />
    <React.Suspense fallback={null}>
      <I18nextProvider i18n={i18n}>
        <Router routes={rootRoute} history={browserHistory}></Router>
      </I18nextProvider>
    </React.Suspense>
  </Sentry.ErrorBoundary>,
  document.getElementById("app")
);
