import { APP_OWNER_TYPES } from "@lib/common-interfaces";
import { Organization } from "@root/data/shell/models";
import { AppNoReleases } from "@root/install-beacon/app/app-no-releases";
import { AppUIStore, ReleaseAvailableStatus } from "@root/install-beacon/app/stores/app-ui-store";
import { DeserializedResignStatusResponse } from "@root/install-beacon/app/stores/install-ui-store";
import { InstallDialog } from "@root/install-beacon/components/install-dialog";
import { InstallToast } from "@root/install-beacon/components/install-toast";
import { NavigationBar } from "@root/install-beacon/components/navigation-bar";
import { Page } from "@root/install-beacon/components/page";
import { Routes } from "@root/install-beacon/utils/constants";
import { FirstRunSessionStorage } from "@root/install-beacon/utils/first-run-session-storage";
import { metrics, logger } from "@root/lib/telemetry";
import { IconName, Size, Text, TextColor } from "@root/shared";
import { appStore, layoutStore, notFoundStore, userStore } from "@root/stores";
import { Location } from "history";
import { observer, Provider } from "mobx-react";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { appUsersStore } from "../../management/stores/user/app-users-store";
import { LayoutWithLeftNav } from "../../shell/layout-with-left-nav/layout-with-left-nav";
import { OrgTitle } from "../apps/org-title";
import { DeviceRegisteredPage } from "../components/device-registered-page/device-registered-page";
import { IncompatibleSafariBrowserMessage } from "../components/incompatible-safari-browser-message";
import { NotFound } from "../components/not-found";
import { Overlay } from "../components/overlay/overlay";
import { AppInfoAndReleases } from "./app-info-and-releases";

const styles = require("./app.scss");
export interface LocationWithQuery extends Location {
  query: {
    udid?: string;
    source?: string;
    no_check?: any;
    skip_registration?: any;
  } | null;
}
export interface AppProps
  extends RouteComponentProps<
    {
      id?: string;
      owner_name?: string;
      app_name?: string;
      group_name?: string;
      skipEmptyState?: boolean;
    },
    {}
  > {
  location: LocationWithQuery;
}

type ToastProps = {
  resignStatus: DeserializedResignStatusResponse["status"] | undefined;
  visible: boolean;
  title: string;
  action?: (event: React.MouseEvent<HTMLElement>) => void;
};
const Toast = ({ resignStatus, ...passthrough }: ToastProps) => {
  const description: React.ReactNode | undefined = (() => {
    switch (resignStatus) {
      case "inProgress":
        return "This may take a few seconds.";
      case "failedAndCanRetry":
        return (
          <Text size={Size.Small} color={TextColor.Link} underline>
            Retry
          </Text>
        );
      case "failedAndCannotRetry":
        return "Something went wrong. Please try again later.";
    }
  })();
  const icon: IconName | undefined = (() => {
    switch (resignStatus) {
      case "inProgress":
        return IconName.Spinner;
      case "failedAndCanRetry":
      case "failedAndCannotRetry":
        return IconName.StatusError;
    }
  })();
  return <InstallToast {...passthrough} description={description} icon={icon!} data-test-id="resign-toast" />;
};

export const App = withTranslation(["install", "common"])(
  observer(
    class App extends React.Component<AppProps & WithTranslation, {}> {
      private appUIStore: AppUIStore;

      constructor(props: AppProps & WithTranslation) {
        super(props);
        const {
          location: { query },
          params: { id, owner_name, app_name, group_name },
          routes,
        } = props;
        const skipRegistration = FirstRunSessionStorage.skippedDeviceRegistration || "skip_registration" in (query || {});
        const isPublicRoute = routes[2].path === "distribution_groups/:group_name";
        this.appUIStore = new AppUIStore(
          isPublicRoute,
          id!,
          (query || {}).udid!,
          "no_check" in (query || {}),
          (query || {}).source!,
          skipRegistration,
          owner_name,
          app_name,
          group_name
        );
      }

      public componentDidMount() {
        if (this.appUIStore.source === "email") {
          logger.info("Clicked email link", { source: "release" });
        }
        window.scrollTo(0, 0);
      }

      public componentWillUnmount() {
        this.appUIStore.dispose();
      }

      public render() {
        const isPublic = this.appUIStore.isPublic;

        // In order to return info for public apps, appUIStore.currentApp is always set
        // Use appStore.app to check app existence for private apps
        if (!appStore.app && !isPublic) {
          return <NotFound />;
        } else {
          const {
            currentApp: app,
            hasReleases,
            hasMultipleReleases,
            topRelease,
            registrationRequired,
            isDownloadOverlayVisible,
            installToastTitle,
            resignStatus,
            onRetryClicked,
            canRetry,
            installToastVisible,
            isLoading,
            fetchingInitial,
            skipEmptyState,
            topReleaseAvailable,
            onSelectPreviousVersions,
          } = this.appUIStore;
          const showReleases = skipEmptyState || topReleaseAvailable !== ReleaseAvailableStatus.NotAvailable;
          const currentOrg = app.owner.type === APP_OWNER_TYPES.ORG ? (app.owner as Organization) : null;
          const pageContent: JSX.Element = (
            <div className={styles.container}>
              <Overlay animated={false} isVisible={registrationRequired}>
                <DeviceRegisteredPage />
              </Overlay>
              <IncompatibleSafariBrowserMessage />
              {fetchingInitial ? null : showReleases ? (
                <Provider appUIStore={this.appUIStore}>
                  <AppInfoAndReleases
                    topRelease={topRelease}
                    isPublic={isPublic}
                    isDownloadOverlayVisible={isDownloadOverlayVisible}
                    app={app}
                  />
                </Provider>
              ) : (
                <AppNoReleases
                  app={app}
                  hasReleases={hasReleases}
                  hasMultipleReleases={hasMultipleReleases}
                  onSelectPreviousVersions={onSelectPreviousVersions}
                  stopTestingOnClick={this.showTesterDialog}
                />
              )}
              {this.renderDeleteDialog()}
              <Toast
                visible={installToastVisible}
                title={installToastTitle!}
                action={canRetry ? onRetryClicked : undefined}
                resignStatus={resignStatus}
              />
            </div>
          );

          // for public distribution group, we use the app info from the call to get latest release and not from initProps.
          // The app will not exist in initProps, which causes notFoundStore to be notified and
          // then LayoutWithLeftNav will return a 404 page. This hack prevents that.
          notFoundStore.reset();

          return (
            <Page data-test-id="app-page">
              {userStore.userLoggedIn ? (
                <LayoutWithLeftNav
                  topBarProps={{
                    showProfileLink: true,
                    loading: isLoading || fetchingInitial,
                    headerLogo: !!currentOrg && layoutStore.isMobile ? <OrgTitle organization={currentOrg}></OrgTitle> : (null as any),
                  }}
                >
                  {pageContent}
                </LayoutWithLeftNav>
              ) : (
                <>
                  <NavigationBar homeLink={Routes.AppList} showProfileLink loading={isLoading || fetchingInitial} />
                  {pageContent}
                </>
              )}
            </Page>
          );
        }
      }

      private renderDeleteDialog(): JSX.Element {
        const { t, params } = this.props;
        const {
          isCurrentUserFromSharedGroup,
          isMicrosoftDogfooding,
          testerHasRemovePermissions,
          isTesterDialogVisible: dialogVisible,
        } = this.appUIStore;
        const app = appStore.app;
        const appName = app ? app.display_name : params.app_name;

        if (isCurrentUserFromSharedGroup) {
          return (
            <InstallDialog
              visible={dialogVisible}
              description={t("install:app.sharedDistributionGroup.stopTestingDialogDescription", { appName })}
              confirmButton={t("common:button.dismiss")}
              onConfirm={this.hideTesterDialog}
              onCancel={() => {}}
            />
          );
        } else if (testerHasRemovePermissions) {
          return (
            <InstallDialog
              visible={dialogVisible}
              description={t("install:app.tester.stopTestingDialogDescription", { appName })}
              confirmButton={t("install:app.tester.stopTestingButton")}
              onConfirm={this.removeTesterFromApp}
              cancelButton={t("common:button.cancel")}
              onCancel={this.hideTesterDialog}
            />
          );
        } else if (isMicrosoftDogfooding) {
          return (
            <InstallDialog
              visible={dialogVisible}
              description={t("install:app.microsoftDogfooding.stopTestingDialogDescription", { appName })}
              confirmButton={t("common:button.dismiss")}
              onConfirm={this.hideTesterDialog}
              onCancel={() => {}}
            />
          );
        } else {
          return (
            <InstallDialog
              visible={dialogVisible}
              description={t("install:app.collaborator.stopTestingDialogDescription", { appName })}
              confirmButton={t("common:button.dismiss")}
              onConfirm={this.hideTesterDialog}
              onCancel={() => {}}
            />
          );
        }
      }

      private removeTesterFromApp = () => {
        if (this.appUIStore.testerHasRemovePermissions) {
          appUsersStore.remove(userStore.currentUser, appStore.app);
          appUsersStore.getRemoveStore(userStore.currentUser.email).deleteTesterDestinations(userStore.currentUser, appStore.app);
          metrics.emitMetric("distribute-remove-tester-from-app", 1);
          this.hideTesterDialog();
        }
      };

      private showTesterDialog = () => this.appUIStore.setTesterDialogVisible(true);

      private hideTesterDialog = () => this.appUIStore.setTesterDialogVisible(false);
    }
  )
);
