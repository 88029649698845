import * as React from "react";
import { Paragraph, Size, Space, TextColor, PrimaryButton } from "@root/shared";
import { Page } from "@root/install-beacon/components/page";
import { RouteComponentProps } from "react-router";
import { WelcomeUIStore } from "./welcome-ui-store";
import { userStore } from "@root/stores";
import { LayoutWithLeftNav } from "../../shell/layout-with-left-nav/layout-with-left-nav";
const styles = require("../common.scss");
const welcomeImage = require("./images/welcome.svg");

export class Welcome extends React.Component<RouteComponentProps<{}, {}>> {
  private welcomeUIStore: WelcomeUIStore;
  constructor(props) {
    super(props);
    this.welcomeUIStore = new WelcomeUIStore();
  }

  public render() {
    const pageContent: JSX.Element = (
      <div className={styles.container}>
        <img alt="" role="presentation" src={welcomeImage} />
        <Paragraph bold size={Size.Large} spaceAbove={Space.Large}>
          {userStore.currentUser.display_name},
        </Paragraph>
        <Paragraph size={Size.Large}>Welcome to App Center!</Paragraph>
        <Paragraph size={Size.Medium} color={TextColor.Secondary} spaceBelow={Space.Small} spaceAbove={Space.XSmall}>
          Our community of amazing developers are glad you're here! Testing apps with App Center helps our developers build better
          apps.
        </Paragraph>
        <PrimaryButton onClick={this.welcomeUIStore.onGetStartedClicked}>Get started</PrimaryButton>
      </div>
    );

    return (
      <Page>
        <LayoutWithLeftNav>{pageContent}</LayoutWithLeftNav>
      </Page>
    );
  }
}
