import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { EmptyState, EmptyStateImageSize } from "@root/shared";
import { testerAppStore, TesterApp } from "@root/data/install";
import { AppsContainer } from "./apps-container";
import { Footer } from "../components/footer";
import { Page } from "@root/install-beacon/components/page";
import { locationStore, layoutStore, organizationStore } from "@root/stores";
import { LayoutWithLeftNav } from "../../shell/layout-with-left-nav/layout-with-left-nav";
import { Routes } from "@root/install-beacon/utils/constants";
import { OrgTitle } from "./org-title";
import { Organization } from "@root/data/shell/models";
const noAppsInOrgImage = require("@root/install-beacon/assets/developers.svg");

const styles = require("./org-apps.scss");
const classNames = require("classnames");

export const OrgApps = withTranslation(["install"])(
  observer(
    class OrgApps extends React.Component<RouteComponentProps<any, any> & WithTranslation, {}> {
      public UNSAFE_componentWillMount() {
        testerAppStore.fetchCollection();
      }

      public render() {
        const { t } = this.props;
        const isFetching = testerAppStore.isFetchingCollection;
        const appsForCurrentOs = testerAppStore.appsForCurrentOsAndOrg;
        const appsForOtherOs = testerAppStore.appsForOtherOsAndOrg;
        const currentAppsCount = appsForCurrentOs.length;
        const appsForOtherOsCount = appsForOtherOs.length;
        const hasApps = currentAppsCount + appsForOtherOsCount > 0;
        const showAppsList = isFetching || hasApps;

        let currentOrg: Organization;
        if (hasApps) {
          const currentApp: TesterApp = currentAppsCount ? appsForCurrentOs[0] : appsForOtherOs[0];
          currentOrg = currentApp.owner ? organizationStore.find(currentApp.owner.name) : (null as any);
        }

        const pageContent: JSX.Element = (
          <div className={classNames({ [styles.apps]: true, [styles["org-apps-empty"]]: !showAppsList })}>
            {showAppsList ? (
              <AppsContainer
                title={
                  isFetching
                    ? t("install:orgs.loadingApps")
                    : currentAppsCount
                    ? t("install:orgs.currentAppsTitle", { count: currentAppsCount })
                    : (null as any)
                }
                isFetching={isFetching}
                appsForCurrentOs={appsForCurrentOs}
                appsForOtherOs={appsForOtherOs}
              />
            ) : (
              <EmptyState
                imgSrc={noAppsInOrgImage}
                title={""}
                subtitle={t("install:orgs.noAppsTitle")}
                buttonText={t("install:orgs.backButton")}
                onClick={() => locationStore.router.push(Routes.AppList)}
                imageSize={EmptyStateImageSize.Huge}
              />
            )}

            <div className={styles.footerContainer}>
              <Footer isPublic={false} />
            </div>
          </div>
        );

        return (
          <Page data-test-id="org-apps-page">
            <LayoutWithLeftNav
              topBarProps={{
                showProfileLink: true,
                headerLogo: !!currentOrg! && layoutStore.isMobile ? <OrgTitle organization={currentOrg!}></OrgTitle> : (null as any),
              }}
            >
              {pageContent}
            </LayoutWithLeftNav>
          </Page>
        );
      }
    }
  )
);
