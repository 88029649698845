import { action } from "mobx";
import * as cookie from "tiny-cookie";
import { locationStore } from "@root/stores";
import { getOsFromUserAgent } from "@root/lib/utils/user-agent";
import { OS } from "@root/install-beacon/models/os";
import { compatibilityStore } from "../stores/compatibility-store";
import { config } from "../../lib/utils/config";
import { logger } from "@root/lib/telemetry";

export class WelcomeUIStore {
  constructor() {
    const domain = config.getCookieDomain();
    cookie.remove("signup-origin", { domain: domain });
  }

  @action
  public onGetStartedClicked = () => {
    logger.info("Get started clicked");

    if (OS.isIos(getOsFromUserAgent().name!) && compatibilityStore.isSafariBrowser()) {
      locationStore.router.push("first-run/register-device");
    } else {
      locationStore.pushAppList();
    }
  };
}
