// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/developers.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._40Z21vcwx{position:relative;height:120px}._1Id9810qx{height:120px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat bottom center;position:absolute;width:100%}._4qZzAQKtx{display:flex;justify-content:center;position:absolute;width:100%;z-index:-1;height:120px;opacity:.25}", "",{"version":3,"sources":["webpack://client/install-beacon/app/app-icon-annotated.scss"],"names":[],"mappings":"AAAA,YACE,iBAAA,CACA,YAAA,CAGF,YACE,YAAA,CACA,0EAAA,CACA,iBAAA,CACA,UAAA,CAGF,YACE,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,UAAA,CACA,UAAA,CACA,YAAA,CACA,WAAA","sourcesContent":[".container {\n  position: relative;\n  height: 120px;\n}\n\n.foreground {\n  height: 120px;\n  background: url(../assets/developers.svg) no-repeat bottom center;\n  position: absolute;\n  width: 100%;\n}\n\n.background {\n  display: flex;\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  z-index: -1;\n  height: 120px;\n  opacity: 0.25;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_40Z21vcwx",
	"foreground": "_1Id9810qx",
	"background": "_4qZzAQKtx"
};
export default ___CSS_LOADER_EXPORT___;
