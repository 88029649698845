import * as React from "react";
import { Text, Title, Size } from "@root/shared";
import { StopTestingMenu } from "./stop-testing-menu";
import { Release } from "../utils/strings";
import StringHelper from "../utils/string-helper";

const styles = require("./release-not-found.scss");

export const ReleaseNotFound: React.FunctionComponent<{ releaseId: string; stopTestingOnClick(): void }> = ({
  releaseId,
  stopTestingOnClick,
}) => (
  <div className={styles.releases}>
    <div className={styles.responsiveConstraint}>
      <div className={styles.titleBar}>
        <Text size={Size.Medium} bold>
          {StringHelper.format(Release.Title, releaseId)}
        </Text>
        <div className={styles.titleControls}>
          <StopTestingMenu onClick={stopTestingOnClick} />
        </div>
      </div>
      <div className={styles.release}>
        <div className={styles.releaseContainer}>
          <div className={styles.releaseInfo}>
            <Title className={styles.title} size={Size.XSmall}>
              {StringHelper.format(Release.NotFound, releaseId)}
            </Title>
          </div>
        </div>
      </div>
    </div>
  </div>
);
