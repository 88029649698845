import * as React from "react";
import { AppIcon, Skeletal, Size, Text, TextColor } from "@root/shared";
import { TesterApp } from "@root/data/install";
import { observer } from "mobx-react";
import StringHelper from "../../utils/string-helper";
import BuildHelper from "../../utils/build-helper";
import { App } from "../../utils/strings";

const styles = require("./app-details-component.scss");

export interface AppDetailsProps extends Skeletal {
  app?: TesterApp;
}

@observer
export class AppDetailsComponent extends React.Component<AppDetailsProps, {}> {
  public render() {
    if (this.props.skeleton) {
      return (
        <div className={styles["container"]}>
          <div className={styles["icon-container"]}>
            <div className={styles["app-icon-skeleton"]} />
          </div>
          <div className={styles["info-container"]}>
            <div className={styles["display-name-skeleton"]} />
            <div className={styles["subtitle-skeleton"]} />
          </div>
        </div>
      );
    }
    const { app } = this.props;

    return (
      <div className={styles["container"]} aria-hidden tabIndex={-1}>
        <div className={styles["icon-container"]}>
          <AppIcon size={40} app={app} />
        </div>
        <div className={styles["info-container"]}>
          <Text size={Size.Medium} bold ellipsize>
            {app!.display_name}
          </Text>
          {!!app!.build ? (
            <Text size={Size.Medium} color={TextColor.Secondary} ellipsize>
              {StringHelper.format(App.VersionAndSize, app!.build.version, BuildHelper.filesizeString(app!.build.size!))}
            </Text>
          ) : null}
          <Text size={Size.Medium} color={TextColor.Secondary}>
            {app!.os}
          </Text>
        </div>
      </div>
    );
  }
}
