import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Paragraph, Size, Space, TextColor, PrimaryButton, Icon, IconName, IconSize, ActionText } from "@root/shared";
import { RegisterDeviceFirstRunUIStore } from "./register-device-first-run-ui-store";
import { Page } from "@root/install-beacon/components/page";
import { InstallDialog } from "@root/install-beacon/components/install-dialog";
import { observer } from "mobx-react";
import { LayoutWithLeftNav } from "../../shell/layout-with-left-nav/layout-with-left-nav";
const styles = require("./register-device-first-run.scss");
const registerDeviceImg = require("./images/register-device-frame.svg");
const registerAnimation = require("./images/install-profile-animation.gif");

@observer
export class RegisterDeviceFirstRun extends React.Component<RouteComponentProps<{}, {}>, {}> {
  private registerUIStore: RegisterDeviceFirstRunUIStore;
  constructor(props) {
    super(props);
    this.registerUIStore = new RegisterDeviceFirstRunUIStore(`${window.location.origin}/first-run/complete-registration`);
  }

  public render() {
    const { onSkipClicked, showSkipConfirmDialog, onSkipConfirmed, onSkipCancelled, onAddDeviceClicked } = this.registerUIStore;
    const pageContent: JSX.Element = (
      <div className={styles.container}>
        <div className={styles.registerAnimationContainer}>
          <img alt="" role="presentation" src={registerDeviceImg} />
          <img className={styles.registerAnimation} alt="" role="presentation" src={registerAnimation} />
        </div>
        <Paragraph bold size={Size.Large} spaceAbove={Space.Large}>
          Ready?
        </Paragraph>
        <Paragraph size={Size.Large}>Let's set up your device</Paragraph>
        <Paragraph size={Size.Medium} color={TextColor.Secondary} spaceAbove={Space.XSmall}>
          Before testing apps, you may need to add this device to your App Center account, so developers can make apps for you.
        </Paragraph>
        <Paragraph size={Size.Small} spaceAbove={Space.Medium} spaceBelow={Space.Medium}>
          This process can take up to 20 seconds
        </Paragraph>
        <PrimaryButton icon={<Icon icon={IconName.Add} size={IconSize.XXSmall} />} onClick={onAddDeviceClicked}>
          Add Device
        </PrimaryButton>
        <ActionText className={styles.doLater} onClick={onSkipClicked}>
          I'll do this later
        </ActionText>

        <InstallDialog
          visible={showSkipConfirmDialog}
          description="You might not be able to test apps if this device has not been added to your account."
          confirmButton="OK"
          onConfirm={onSkipConfirmed}
          cancelButton="Cancel"
          onCancel={onSkipCancelled}
        />
      </div>
    );

    return (
      <Page>
        <LayoutWithLeftNav>{pageContent}</LayoutWithLeftNav>
      </Page>
    );
  }
}
