import * as React from "react";
import { Paragraph, Size, Space, TextColor, ActionText } from "@root/shared";
import { Page } from "@root/install-beacon/components/page";
import { RouteComponentProps } from "react-router";
import { FinishedUIStore } from "./finished-ui-store";
import { LayoutWithLeftNav } from "../../shell/layout-with-left-nav/layout-with-left-nav";
const styles = require("./finished.scss");
const finishedImage = require("./images/finished.svg");

export class FirstRunFinished extends React.Component<RouteComponentProps<{}, {}>> {
  private finishedUIStore: FinishedUIStore;
  constructor(props) {
    super(props);
    this.finishedUIStore = new FinishedUIStore();
  }

  public render() {
    const pageContent: JSX.Element = (
      <div className={styles.container}>
        <img alt="" role="presentation" src={finishedImage} />
        <Paragraph bold size={Size.Large} spaceAbove={Space.Large}>
          You're all set for testing!
        </Paragraph>
        <Paragraph size={Size.Medium} color={TextColor.Secondary} spaceBelow={Space.Medium} spaceAbove={Space.XSmall}>
          If you want to add additional devices for testing, you can manage your devices at any time in Account Settings
        </Paragraph>
        <ActionText className={styles.gotIt} onClick={this.finishedUIStore.onNavigationClicked}>
          Ok, got it!
        </ActionText>
      </div>
    );
    return (
      <Page>
        <LayoutWithLeftNav topBarProps={{ showProfileLink: true }}>{pageContent}</LayoutWithLeftNav>
      </Page>
    );
  }
}
