import * as React from "react";
import { observer } from "mobx-react";

import { compatibilityStore, CompatibilityStore, Compatibility } from "../stores/compatibility-store";
import { ProductLogo } from "@root/shared";

const styles = require("./incompatible-warning.scss");

const wrongDeviceImg = require("../assets/wrong-device.svg");
const privateModeImg = require("../assets/private-mode.svg");
const safariImg = require("../assets/safari.svg");
const chromeImg = require("../assets/chrome.svg");
const defaultImg = require("../assets/settings.svg"); // TODO: get an actual default incompatiblity warning image

export interface IncompatibleWarningProps {
  compatibility: Compatibility;
  instructions?: string;
  instructionsLink?: string;
}

@observer
export class IncompatibleWarning extends React.Component<IncompatibleWarningProps, {}> {
  public render() {
    return (
      <div className={styles["incompatible"]}>
        <div className={styles["logo"]}>
          <ProductLogo />
        </div>
        <div className={styles["content"]}>
          {this._getIncompatibilityImage(this.props.compatibility)}
          <div className={styles["message"]}>{this.props.compatibility.incompatibilityMessage}</div>
          <a className={styles["instructions"]} href={this.props.instructionsLink}>
            {this.props.instructions}
          </a>
        </div>
      </div>
    );
  }

  private _getIncompatibilityImage(compatibility: Compatibility): JSX.Element {
    let imageSrc: string = defaultImg;
    if (compatibility.osNameCompatible === false || compatibility.osVersionCompatible === false) {
      imageSrc = wrongDeviceImg;
    } else if (compatibility.browserCompatible === false) {
      const compatibleBrowser: string = compatibilityStore.compatibleBrowserForOs();
      if (compatibleBrowser === CompatibilityStore.UserAgent.BrowserNames.MobileSafari) {
        imageSrc = safariImg;
      } else if (compatibleBrowser === CompatibilityStore.UserAgent.BrowserNames.Chrome) {
        imageSrc = chromeImg;
      }
    } else if (compatibility.privateBrowsing) {
      imageSrc = privateModeImg;
    }
    return <img alt="" role="presentation" className={styles["graphic"]} src={imageSrc} />;
  }
}
