import { MessageBar, NotificationType } from "@root/shared";
import { observer } from "mobx-react";
import * as React from "react";
import { incompatibleSafariWarningStore } from "../stores/incompatible-safari-warning-store";
import * as Strings from "../utils/strings";

@observer
export class IncompatibleSafariBrowserMessage extends React.Component<{}, {}> {
  public render() {
    if (incompatibleSafariWarningStore.shouldShowMessage) {
      return (
        <MessageBar dismissButtonText={Strings.Generic.Dismiss} onDismiss={this.hideMessage} type={NotificationType.Warning}>
          {Strings.Install.Incompatible.SafariBrowser}
        </MessageBar>
      );
    }

    return null;
  }

  private hideMessage() {
    incompatibleSafariWarningStore.hideWarningMessage();
  }
}
