import { action, computed, observable } from "mobx";
import { RegistrationUtils } from "@root/install-beacon/utils/registration-utils";
import { logger } from "@root/lib/telemetry";
import { registerDeviceStore } from "@root/install-beacon/stores/register-device-store";
import { locationStore } from "@root/stores";
import { devicesStore } from "@root/data/management";

export class RegisterDeviceUIStore {
  private returnUrl: string;
  @observable public showSkipConfirmDialog = false;

  @computed
  private get relativeReturnUrl() {
    return RegistrationUtils.relativeUrl(this.returnUrl);
  }

  @computed get isFetchingDevices() {
    return devicesStore.isFetchingCollection;
  }

  @computed get hasDevices() {
    return devicesStore.resources.length > 0;
  }

  @computed get registeredDevices() {
    return devicesStore.resources;
  }

  constructor(originalUrl: string) {
    this.returnUrl = originalUrl || encodeURI(`${window.location.origin}/apps`);
    devicesStore.fetchCollection();
  }

  @action
  public onAddDeviceClicked = () => {
    // Download the device management profile
    logger.info("Register device clicked");
    registerDeviceStore.fetchProfile(this.returnUrl);
  };

  @action
  public onSkipClicked = () => {
    this.showSkipConfirmDialog = true;
  };

  @action
  public onSkipConfirmed = () => {
    // Record that the user chose to skip, so they don't get repeatedly prompted to register.
    logger.info("Skip device registration clicked");
    RegistrationUtils.skipDeviceRegistration();
    locationStore.router.push(this.relativeReturnUrl);
  };

  @action
  public onSkipCancelled = () => {
    this.showSkipConfirmDialog = false;
  };
}
