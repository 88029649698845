import { DEPRECATED_DO_NOT_USE_SEE_DOC_COMMENT_ExternalDataStore, ExternalDataState } from "@root/shared";
import { SerializedBuild, DeserializedBuild } from "@root/data/install";
import * as Constants from "../utils/constants";
import { apiGateway } from "@root/lib/http";
import { logger } from "@root/lib/telemetry";
import StringHelper from "../utils/string-helper";
import { action } from "mobx";
import { updateTokenStore } from "./update-token-store";
import { UpdateSetupHelper } from "../utils/update-setup-helper";

// TODO: refactor this into the new BuildStore in @root/data/install
export class UpdateBuildStore extends DEPRECATED_DO_NOT_USE_SEE_DOC_COMMENT_ExternalDataStore<DeserializedBuild> {
  public fetchPublicUpdateBuild(appSecret: string, distributionGroupId: string, contextId?: string): void {
    const urlTemplate = Constants.Urls.GetPublicUpdateBuild;
    const params = { app_secret: appSecret, distribution_group_id: distributionGroupId };
    const options = { params: params, noBifrostToken: true };

    this.loadVoid(this.fetchUpdateBuild(urlTemplate, options)).then(() => {
      if (this.state === ExternalDataState.Failed) {
        logger.warn("Fetching update build info failed", this.error, {
          urlTemplate: urlTemplate,
          distributionGroupId: StringHelper.obfuscate(distributionGroupId),
          appSecret: StringHelper.obfuscate(appSecret),
          contextId: contextId,
        });
      }
    });
  }

  public fetchPrivateUpdateBuild(appSecret: string, releaseHash: string, updateToken: string, contextId?: string): void {
    const urlTemplate = Constants.Urls.GetPrivateUpdateBuild;
    const fetchTokenPromise: Promise<string> = updateToken ? Promise.resolve(updateToken) : updateTokenStore.fetchUpdateToken();
    const fetchBuildPromise: Promise<DeserializedBuild> = fetchTokenPromise.then((token) => {
      const params = { app_secret: appSecret, package_hash: releaseHash };
      const headers = { "x-api-token": token };
      const options = { params: params, headers: headers, noBifrostToken: true };
      return this.fetchUpdateBuild(urlTemplate, options).then((build) => {
        // If the calling function does not pass in an update token, this means this was not available before. We need to store it now.
        if (!updateToken) {
          UpdateSetupHelper.storeCookies(build, build.appDisplayName!, build.iconLink!, false, token);
        }
        return build;
      });
    });

    this.loadVoid(fetchBuildPromise).then(() => {
      if (this.state === ExternalDataState.Failed) {
        logger.warn("Fetching update build info failed", this.error, {
          urlTemplate: urlTemplate,
          releaseHash: StringHelper.obfuscate(releaseHash),
          appSecret: StringHelper.obfuscate(appSecret),
          updateToken: StringHelper.obfuscate(updateToken, 50, 50),
          contextId: contextId,
        });
      }
    });
  }

  @action
  private fetchUpdateBuild(urlTemplate: string, options: any): Promise<DeserializedBuild> {
    return apiGateway.get<SerializedBuild>(urlTemplate, options).then((serialized: SerializedBuild) => {
      return {
        id: (serialized.id || "").toString(),
        appName: serialized.app_name,
        appDisplayName: serialized.app_display_name,
        appOs: serialized.app_os,
        appVersion: serialized.short_version,
        buildNumber: serialized.version,
        timestamp: serialized.uploaded_at,
        releaseNotes: serialized.release_notes,
        size: serialized.size,
        minOsVersion: serialized.min_os,
        iconLink: serialized.app_icon_url,
        downloadLink: serialized.download_url,
        installLink: serialized.install_url,
        distributionGroups: serialized.distribution_groups,
        packageHashes: serialized.package_hashes,
        bundleIdentifier: serialized.bundle_identifier,
        provisioningProfileType: serialized.provisioning_profile_type,
        isProvisioningProfileSyncing: serialized.is_provisioning_profile_syncing,
        isUdidProvisioned: serialized.is_udid_provisioned,
        isLatest: serialized.is_latest,
      };
    });
  }
}

export const updateBuildStore = new UpdateBuildStore();
