// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../common.scss";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../../shared/styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5xn4P197x{}._62YFYFNvx{margin:20px 0}._7r8XmVKJx{width:100%}", "",{"version":3,"sources":["webpack://client/install-beacon/register-device/register-device.scss"],"names":[],"mappings":"AAEA,YACE,CAGF,YAEE,aAAA,CAGF,YACE,UAAA","sourcesContent":["@import \"~@css/vars.scss\";\n\n.container {\n  composes: container from \"../common.scss\";\n}\n\n.skip {\n  composes: standalone-anchor from '~@css/utils.scss';\n  margin: $space-m 0;\n}\n\n.devices-container {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"container": "_5xn4P197x " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["container"] + "",
	"skip": "_62YFYFNvx " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["standalone-anchor"] + "",
	"devices-container": "_7r8XmVKJx",
	"devicesContainer": "_7r8XmVKJx"
};
export default ___CSS_LOADER_EXPORT___;
