// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Ph01WCRx{position:fixed;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:#fff;z-index:2;opacity:1;visibility:hidden;overflow:scroll;-webkit-overflow-scrolling:touch}._1J9SlIhix{opacity:0;transition:opacity .5s;transition-delay:1s}._2wPjmTTdx{opacity:1;visibility:visible}._5etx0wJ8x{overflow:hidden}", "",{"version":3,"sources":["webpack://client/install-beacon/components/overlay/overlay.scss"],"names":[],"mappings":"AAEA,YACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,qBAAA,CACA,SAAA,CACA,SAAA,CACA,iBAAA,CACA,eAAA,CACA,gCAAA,CAGF,YAEE,SAAA,CAEA,sBAAA,CAEA,mBAAA,CAGF,YACE,SAAA,CACA,kBAAA,CAGF,YACE,eAAA","sourcesContent":["@import \"~@css/vars.scss\";\n\n.container {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0; \n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: white;\n  z-index: 2; \n  opacity: 1.0; \n  visibility: hidden;\n  overflow: scroll;\n  -webkit-overflow-scrolling: touch;\n}\n\n.animated-container {\n  composes: container;\n  opacity: 0.0; \n  -webkit-transition: opacity 0.5s;\n  transition: opacity 0.5s;\n  -webkit-transition-delay: 1s;\n  transition-delay: 1s;\n}\n\n.visible {\n  opacity: 1.0;\n  visibility: visible;\n}\n\n.no-scroll {\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"container": "_3Ph01WCRx",
	"animated-container": "_1J9SlIhix _3Ph01WCRx",
	"animatedContainer": "_1J9SlIhix _3Ph01WCRx",
	"visible": "_2wPjmTTdx",
	"no-scroll": "_5etx0wJ8x",
	"noScroll": "_5etx0wJ8x"
};
export default ___CSS_LOADER_EXPORT___;
