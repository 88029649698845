import * as React from "react";
import * as classNames from "classnames";

const styles = require("./close-button.scss");

export interface CloseButtonProps<T = HTMLButtonElement | HTMLAnchorElement> extends React.ButtonHTMLAttributes<T> {
  onClick?: () => void;
  label?: string;
}

export class CloseButton extends React.Component<CloseButtonProps, {}> {
  public render(): JSX.Element {
    const className = classNames(this.props.className, styles["button"]);

    return <button className={className} onClick={this.onClick} aria-label={this.props.label} />;
  }

  private onClick = (): void => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };
}
