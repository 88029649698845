import { Routes } from "../utils/constants";
import { safeLocalStorage } from "../../lib/utils/safe-local-storage";
import { LocalStorageKeys } from "../utils/constants";
import { Build, ProfileType } from "@root/data/install";
import { OS } from "../models/os";
import { locationStore } from "@root/stores";
import { IApp } from "@lib/common-interfaces";
import { getOsNameFromUserAgent } from "@root/lib/utils/user-agent";
import { compatibilityStore } from "../stores/compatibility-store";
import { logger } from "@root/lib/telemetry";

export class RegistrationUtils {
  /**
   * Helper used to determine whether user should be prompted with device registration or not. This returns 'true' if user is running on an iOS
   * device and there is no 'udid' cookie available.
   */
  public static redirectToInitialRegistrationIfNeeded(skipRegistration?: boolean, udid?: string): void {
    // The SDK might pass the skip_registration query parameter to prevent the device registration from showing from release notes link
    if (skipRegistration && !udid) {
      return;
    }

    const osName: string = getOsNameFromUserAgent();

    if (OS.isIos(osName)) {
      const storedUdid = !!safeLocalStorage.getItem(LocalStorageKeys.Registered);
      const skip = !!safeLocalStorage.getItem(LocalStorageKeys.Skip);

      if (udid) {
        RegistrationUtils.finishDeviceRegistration(udid, false, false);
      } else if (!skip && !storedUdid && compatibilityStore.isSafariBrowser()) {
        this.redirectToDeviceRegistration();
      }
    }
  }

  public static isDeviceRegistrationNeeded(build: Build, app: IApp): boolean {
    const osName: string = getOsNameFromUserAgent();
    const appOs = app && app.os ? app.os : "";

    // If the app is iOS and the device is iOS, check to see if the cookie is set
    if (OS.isIos(osName) && OS.isIos(appOs)) {
      const registered: boolean = !!safeLocalStorage.getItem(LocalStorageKeys.Registered);
      const isAdHocProfile = build && build.provisioningProfileType === ProfileType.AdHoc;
      if (registered || !isAdHocProfile) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  public static startDeviceRegistration() {
    logger.info("Device registration start");
  }

  public static skipDeviceRegistration() {
    logger.info("Device registration skipped");
    safeLocalStorage.setItem(LocalStorageKeys.Skip, "true");
  }

  public static finishDeviceRegistration(udid: string, enterprise: boolean, fromApp: boolean) {
    logger.info("Device registration complete", { skip: false, registered: true, enterprise: enterprise, fromApp: fromApp });
    safeLocalStorage.setItem(LocalStorageKeys.Registered, udid);
    safeLocalStorage.removeItem(LocalStorageKeys.Skip);
  }

  public static relativeUrl(url: string): string {
    const isRelativeUrl: boolean = !!url && !/^(?:[a-z]+:)?\/\//i.test(url);
    if (url && !isRelativeUrl) {
      return "/" + url.replace(/^(?:\/\/|[^\/]+)*\//, "");
    } else {
      return url;
    }
  }

  /**
   * A helper method that redirects the user to the registration page.
   */
  private static redirectToDeviceRegistration(): void {
    locationStore.router.push({
      pathname: Routes.RegisterDevice,
      query: { original_url: window.location.toString() },
    });
  }
}
