import * as React from "react";
import { Register } from "../utils/strings";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import { registerDeviceStore } from "../stores/register-device-store";
import { PrimaryButton, Title, Size } from "@root/shared";
import { compatibilityStore, Compatibility } from "../stores/compatibility-store";
import { Install } from "../utils/strings";
import { IncompatibleWarning } from "../app/incompatible-warning";
import { logger } from "@root/lib/telemetry";

const styles = require("./register-device-start.scss");

/**
 * This page is unauthenticated, it is here to launch the configuration profile install.
 */
@observer
export class RegisterDeviceStart extends React.Component<RouteComponentProps<any, any>, {}> {
  private registrationId!: string;

  public UNSAFE_componentWillMount() {
    // TODO: Optimization: Could check for the cookie and launch the redirect url directly.

    // Get the query params.
    let queryParams;
    if (this.props.location && this.props.location.query) {
      queryParams = this.props.location.query;
    }

    // Get the registration id.
    this.registrationId = queryParams && queryParams.registration_id;
    if (this.registrationId) {
      registerDeviceStore.installProfileForRegistrationId(this.registrationId);
    } else {
      logger.info("RegisterDeviceStartPage: No Registration ID provided to registration start page.");

      // Nothing we can do so navigate to the main page.
      RegisterDeviceStart.navigate(window.location.origin);
    }
  }

  public render() {
    // Detect if we are in Chrome or in private browsing.
    if (compatibilityStore.isLoaded && !compatibilityStore.data!.compatible) {
      const instructions: string = this.getInstructionsForIncompatibility(compatibilityStore.data!);
      const instructionsLink: string = instructions ? window.location.href : (undefined as any);
      return (
        <IncompatibleWarning
          compatibility={compatibilityStore.data!}
          instructions={instructions}
          instructionsLink={instructionsLink}
        />
      );
    }

    // Users should never see this screen for long enough to do anything on it,
    // but just in case we will show them a way to trigger the profile install manually.
    return (
      <div className={styles["top-container"]}>
        <div className={styles["image-container"]}></div>
        <div className={styles["content-container"]}>
          <Title className={styles["title"]} size={Size.Medium}>
            {Register.Instructions}
          </Title>
          <PrimaryButton className={styles["button"]} onClick={() => this.register()}>
            {Register.ButtonTitleRegister}
          </PrimaryButton>
        </div>
      </div>
    );
  }

  private register() {
    logger.info("RegisterDeviceStartPage: Register device clicked");
    registerDeviceStore.fetchProfile(null as any, this.registrationId);
  }

  private getInstructionsForIncompatibility(compatibility: Compatibility): string {
    let instructions: string;
    if (!compatibility.browserCompatible || compatibility.privateBrowsing) {
      instructions = Install.CopyLink;
    }
    return instructions!;
  }

  /**
   * Navigates to the provided url.
   *
   * NOTE: This is only here for testing purposes so we can make sure that we are navigating to the right place.
   */
  private static navigate(url: string) {
    window.location.href = url;
  }
}
