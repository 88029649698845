import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Size, Paragraph, Space, TextColor, Icon, IconName, IconSize, ActionText, TextAlignment } from "@root/shared";
import { observer } from "mobx-react";
import { PrimaryButton } from "@root/shared";
import { RegistrationUtils } from "../utils/registration-utils";
import { Location } from "history";
import { Page } from "@root/install-beacon/components/page";
import { RegisterDeviceUIStore } from "./register-device-ui-store";
import { InstallDialog } from "@root/install-beacon/components/install-dialog";
import { DevicesList } from "@root/install-beacon/components/devices-list";
import { LayoutWithLeftNav } from "../../shell/layout-with-left-nav/layout-with-left-nav";

const styles = require("./register-device.scss");
const registerDeviceImg = require("./images/register-device.svg");

export interface LocationWithQuery extends Location {
  query: {
    original_url?: string;
  } | null;
}

export interface RegisterDeviceProps extends RouteComponentProps<{}, {}> {
  location: LocationWithQuery;
}

@observer
export class RegisterDevice extends React.Component<RegisterDeviceProps, {}> {
  private uiStore: RegisterDeviceUIStore;

  constructor(props: RegisterDeviceProps) {
    super(props);

    const {
      location: { query },
    } = this.props;
    this.uiStore = new RegisterDeviceUIStore((query || {}).original_url!);
    RegistrationUtils.startDeviceRegistration();
  }

  public render() {
    const {
      onAddDeviceClicked,
      onSkipClicked,
      showSkipConfirmDialog,
      onSkipConfirmed,
      onSkipCancelled,
      hasDevices,
      registeredDevices,
      isFetchingDevices,
    } = this.uiStore;
    const pageContent: JSX.Element = (
      <div className={styles.container}>
        <img alt="" role="presentation" src={registerDeviceImg} />
        <Paragraph size={Size.Large} spaceAbove={Space.Medium}>
          This device might not be added to your account yet.
        </Paragraph>
        <Paragraph size={Size.Medium} color={TextColor.Secondary} spaceAbove={Space.XSmall} spaceBelow={Space.Medium}>
          If it is not listed below, add it before continuing.
        </Paragraph>
        <PrimaryButton icon={<Icon icon={IconName.Add} size={IconSize.XXSmall} />} onClick={onAddDeviceClicked}>
          Add Device
        </PrimaryButton>
        <ActionText data-test-id="skip" className={styles.skip} onClick={onSkipClicked}>
          I'm good
        </ActionText>

        {hasDevices ? (
          <div className={styles.devicesContainer}>
            <Paragraph size={Size.Medium} bold align={TextAlignment.Left} spaceAbove={Space.XSmall} spaceBelow={Space.Small}>
              My Devices
            </Paragraph>
            <DevicesList devices={registeredDevices} invert data-test-id="register-devices-list" />
          </div>
        ) : null}

        <InstallDialog
          data-test-id="skip-confirm-dialog"
          visible={showSkipConfirmDialog}
          description="You might not be able to test apps if this device has not been added to your account yet"
          confirmButton="OK"
          onConfirm={onSkipConfirmed}
          cancelButton="Cancel"
          onCancel={onSkipCancelled}
        />
      </div>
    );

    return isFetchingDevices ? null : (
      <Page data-test-id="register-devices">
        <LayoutWithLeftNav topBarProps={{ showProfileLink: true }}>{pageContent}</LayoutWithLeftNav>
      </Page>
    );
  }
}
